import { injectable } from 'inversify';
import {
    observable, action, computed, makeObservable
} from 'mobx';

export type WindowSize = 'small' | 'large';

@injectable()
export default class LayoutStore {
    @observable public size: WindowSize = LayoutStore.calculateWindowSize();

    @observable public mobilePanelFullscreen: boolean = false;

    @observable public loadersCounter = 0;

    @observable public drawerScrollTitle: string | null = null;

    @observable public drawerIsScrolled = false;

    @computed public get drawerScrollButtonVisible() {
        return !this.drawerIsScrolled && this.isMobile;
    }

    @computed public get isMobile(): boolean { return this.size === 'small'; }

    @computed public get isLoading() {
        return this.loadersCounter > 0;
    }

    constructor() {
        makeObservable(this);
    }

    @action setSize(size: WindowSize) {
        this.size = size;
    }

    @action setMobilePanelFullscreen(a: boolean) {
        this.mobilePanelFullscreen = a;
    }

    @action resetToDefaults() {
        this.mobilePanelFullscreen = false;
    }

    @action startLoading() {
        this.loadersCounter += 1;
    }

    @action finishLoading() {
        this.loadersCounter = Math.max(0, this.loadersCounter - 1);
    }

    @action setDrawerScrollTitle(value: string) {
        this.drawerScrollTitle = value;
    }

    @action clearDrawerScrollTitle() {
        this.drawerScrollTitle = null;
    }

    @action setDrawerIsScrolled(value: boolean) {
        this.drawerIsScrolled = value;
    }

    public static calculateWindowSize(): WindowSize {
        return window.innerWidth < 800 ? 'small' : 'large';
    }

    public static getWindowSize(): number {
        return window.innerWidth;
    }
}
