import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { defineMessage, defineMessages, useIntl } from 'react-intl';

import ResponsiveModal from '../Layout/ResponsiveModal';

const titleMessages = defineMessages({
    errorModalTitle: {
        id: 'ERROR_TITLE_DEFAULT',
        defaultMessage: 'Chyba',
    }
});

type ErrorModalProps = {
    title?: string;
    open: boolean;
    errorMessage?: any;
    onClose: () => void;
};

const ErrorModal: React.FC<ErrorModalProps> = ({
    title,
    open,
    errorMessage,
    onClose,
}) => {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <ResponsiveModal
            title={ title ?? intl.formatMessage(titleMessages.errorModalTitle) }
            open={ open }
            onClose={ onClose }
        >
            <Typography variant="body1">
                { errorMessage && intl.formatMessage(errorMessage) }
            </Typography>
        </ResponsiveModal>
    );
};

export default ErrorModal;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
}));
