import {
    Button, Popover, Typography, Link
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FeedbackIcon from '@material-ui/icons/FeedbackRounded';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { useRootNavigation } from '../../Hooks/useRootNavigation';
import { useStores } from '../../Stores/useStores';

import PlaceFeedbackModal from './PlaceFeedbackModal';

type PlaceFeedbackButtonProps = {
    place: PlaceInfo;
};

const PlaceFeedbackButton: React.FC<PlaceFeedbackButtonProps> = ({ place }) => {
    const rootNavigation = useRootNavigation();
    const { authStore } = useStores();
    const classes = useStyles();
    const [modalVisible, setModalVisible] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const onFeedbackClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (authStore.isSignedIn) {
            setModalVisible(true);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button variant="contained" color="secondary" className={ classes.feedbackButton } onClick={ onFeedbackClicked }>
                <Typography variant="button">
                    <FormattedMessage
                        id="BUTTON_CTA_PLACE_FEEDBACK"
                        defaultMessage="Nahlásit chybné údaje"
                    />
                </Typography>
                <FeedbackIcon className={ classes.rightIcon } />
            </Button>
            <Popover
                open={ open }
                anchorEl={ anchorEl }
                onClose={ handleClose }
                anchorOrigin={ {
                    vertical: 'top',
                    horizontal: 'center',
                } }
            >
                <div className={ classes.popoverContent }>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="BUTTON_CTA_PLACE_FEEDBACK_SIGNIN_REQUIRED"
                            defaultMessage="Pro hlášení chybných údajů se prosím nejprve přihlašte."
                        />
                    </Typography>
                    <Link to={ rootNavigation.getLinkTo('/signIn') } component={ RouterLink } className={ classes.signinLink }>
                        <FormattedMessage
                            id="BUTTON_CTA_PLACE_FEEDBACK_SIGNIN_REQUIRED_LINK"
                            defaultMessage="Přejít na přihlášení nebo registraci"
                        />
                    </Link>
                </div>
            </Popover>
            <PlaceFeedbackModal place={ place } visible={ modalVisible } onClose={ () => setModalVisible(false) } />
        </>
    );
};

export default observer(PlaceFeedbackButton);

const useStyles = makeStyles((theme: Theme) => createStyles({
    feedbackButton: {
        alignSelf: 'center',
        flex: 1,
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
        fontSize: 18,
    },
    popoverContent: {
        padding: theme.spacing(2),
    },
    signinLink: {
        marginTop: theme.spacing(2),
    },
}));
