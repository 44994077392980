import IEnvironment, { IEnvironment_TYPE } from '@shared/Core/IEnvironment';
import IocContainer from '@shared/Core/IocContainer';
import IAuthService from '@shared/Core/Services/IAuthService';
import firebase from 'firebase/app';
import 'firebase/auth';
import { injectable } from 'inversify';

import { FIREBASE_LANG_MAP } from '../../../languages';

@injectable()
export default class FirebaseAuthService implements IAuthService {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    public firebase!: firebase.app.App;

    public initialize(locale: string) {
        const languageCode = (FIREBASE_LANG_MAP as any)[locale] ?? FIREBASE_LANG_MAP.default;

        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: this.environment.firebaseAuth.apiKey,
            projectId: this.environment.firebaseAuth.projectId,
            appId: this.environment.firebaseAuth.appId,
            authDomain: this.environment.firebaseAuth.authDomain,
            languageCode,
        };
        // Initialize Firebase
        this.firebase = firebase.initializeApp(firebaseConfig, 'auth');

        this.firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    }

    public async getToken(): Promise<string | undefined> {
        if (!this.firebase) {
            return;
        }

        const { currentUser } = this.firebase.auth();
        if (!currentUser) {
            return;
        }
        return currentUser.getIdToken();
    }
}
