import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { placeTypeToImageFileName } from '@shared/Core/Utils/Helpers';
import React from 'react';

type PlaceIconProps = {
    placeType: string;
    size?: string;
    rounded?: boolean
};

const PlaceIcon: React.FC<PlaceIconProps> = ({ size, placeType, rounded }) => {
    const finalSize = size || '64px';
    const classes = useStyles({ size: finalSize, rounded });

    return (
        <img alt="point" className={ classes.root } src={ `/assets/${placeTypeToImageFileName(placeType)}` } height={ size } />
    );
};

export default PlaceIcon;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (args: any) => ({
        minWidth: args.size,
        borderRadius: args.rounded ? 100 : 0,
    }),
}));
