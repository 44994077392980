import { Toolbar } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

const ResponsiveToolbar: React.FC = ({ children }) => {
    const { layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    return (
        <Toolbar className={ classes.root } disableGutters>
            { children }
        </Toolbar>
    );
};

export default observer(ResponsiveToolbar);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        alignItems: 'center',
        padding: theme.spacing(0, 1.4),
        minHeight: 56,
    }),
}));
