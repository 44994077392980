import {
    Button, CircularProgress, Paper, Tab, Tabs, Typography
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useStores } from '../../../../Stores/useStores';
import { useSignInService } from '../../hooks/useSIgnInService';

import EmailSingIn from './EmailSignIn';
import { EmailSignInFormOptions } from './EmailSignInForm';
import EmailSingUp, { EmailSignUpFormOptions } from './EmailSignUp';
import LinkEmailForm from './LinkEmailForm';
import SignInContent from './SignInContent';
import { signInMessages } from '../../messages/signInMessages';
import SignInWithProvider from './SignInWIthProvider';

type SignInProps = {
};

const SignIn: React.FC<SignInProps> = () => {
    const intl = useIntl();
    const { layoutStore, signInStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });
    const [activeTab, setActiveTab] = React.useState(0);
    const [signUpMode, setSignUpMode] = useState(false);
    const isRedirect = useMemo(() => localStorage.getItem('auth-redirect'), []);
    const [differentCredentialsError, setDifferentCredentialsError] = useState<firebase.auth.AuthError>();

    const handleChange = (_event: any, newValue: any) => {
        setActiveTab(newValue);
        setSignUpMode(false);
    };

    const signInSvc = useSignInService();

    // handle signin redirect
    useEffect(() => {
        handleSignInRedirect();
    }, []);

    const handleSigninWithEmail = async (options: EmailSignInFormOptions) => {
        const { email, password } = options;
        await handleAuth(async () => {
            await signInSvc.signInWithEmail(email, password);
            window.location.href = '/';
        });
    };

    const handleSignUpWithEmail = async (options: EmailSignUpFormOptions) => {
        await handleAuth(async () => {
            await signInSvc.registerWithEmail(options);
            window.location.href = '/';
        });
    };

    if (isRedirect) {
        // handle different credentials case
        if (differentCredentialsError) {
            return (
                <LinkEmailForm
                    linkError={ differentCredentialsError }
                    onError={ (err) => signInStore.setErrorToDisplay(err.message) }
                />
            );
        }

        // indicate something is in progress
        return (
            <div className={ classes.loaderContainer }>
                <CircularProgress className={ classes.loader } size={ 30 } />
                <Typography variant="body1">
                    <FormattedMessage
                        id="SIGNIN_IN_PROGRESS"
                        defaultMessage="Probíhá přihlašování..."
                    />
                </Typography>
            </div>
        );
    }

    return (
        <>
            <Tabs value={ activeTab } onChange={ handleChange } variant="fullWidth" className={ classes.tabs }>
                <Tab label={ intl.formatMessage(signInMessages.tabSignIn) } />
                <Tab label={ intl.formatMessage(signInMessages.tabSignUp) } />
            </Tabs>
            <SignInContent>
                { signUpMode

                    ? (
                        <EmailSingUp
                            onSubmit={ handleSignUpWithEmail }
                        />
                    ) : (
                        <>
                            { activeTab === 0 && (
                                <EmailSingIn
                                    onSubmit={ handleSigninWithEmail }
                                />
                            ) }
                            { activeTab === 1 && (
                                <EmailSingUp
                                    onSubmit={ handleSignUpWithEmail }
                                />
                            ) }

                            <SignInWithProvider handleAuth={ handleExternalAuth } />
                        </>
                    ) }
            </SignInContent>
        </>
    );

    async function handleExternalAuth(handler: () => Promise<any>) {
        localStorage.setItem('auth-redirect', 'true');
        return handleAuth(handler);
    }

    async function handleAuth(handler: () => Promise<any>) {
        try {
            await handler();
        } catch (err) {
            localStorage.removeItem('auth-redirect');
            signInStore.setErrorToDisplay(err.message);
        }
    }

    async function handleSignInRedirect() {
        try {
            if (!isRedirect) { return; }

            localStorage.removeItem('auth-redirect');

            const result = await signInSvc.getRedirectResult();
            if (result.user) {
                window.location.href = '/';
            }
        } catch (err) {
            console.log(err);

            if (err.code === 'auth/account-exists-with-different-credential') {
                setDifferentCredentialsError(err as firebase.auth.AuthError);
                return;
            }
            signInStore.setErrorToDisplay(err.message);
        }
    }
};

export default observer(SignIn);

const useStyles = makeStyles((theme) => createStyles({
    tabs: {

    },
    tabContent: ({ isMobile }: any) => ({
        padding: isMobile
            ? theme.spacing(5, 4)
            : theme.spacing(5, 12),
    }),
    loader: {
        margin: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    loaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
