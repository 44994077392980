import { LatLng } from '../Types';

const TOLERANCE_LNG = 0.0001;
const TOLERANCE_LAT = 0.0001;

export function locationsEqual(loc1: LatLng, loc2: LatLng) {
    // get the moved distance
    const dx = Math.abs(loc1.lng - loc2.lng);
    const dy = Math.abs(loc1.lat - loc2.lat);
    // evaluate
    return dx <= TOLERANCE_LNG && dy <= TOLERANCE_LAT;
}
