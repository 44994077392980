import 'reflect-metadata';
import './index.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'mobx-react-lite/optimizeForReactDom';

import IEnvironment, { IEnvironment_TYPE } from '@shared/Core/IEnvironment';
import IocContainer from '@shared/Core/IocContainer';
import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '@shared/Core/Services/IAnalyticsLogger';
import IAuthService, { IAuthService_TYPE } from '@shared/Core/Services/IAuthService';
import IMapboxService, { IMapboxService_TYPE } from '@shared/Core/Services/IMapboxService';
import IPerformanceLogger, { IPerformanceLogger_TYPE } from '@shared/Core/Services/IPerformanceLogger';
import { injectable } from 'inversify';
import { configure } from 'mobx';
import { RouterStore } from 'mobx-react-router';

import IGeocoder, { IGeocoder_TYPE } from '../../shared/src/Core/Services/IGeocoder';
import { ILocaleStore, ILocaleStore_TYPE } from '../../shared/src/Core/Stores/ILocaleStore';

import FirebaseAuthService from './domain/login/services/FirebaseAuthService';
import { SignInService } from './domain/login/services/SignInService';
import { SignInStore } from './domain/login/stores/SignInStore';
import Environment from './environment';
import AnalyticsLogger from './Services/AnalyticsLogger';
import FirebaseService from './Services/FirebaseService';
import Geocoder from './Services/Geocoder';
import { IntlService } from './Services/IntlService';
import MapboxService from './Services/MapboxService';
import PerformanceLogger from './Services/PerformanceLogger';
import { AppStore } from './Stores/AppStore';
import LayoutStore from './Stores/LayoutStore';
import LocaleStore from './Stores/LocaleStore';

configure({
    useProxies: 'ifavailable',
    enforceActions: 'never',
    // TODO: enforceActions: 'observed',
});

IocContainer.setup((container) => {
    container.bind<IEnvironment>(IEnvironment_TYPE).to(Environment);
    container.bind<IAnalyticsLogger>(IAnalyticsLogger_TYPE).to(AnalyticsLogger);
    container.bind<IPerformanceLogger>(IPerformanceLogger_TYPE).to(PerformanceLogger);
    container.bind<IMapboxService>(IMapboxService_TYPE).to(MapboxService);
    container.bind<IGeocoder>(IGeocoder_TYPE).to(Geocoder);
    container.bind(IntlService).toSelf();
    container.bind(SignInService).toSelf();

    container.bind(FirebaseService).toSelf();
    container.bind<IAuthService>(IAuthService_TYPE).to(FirebaseAuthService);
    container.bind(AnalyticsLogger).toSelf();

    container.bind(LayoutStore).toSelf();
    container.bind(AppStore).toSelf();
    container.bind(SignInStore).toSelf();
    container.bind<ILocaleStore>(ILocaleStore_TYPE).to(LocaleStore);

    container.bind(RouterStore).to(injectable()(RouterStore));
});
