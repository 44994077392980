import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { useStores } from '../../../Stores/useStores';
import Panel from '../../Common/Panel';

import CategoriesBrowser from './CategoriesBrowser';
import CategoriesResults from './CategoriesResults';

const CategoriesView: React.FC = () => {
    const { categoryStore, layoutStore } = useStores();

    useEffect(() => {
        initLayout();
        categoryStore.clearSearch();
        return (() => {
            categoryStore.clearSearch();
        });
    }, []);

    const initLayout = () => {
        layoutStore.resetToDefaults();
        layoutStore.setMobilePanelFullscreen(true);
    };

    return (
        <Panel>
            { renderInner() }
        </Panel>
    );

    function renderInner() {
        if (categoryStore.isSearchActive) {
            return (<CategoriesResults />);
        }
        return (<CategoriesBrowser />);
    }
};

export default observer(CategoriesView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2),
    },
}));
