import IGeocoder, { GeocoderReverseResult, GeocoderResult } from '@shared/Core/Services/IGeocoder';
import { LatLng } from '@shared/Core/Types';
import { injectable } from 'inversify';

declare const SMap: any;

@injectable()
export default class Geocoder implements IGeocoder {
    private geocoderCache = new Map<string, GeocoderResult[]>();

    private reverseGeocoderCache = new Map<string, GeocoderReverseResult>();

    public async geocode(term: string) {
        if (this.geocoderCache.has(term)) {
            return this.geocoderCache.get(term) ?? [];
        }
        if (typeof SMap === 'undefined') return [];

        const items = await new Promise<GeocoderResult[]>((resolve, reject) => {
            const _ = new SMap.Geocoder(term, callback, {
                locality: 'cz|sk',
            });

            function callback(geocoder: any) {
                const { results } = geocoder.getResults()[0];

                if (!results.length) {
                    resolve([]);
                }

                const resultItems = results
                    .filter((r: any) => r.source === 'stre')
                    .map((r: any) => ({
                        id: r.id,
                        label: r.label,
                        location: {
                            lat: r.coords.y,
                            lng: r.coords.x,
                        },
                    } as GeocoderResult));
                resolve(resultItems);
            }
        });

        this.geocoderCache.set(term, items);
        return items;
    }

    public async reverseGeocode(location: LatLng, lang: string) {
        const locationKey = `${location.lat};${location.lng}`;

        if (this.reverseGeocoderCache.has(locationKey)) {
            const cached = this.reverseGeocoderCache.get(locationKey);
            if (!cached) { throw Error('Invalid cached result.'); }
            return cached;
        }

        if (typeof SMap === 'undefined') return {items: [], label: 'Zjišťuji adresu'};

        const result = await new Promise<GeocoderReverseResult>((resolve, reject) => {
            const coords = SMap.Coords.fromWGS84(location.lng, location.lat);
            const _ = new SMap.Geocoder.Reverse(coords, callback, { lang });

            function callback(geocoder: any) {
                const results = geocoder.getResults();
                resolve(results);
            }
        });

        this.reverseGeocoderCache.set(locationKey, result);
        return result;
    }
}
