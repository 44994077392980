import { debounce } from 'debounce';
import { injectable } from 'inversify';
import {
    observable, action, runInAction, reaction, makeObservable
} from 'mobx';

import IocContainer from '../IocContainer';
import { LocationSearchItem } from '../Models/LocationSearchItem';
import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '../Services/IAnalyticsLogger';
import SearchLocationService from '../Services/SearchLocationService';
import OrderedInvoker from '../Utils/orderedInvoker';

import HomeStore from './HomeStore';

@injectable()
export class SearchLocationStore {
    private analyticsLogger = IocContainer.get<IAnalyticsLogger>(IAnalyticsLogger_TYPE);

    private searchLocationService = IocContainer.get(SearchLocationService);

    private homeStore = IocContainer.get(HomeStore);

    @observable public modalOpen: boolean = false;

    @observable public searchTerm = '';

    @observable results: LocationSearchItem[] = [];

    @observable resultsAreLoading: boolean = false;

    private searchNearbyInvoker = new OrderedInvoker();

    public searchDebounced = debounce(this.search, 500);

    private logSearchDebounced = debounce(this.logSearch, 1000);

    constructor() {
        makeObservable(this);
        this.setupReactions();
    }

    @action public openModal() {
        this.modalOpen = true;
    }

    @action public closeModal() {
        this.modalOpen = false;
    }

    @action public setSearchTerm(value: string) {
        this.searchTerm = value;
        this.searchDebounced();
    }

    @action async search() {
        this.resultsAreLoading = true;

        await this.searchNearbyInvoker.continueIfNewest(
            async () => {
            // call api
                this.logSearchDebounced();

                return this.searchLocationService.searchLocations(
                    this.searchTerm,
                    (this.searchTerm && this.homeStore.activeMapLocation) || undefined
                );
            },
            async (results) => {
                // apply most recent results
                runInAction(() => {
                    this.results = results;
                    this.resultsAreLoading = false;
                });
            }
        );
    }

    private logSearch() {
        if (!this.searchTerm) {
            return;
        }

        // analytics logging
        this.analyticsLogger.track({
            type: 'Search',
            data: {
                content_category: 'searchMunicipality',
                search_string: this.searchTerm,
            },
        });
    }

    private setupReactions() {
        reaction(() => [this.modalOpen], () => {
            if (this.modalOpen) {
                this.setSearchTerm('');
                runInAction(() => {
                    this.results = [];
                });
            }
        });
    }
}
