import { injectable } from 'inversify';

import IEnvironment, { IEnvironment_TYPE } from '../../../shared/src/Core/IEnvironment';
import IocContainer from '../../../shared/src/Core/IocContainer';

@injectable()
export class IntlService {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    public async loadMessages(locale: string): Promise<Record<string, string>> {
        const response = await fetch(`/translations/${locale}.json?v=${this.environment.versionHash}`);
        return response.json();
    }
}
