import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const Square: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={ classes.root }>
            <div className={ classes.wrapper }>
                <div className={ classes.container }>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default Square;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'block',
        position: 'relative',
        paddingTop: '100%',
    },
    wrapper: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
    },
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        margin: 0,
        overflow: 'hidden',
    },
}));
