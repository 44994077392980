import IEnvironment, { IEnvironment_TYPE } from '@shared/Core/IEnvironment';
import IocContainer from '@shared/Core/IocContainer';
import IAnalyticsLogger, { AnalyticsEvent } from '@shared/Core/Services/IAnalyticsLogger';
import { injectable } from 'inversify';
import ReactPixel from 'react-facebook-pixel';

@injectable()
export default class AnalyticsLogger implements IAnalyticsLogger {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    public initialize() {
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: !this.environment.isProd, // enable logs
        };
        ReactPixel.init(this.environment.facebookPixelId, undefined, options);

        this.track({ type: 'PageView' });
    }

    public track(event: AnalyticsEvent) {
        ReactPixel.track(event.type, event.data);
    }
}
