/* eslint-disable no-await-in-loop */
import firebase, { FirebaseError } from 'firebase/app';
import { injectable } from 'inversify';

import IocContainer from '../../../../../shared/src/Core/IocContainer';
import ApiService from '../../../../../shared/src/Core/Services/ApiService';
import { IAuthService_TYPE } from '../../../../../shared/src/Core/Services/IAuthService';

import FirebaseAuthService from './FirebaseAuthService';

export type PromptUserPasswordCallback = (email: string, resolvePassword: (password: string) => void, reject: () => void) => void;

@injectable()
export class SignInService {
    private api = IocContainer.get(ApiService);

    private authService = IocContainer.get<FirebaseAuthService>(IAuthService_TYPE);

    public async resetPassword(email: string) {
        return this.authService.firebase.auth().sendPasswordResetEmail(email);
    }

    public async registerWithEmail(options: { email: string, password: string, nickname?: string, firstName: string, lastName: string}) {
        try {
            const {
                email, password, firstName, lastName, nickname,
            } = options;
            const { user } = await this.authService.firebase.auth().createUserWithEmailAndPassword(email, password);
            if (!user) {
                throw Error('User is null');
            }
            await Promise.all([
                user.updateProfile({
                    displayName: `${firstName} ${lastName}`,
                }),
                this.api.updateUserDetails({
                    firstName,
                    lastName,
                    nickname,
                }),
            ]);
        } catch (err) {
            await this.handleFirebaseError(err);
        }
    }

    public async signInWithEmail(email: string, password: string) {
        try {
            const result = await this.authService.firebase.auth().signInWithEmailAndPassword(email, password);
            return result.user;
        } catch (err) {
            await this.handleFirebaseError(err);
        }
    }

    public async signInWithFacebook() {
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.addScope('email');
        provider.addScope('public_profile');
        return this.signInWithProvider(provider);
    }

    public async signInWithGoogle() {
        return this.signInWithProvider(new firebase.auth.GoogleAuthProvider());
    }

    public async getRedirectResult() {
        const result = await this.authService.firebase.auth().getRedirectResult();
        return result;
    }

    private async handleFirebaseError(err: FirebaseError) {
        throw this.transformFirebaseError(err);
    }

    private async signInWithProvider(provider: firebase.auth.AuthProvider) {
        try {
            await this.authService.firebase.auth().signInWithRedirect(provider);
        } catch (err) {
            await this.handleFirebaseError(err);
        }
    }

    private transformFirebaseError(err: FirebaseError) {
        console.log('auth error', err);
        // TODO: log it
        // switch (err.code) {
        // case 'auth/user-not-found':
        //     return new Error('AUTH_NON_EXISTING_USER');
        // case 'auth/wrong-password':
        //     return new Error('AUTH_WRONG_PASSWORD');
        // case 'auth/email-already-in-use':
        //     return new Error('AUTH_EMAIL_ALREADY_USED');
        // case 'auth/weak-password':
        //     return new Error('AUTH_WEAK_PASSWORD');
        // default:
        //     return new Error('AUTH_UNHANDLED_ERROR_OCCURED');
        // }
        return new Error(err.code);
    }

    public async handleAccountExistsWithDifferentCredential(linkError: firebase.auth.AuthError, promptUserForPassword: PromptUserPasswordCallback, onAuthError: (error: Error) => void) {
        // User's email already exists.
        // The pending Google credential.
        const pendingCred = linkError.credential;
        if (!pendingCred) {
            throw Error('Credentials not available.');
        }

        // The provider account's email address.
        const { email } = linkError;
        if (!email) {
            throw Error('Email not available.');
        }

        const methods = await this.authService.firebase.auth().fetchSignInMethodsForEmail(email);

        const primaryMethod = methods[0];

        if (primaryMethod === 'password') {
            // attempt to link external provider to email-password account
            return this.handleAccountExistsWithEmailCredentials(email, pendingCred, promptUserForPassword, onAuthError);
        }

        // attempt to link external providers together
        return this.handleAccountExistsWithDifferentProvider(primaryMethod, pendingCred);
    }

    private async handleAccountExistsWithEmailCredentials(
        email: string,
        pendingCred: firebase.auth.AuthCredential,
        promptUserForPassword: PromptUserPasswordCallback,
        onAuthError: (error: Error) => void
    ) {
        let user: firebase.User | null = null;
        // give user multiple attempts to confirm the password
        do {
            let password: string;
            try {
                password = await new Promise((resolve, reject) => promptUserForPassword(email, resolve, reject));
            } catch (err) {
                console.log('password prompt rejected');
                // break the attempt cycle
                break;
            }

            try {
                const result = await this.authService.firebase.auth().signInWithEmailAndPassword(email, password);
                user = result.user;
                if (!user) {
                    // give another attempt
                    console.log('Another attempt to confirm the password.');
                }
            } catch (err) {
                console.log(err);
                console.log('Another attempt to confirm the password.');
                onAuthError(this.transformFirebaseError(err));
            }
        } while (!user);

        if (!user) {
            // the process has been cancelled
            return false;
        }

        await user.linkWithCredential(pendingCred);

        return true;
    }

    private async handleAccountExistsWithDifferentProvider(signInMethod: string, pendingCred: firebase.auth.AuthCredential) {
        const provider = this.mapProviderIdToProvider(signInMethod);

        // TODO: figure out how to do this with redirect
        const result = await this.authService.firebase.auth().signInWithPopup(provider);

        if (!result.user) {
            throw Error('Failed to authenticate');
        }
        await result.user.linkWithCredential(pendingCred);
        return true;
    }

    private mapProviderIdToProvider(id: string): firebase.auth.AuthProvider {
        switch (id) {
        case 'google.com':
            return new firebase.auth.GoogleAuthProvider();
        case 'facebook.com':
            return new firebase.auth.FacebookAuthProvider();
        default:
            throw Error(`Unsupported provider ${id}`);
        }
    }
}
