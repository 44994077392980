import { Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import AssetIcon from './Common/AssetIcon';

const LegendViewMessages = defineMessages({
    legendReuse: {
        id: 'LEGEND_REUSE_TEXT',
        defaultMessage: 'Místo s odběrem nepotřebných předmětů k dalšímu využití.',
    },
    legendRecycle: {
        id: 'LEGEND_RECYCLE_TEXT',
        defaultMessage: 'Místo s odběrem nepotřebných předmětů či odpadků k recyklaci.',
    },
    legendWaste: {
        id: 'LEGEND_WASTE_TEXT',
        defaultMessage: 'Místo s odběrem odpadků k likvidaci.',
    },
});

type LegendViewProps = {
};

const LegendView: React.FC<LegendViewProps> = (props) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <div className={ classes.inner }>
            <Grid container direction="row" spacing={ 4 }>
                { renderItem('legend-reuse.png', intl.formatMessage(LegendViewMessages.legendReuse)) }
                { renderItem('legend-recycle.png', intl.formatMessage(LegendViewMessages.legendRecycle)) }
                { renderItem('legend-waste.png', intl.formatMessage(LegendViewMessages.legendWaste)) }
            </Grid>
        </div>
    );

    function renderItem(iconName: string, content: string) {
        return (
            <Grid item xs={ 12 } md={ 6 }>
                <Grid container direction="row">
                    <Grid item xs={ 4 }>
                        <AssetIcon name={ iconName } height={ 48 } />
                    </Grid>
                    <Grid item xs={ 8 } alignItems="center">
                        <Typography variant="caption" component="div">{ content }</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
};

export default LegendView;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    inner: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.spacing(5, 2),
        marginBottom: params.isMobile ? '20vh' : undefined,
    }),
}));
