import React from 'react';

import ImageIcon, { ImageIconProps } from './ImageIcon';

type AssetIconProps = {
    name: string;
} & Pick<ImageIconProps, 'height'>;

const AssetIcon: React.FC<AssetIconProps> = ({ name, height }) => (
    <ImageIcon alt={ name } height={ height } src={ `/assets/${name}` } />
);

export default AssetIcon;
