import { Typography } from '@material-ui/core'; // , Grid
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import MarkDown from '../Components/Common/MarkDown';

import Page from '../Components/Layout/Page';

const OrganizationPageMessages = defineMessages({
    pagesOrganizationContentTitle: {
        id: 'PAGES_ORGANIZATION_CONTENT_TITLE',
        defaultMessage: 'O spolku Ukliďme Česko',
    },
    pagesOrganizationContentIntro: {
        id: 'PAGES_ORGANIZATION_CONTENT_INTRO',
        defaultMessage: `"Ukliďme Česko z.s. je brněnská nezisková organizace, založená v roce 2013 (původně pod názvem „Ekosmák“). Jedná se o spolek několika málo ekologických nadšenců, vzniklý původně zejména kvůli organizaci celorepublikové akce **„Ukliďme Česko“**. Ta získala hned při svém prvním ročníku prestižní cenu **E.ON Energy Globe Awards** a stala se během několika málo roků největší dobrovolnickou akcí u nás, s více než **150 000 zapojených dobrovolníků v roce 2019**. Naším cílem však není donekonečna uklízet nepořádek po druhých. Proto jsme se při své činnosti začali postupně zaměřovat i na osvětové a vzdělávací aktivity zaměřené od prevence vytváření nepořádku či omezování vzniku odpadů až po aktivity směřující k cirkulární ekonomice. Jedním z takových projektů je i naše „nejmladší dítě“, **aplikace „Kam s ním?“**, originální vyhledávač míst ve vašem okolí, kde se můžete legálně zbavit nechtěných věcí a odpadů. S tímto projektem máme velké ambice a dozajista o něm ještě uslyšíte… 


*Radek Janoušek, předseda spolku Ukliďme Česko*, Brno, 1.6.2020 

--- 

Obsáhlejší vymezení činnosti spolku z našich stanov:"`,
    },
    pagesOrganizationContentTitle2: {
        id: 'PAGES_ORGANIZATION_CONTENT_TITLE2',
        defaultMessage: 'Čl. II - Účel spolku',
    },
    pagesOrganizationContentParagraph2: {
        id: 'PAGES_ORGANIZATION_CONTENT_PARAGRAPH2',
        defaultMessage: 'Účelem spolku je poskytování obecně prospěšných činností v oblasti ochrany životního prostředí, přírody, krajiny, ochrany lidského zdraví a dalších aktivit ve smyslu občanské společnosti.',
    },
    pagesOrganizationContentTitle3: {
        id: 'PAGES_ORGANIZATION_CONTENT_TITLE3',
        defaultMessage: 'Čl. III - Hlavní činnost spolku',
    },
    pagesOrganizationContentParagraph3: {
        id: 'PAGES_ORGANIZATION_CONTENT_PARAGRAPH3',
        defaultMessage: `"Činnost spolku směřuje k naplnění účelu popsaného v čl. II., coby společného zájmu jeho členů. Tento účel je naplňován zejména prostřednictvím:

* poskytování a šíření informací o životním prostředí a o ochraně přírody a krajiny,
* osvětové a edukační činnosti,
* výzkumu a vzdělávání,
* analýzy, komentování a propagace právních instrumentů na ochranu životního prostředí,
* praktické spolupráce s jinými subjekty na národní i mezinárodní úrovni,
* pořádání seminářů, přednášek a konferencí,
* organizování kampaní a petičních aktivit,
* propagace aktivit spolku a osvěty v ochraně životního prostředí,
* vydávání a distribuce publikací, brožur, letáků a dalších materiálů,
* tvorby a provozování webových a mobilních aplikací,
* tvorby audio-vizuálních děl,
* poradenské, propagační a konzultační činnosti,
* účasti na konzultačních a rozhodovacích procesech týkajících se životního prostředí,
* účasti ve správních i jiných řízeních, ve kterých mohou být dotčeny zájmy ochrany životního prostředí,
* zapojení dobrovolníků a podpory dobrovolnictví,
* realizace projektů směřujících k ochraně životního prostředí."`,
    },
});

const OrganizationPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Page currentPage="organization">
            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentTitle) }
            </Typography>
            <br />

            <MarkDown source={ intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentIntro) } />
            <br />
            <Typography className={ classes.title } variant="h5" component="h2">
                { intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentTitle2) }
            </Typography>
            <MarkDown source={ intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentParagraph2) } />
            <br /><br />
            <Typography className={ classes.title } variant="h5" component="h2">
                { intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentTitle3) }
            </Typography>
            <MarkDown source={ intl.formatMessage(OrganizationPageMessages.pagesOrganizationContentParagraph3) } />
            
        </Page>
    );
};

export default OrganizationPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    root: {

    },
    img: {
        margin: theme.spacing(1),
    },
}));
