import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import { useStores } from '../../../Stores/useStores';
import Panel from '../../Common/Panel';
import PlacesList from '../../Place/PlacesList';

type SearchPlacesViewProps = {} & RouteComponentProps;

const SearchPlacesView: React.FC<SearchPlacesViewProps> = () => {
    const { searchPlacesStore, layoutStore } = useStores();
    const { navigateToPlace } = useHomeNavigation();
    const classes = useStyles();

    React.useEffect(() => {
        layoutStore.setMobilePanelFullscreen(true);
        searchPlacesStore.beginSearch();
        return (() => {
            searchPlacesStore.finishSearch();
        });
    }, []);

    const onItemClick = (item: PlaceInfo) => {
        navigateToPlace(item.id);
    };

    return (
        <Panel>
            <div className={ classes.root }>
                { searchPlacesStore.results.length > 0
                    ? (
                        <PlacesList items={ searchPlacesStore.results } loading={ searchPlacesStore.resultsAreLoading } onItemClick={ onItemClick } />
                    )
                    : renderMessage() }
            </div>
        </Panel>
    );

    function renderMessage() {
        return searchPlacesStore.searchTerm.length === 0
            ? (
                <Typography className={ classes.root }>
                    <FormattedMessage
                        id="PLACES_SEARCH_DEFAULT_MESSAGE"
                        defaultMessage="Po zadání názvu se odpovídající místa zobrazí v tomto seznamu."
                    />
                </Typography>
            ) : (
                <Typography className={ classes.root }>
                    <FormattedMessage
                        id="PLACES_SEARCH_EMPTY_RESULT_MESSAGE"
                        defaultMessage="Nebylo nalezeno žádné místo odpovídající zadanému názvu."
                    />
                </Typography>
            );
    }
};

export default observer(SearchPlacesView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
}));
