import {
    Typography, Modal, IconButton, AppBar
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

import ResponsiveToolbar from './ResponsiveToolbar';


type ResponsiveModalProps = {
    title: string;
    open: boolean;
    onClose: () => void;
    noPadding?: boolean;
    toolbarComponent?: React.ReactNode;
};

const ResponsiveModal: React.FC<ResponsiveModalProps> = ({
    title, open, onClose, children, noPadding, toolbarComponent,
}) => {
    const { layoutStore } = useStores();
    const classes = useStyles({
        isMobile: layoutStore.isMobile,
        noPadding,
    });

    const handleClose = () => onClose();

    return (
        <Modal
            open={ open }
            onClose={ handleClose }
        >
            <div className={ layoutStore.isMobile ? classes.paperMobile : classes.paperDesktop }>
                <AppBar position="static">
                    <ResponsiveToolbar>
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={ handleClose }
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={ classes.title }>{ title }</Typography>
                    </ResponsiveToolbar>
                    { toolbarComponent && (
                        <div>
                            { toolbarComponent }
                        </div>
                    ) }
                </AppBar>
                <div className={ classes.content }>
                    { children }
                </div>
            </div>
        </Modal>
    );
};

export default observer(ResponsiveModal);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    },
    paperDesktop: {
        position: 'absolute',
        width: 550,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid white',
        boxShadow: theme.shadows[5],
        top: '45%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        borderRadius: 16,
        overflow: 'hidden',
        outline: 'none',
    },
    paperMobile: {
        position: 'fixed',
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%',
    },
    content: (params: any) => ({
        padding: params.noPadding ? 0 : theme.spacing(3, 4),
        overflowY: 'auto',
        overflowX: 'hidden',
        height: params.isMobile ? 'calc(100vh - 56px)' : undefined,
        // maxHeight: params.isMobile ? 'calc(100vh - 56px)' : 'min(500px, 80vh)',
    }),
    title: {
        marginLeft: theme.spacing(2),
        flexGrow: 1,
    },
}));
