import { injectable } from 'inversify';
import { observable, action, reaction, makeObservable } from 'mobx';
import { RouterStore } from 'mobx-react-router';
import queryString from 'query-string';

import { ApiOptions } from '../../../shared/src/Core/ApiOptions';
import IocContainer from '../../../shared/src/Core/IocContainer';
import { AppHosts } from '../../../shared/src/Core/Types';

@injectable()
export class AppStore {
    @observable routerStore = IocContainer.get(RouterStore);

    private apiOptions = IocContainer.get(ApiOptions);

    @observable public allowCovid = false;

	@observable public isApp = false;

    @observable public rootPath = '/';

    @observable public hostApp: AppHosts = 'browser';

    constructor() {
        makeObservable(this);
		//console.log("CCC");
		//console.log(this.routerStore);
        this.setupHostedAppReaction();
    }

    @action public setRootPath(path: string) {
        this.rootPath = path;
    }

    private setupHostedAppReaction() {
		this.check();
        reaction(() => this.routerStore, () => {
			this.check();
        });
    }

	public check() {
            const params = queryString.parse(this.routerStore.location?.search);
			//console.log("AAAA");
			//console.log(params);
            const isAndroidApp = !!params.androidApp;
            const isIOSApp = !!params.iosApp;

            let hostApp: AppHosts = 'browser';
            if (isAndroidApp) {
                hostApp = 'android';
            } else if (isIOSApp) {
                hostApp = 'ios';
            }

			this.isApp = hostApp === 'android' || hostApp === 'ios';

            this.hostApp = hostApp;
            this.apiOptions.hostApp = hostApp;

            // IMPORTAT: disable covid feature for Android and iOS apps in order to meet Google Play and AppStore policies
            this.allowCovid = hostApp === 'browser';
    }
}
