import { useLocation } from 'react-router';

export function useNavigation() {
    const location = useLocation();

    const constructPath = (path: string) => (location.search ? `${path}${location.search}` : path);

    return {
        constructPath,
    };
}
