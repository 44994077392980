import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import AspectRatio from '../../Common/AspectRatio';

type PlacePhotoProps = {
    photoUrl?: string;
    ratio: number;
};

const PlacePhoto: React.FC<PlacePhotoProps> = ({ photoUrl, ratio }) => {
    const classes = useStyles({
        headerPhotoUrl: photoUrl,
    });

    return (
        <AspectRatio ratio={ ratio }>
            <div className={ classes.root } />
        </AspectRatio>
    );
};

export default PlacePhoto;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (args: any) => ({
        height: '100%',
        minHeight: 140,
        backgroundSize: 'cover',
		backgroundPositionY: 'center',
        backgroundImage: args.headerPhotoUrl && `url(${args.headerPhotoUrl})`,
    }),
}));
