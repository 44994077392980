import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import CategoryItem from '../Models/CategoryItem';
import CategoryProduct from '../Models/CategoryProduct';
import CategorySearchResult from '../Models/CategorySearchResult';
import CategoryViewModel from '../Models/CategoryViewModel';
import SearchCategoriesCriteria from '../Models/SearchCategoriesCriteria';
import { isNullOrUndefined, distinct, normalizeString } from '../Utils/Helpers';

import ConfigurationService from './ConfigurationService';

@injectable()
export default class CategoryService {
    private configurationService = IocContainer.get(ConfigurationService);

    public async getCategories(): Promise<CategoryViewModel[]> {
        const config = await this.configurationService.getConfiguration();
        return this.mapCategoriesToViewModel(config.categories);
    }

    public async getCategory(categoryId: string): Promise<CategoryViewModel | null> {
        const allCategories = await this.getCategories();
        const results = this.searchCategories(allCategories, { id: categoryId }, []);
        if (results.length === 0) {
            return null;
        }
        return results[0].data as CategoryViewModel;
    }

    public async getCategoryPath(categoryId: string) {
        const allCategories = await this.getCategories();

        const foundPaths = allCategories
            .map((c) => a(c, []))
            .filter((p) => p !== null);

        return foundPaths.length === 0
            ? null
            : foundPaths[0];

        function a(parent: CategoryViewModel, path: CategoryViewModel[]): Array<CategoryViewModel> | null {
            const thisPath = [...path];
            if (!parent.children) {
                return null;
            }

            thisPath.push(parent);
            if (parent.id === categoryId) {
                return thisPath;
            }

            const paths = parent.children
                .filter((ch) => !!ch.children)
                .map((ch) => a(ch, thisPath))
                .filter((p) => p !== null);

            if (paths.length === 0) {
                return null;
            }

            const finalPath = paths[0] as CategoryViewModel[];

            return finalPath;
        }
    }

    public searchCategories(categories: CategoryViewModel[], criteria: SearchCategoriesCriteria, initalValue: CategorySearchResult[]): CategorySearchResult[] {
        if (!isNullOrUndefined(criteria.searchTerm) && !criteria.searchTerm) {
            return [];
        }
        const filteredCategories = categories.reduce((acc, childCategory) => [...acc, ...this.searchCategory(childCategory, criteria)], initalValue);
        const distinctCategories = distinct(filteredCategories, (c) => c.id);
        return distinctCategories;
    }

    private searchCategory(category: CategoryViewModel, criteria: SearchCategoriesCriteria): CategorySearchResult[] {
        let results: CategorySearchResult[] = [];
        if (category.children) {
            results = [...results, ...this.searchCategories(category.children, criteria, results)];
        } else if (this.matchCategoryTerm(category, criteria)) {
            results = [this.mapCategoryToResult(category)];
        }
        if (category.products) {
            const products = category.products
                .filter((p) => this.matchProductTerm(p, criteria))
                .map((p) => this.mapProductToResult(p, category.id));
            results = [...results, ...products];
        }
        return results;
    }

    private matchCategoryTerm(category: CategoryViewModel, criteria: SearchCategoriesCriteria): boolean {
        if (criteria.searchTerm) {
            const categoryName = normalizeString(category.name);
            const parts = normalizeString(criteria.searchTerm).split(' ').map((p) => p.trim());
            return parts.every((part) => categoryName.includes(part) || this.matchKeywords(category.keywords, part));
        } if (criteria.id) {
            return category.id === criteria.id;
        }
        return false;
    }

    private matchProductTerm(product: CategoryProduct, criteria: SearchCategoriesCriteria): boolean {
        if (criteria.searchTerm) {
            const productName = normalizeString(product.name);
            const parts = normalizeString(criteria.searchTerm).split(' ').map((p) => p.trim());
            return parts.every((part) => productName.includes(part) || this.matchKeywords(product.keywords, part));
        } if (criteria.id) {
            return product.id === criteria.id;
        }
        return false;
    }

    private matchKeywords(keywords: string[] | string, term: string) {
        if (!keywords) {
            return false;
        } if (Array.isArray(keywords)) {
            return keywords.some((kw) => {
                const keyword = normalizeString(kw);
                return keyword.includes(term);
            });
        }
        return normalizeString(keywords).includes(term);
    }

    private mapCategoryToResult(category: CategoryViewModel): CategorySearchResult {
        return {
            id: category.id,
            type: 'category',
            categoryId: category.id,
            name: category.name,
            icon: category.icon,
            data: category,
        };
    }

    private mapProductToResult(product: CategoryProduct, categoryId: string): CategorySearchResult {
        return {
            id: product.id,
            type: 'product',
            categoryId,
            productId: product.id,
            name: product.name,
            icon: product.icon ?? '/assets/category.png',
            data: product,
        };
    }

    private mapCategoriesToViewModel(categories: CategoryItem[]): CategoryViewModel[] {
        return categories.map((category) => this.mapCategoryToViewModel(category));
    }

    private mapCategoryToViewModel(category: CategoryItem): CategoryViewModel {
        const { children, ...common } = category;
        const viewModel: CategoryViewModel = { ...common };

        if (category.children) {
            let totalCount = category.children.length;
            viewModel.children = [];
            for (const child of category.children) {
                const childVm = this.mapCategoryToViewModel(child);
                if (childVm.totalCount) {
                    totalCount += childVm.totalCount;
                }
                viewModel.children.push(childVm);
            }
            viewModel.totalCount = totalCount;
        }

        return viewModel;
    }

    public async mapCategoryIds(categoryIds: string[]) {
        const mapped: CategoryViewModel[] = [];
        for (const id of categoryIds) {
            const c = await this.getCategory(id);
            if (!c) { continue; }
            mapped.push(c);
        }
        return mapped;
    }

    public async getCategoriesMap() {
        const config = await this.configurationService.getConfiguration();

        const categories = new Map<string, CategoryItem>();

        const queue: CategoryItem[] = [...config.categories];
        while (queue.length > 0) {
            const [item] = queue.splice(0, 1);

            categories.set(item.id, item);

            if (item.children) {
                queue.push(...item.children);
            }
        }

        return categories;
    }
}
