import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useStores } from '../../Stores/useStores';

import Loader from './Loader';

type PanelProps = {
    headerComponent?: React.ReactNode;
    infiniteScroll?: boolean;
    loading?: boolean;
    loadingMore?: boolean;
    hasMoreToLoad?: boolean;
    onLoadMore?: () => Promise<void>;
};

const Panel: React.FC<PanelProps> = ({
    headerComponent,
    infiniteScroll,
    hasMoreToLoad,
    loading,
    loadingMore,
    onLoadMore,
    children,
}) => {
    const { layoutStore } = useStores();
    const classes = useStyles();
    const [isLoadingMore, setLoadingMore] = React.useState(false);

    const onReachEnd = async () => {
        if (layoutStore.isMobile) { return; }
        await loadMore();
    };

    const onLoadMoreClicked = async () => {
        await loadMore();
    };

    const shouldUsePerfectScrollbar = !layoutStore.isMobile; // do use perfect scollbar for desktop only
    return (
        <div className={ classes.root }>
            { headerComponent && (
                <div className={ classes.header }>
                    { headerComponent }
                </div>
            ) }
            <div className={ classes.contentWrapper }>
                { shouldUsePerfectScrollbar
                    ? (
                        <div className={ classes.content }>
                            <PerfectScrollbar onYReachEnd={ onReachEnd }>
                                { renderContent() }
                                { /* {values.map((a, i) => (<div>Hello #{i}</div>))} */ }
                            </PerfectScrollbar>
                        </div>
                    )
                    : (
                        <>
                            { renderContent() }
                            { infiniteScroll && hasMoreToLoad && !loading && !loadingMore && (
                                <Button onClick={ onLoadMoreClicked }>Zobrazit více</Button>
                            ) }
                        </>
                    ) }
            </div>
        </div>
    );

    function renderContent() {
        const showLoader = loadingMore && !loading;
        return (
            <>
                { children }
                { showLoader && (
                    <Loader />
                ) }
            </>
        );
    }

    async function loadMore() {
        if (isLoadingMore || !hasMoreToLoad) { return; }

        if (onLoadMore) {
            setLoadingMore(true);
            await onLoadMore();
            setLoadingMore(false);
        }
    }
};

// const values = (() => {
//     const items = [];
//     for (let i = 0; i < 100; i++) {
//         items.push('Hello #' + i);
//     }
//     return items;
// })();

export default observer(Panel);

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    contentWrapper: {
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    header: {
        // padding: theme.spacing(2, 3),
        // borderBottom: '1px solid lightgray',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}));
