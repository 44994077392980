export type AnalyticsEvent = {
    type: 'Contact',
    data?: any,
} | {
    type: 'PageView',
    data?: any,
} | {
    type: 'Search',
    data?: {
        content_category?: string,
        search_string?: string,
        value?: string,
        content_ids?: string[],
        contents?: string,
    },
} | {
    type: 'ViewContent',
    data?: {
        content_ids?: string[],
        content_category?: string,
        content_type?: string,
        contents?: string,
        value?: string,
    },
};

export default interface IAnalyticsLogger {
    initialize(): void;
    track(event: AnalyticsEvent): void;
}

export const IAnalyticsLogger_TYPE = Symbol.for('IAnalyticsLogger');
