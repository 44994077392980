import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';

import { Point } from '../Models/Point';
import { LatLng, Bounds, MapType } from '../Types';

export type MapLocationState = 'locating' | 'located' | 'error';
export default interface IMapboxService {
    readonly locationAccuracy: number;

    userPosition: BehaviorSubject<LatLng | null>;
    mapPosition: ReplaySubject<{ bounds: Bounds; center: LatLng; zoom: number; }>;
    pinClick: Subject<string>;
    municipalityClick: Subject<never>;
    clusterClick: Subject<LatLng>;
    selectedPosition: BehaviorSubject<LatLng | null>;
    locationState: BehaviorSubject<MapLocationState>;
    clearSelectedPosition: Subject<void>;

    ready(): Promise<boolean>;
    projectLngLat(latLng: LatLng): Point;
    projectBoundsPosition(bounds: Bounds): Point;
    addPaddingToBounds(bounds: Bounds, padding: number): Bounds;
    locateUser(options?: { skipFlyToLocation: boolean }): Promise<void>;
    clearMapLocation(): void;
    zoomTo(zoom: number): void;
    getZoom(): number;
    flyTo(center: LatLng, zoom: number): Promise<void>;
    flyToBounds(bounds: Bounds): Promise<void>;
    flyToLocations(points: LatLng[], defaultZoom: number): Promise<void>;
    setCenter(center: LatLng, zoom: number): void;
    setSelectedPosition(position: LatLng): void;
    setMapType(type: MapType): void;
}

export const IMapboxService_TYPE = Symbol.for('IMapboxService');
