import {
    IconButton, Fab
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import ResponsiveModal from '../Layout/ResponsiveModal';
import ShareView from '../ShareView';

const ShareModalMessages = defineMessages({
    title: {
        id: 'SHARE_TITLE',
        defaultMessage: 'Sdílet',
    },
});

type ShareButtonProps = {
    className?: string;
    fab?: boolean,
};

const ShareButton: React.FC<ShareButtonProps> = observer(({ className, fab }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const handleShareClick = () => {
        setOpen(true);
    };

    const handleModalClosed = () => {
        setOpen(false);
    };

    return (
        <>
            { fab
                ? (
                    <Fab
                        size="medium"
                        color="inherit"
                        onClick={ handleShareClick }
                        className={ className }
                        aria-label="share"
                    >
                        <ShareIcon />
                    </Fab>
                )
                : (
                    <IconButton onClick={ handleShareClick } className={ className } color="inherit" aria-label="share">
                        <ShareIcon />
                    </IconButton>
                ) }
            <ResponsiveModal open={ open } title={ intl.formatMessage(ShareModalMessages.title) } onClose={ handleModalClosed }>
                <ShareView />
            </ResponsiveModal>
        </>
    );
});

export default ShareButton;
