import { Paper } from '@material-ui/core';
import React from 'react';

const SidePanel: React.FC = ({ children }) => (
    <Paper className="side-panel">
        { children }
    </Paper>
);

export default SidePanel;
