import {
    Select, MenuItem, Typography
} from '@material-ui/core';
import {
    createStyles, Theme, makeStyles
} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { languages } from '../../languages';
import { changeLocale } from '../../operations/changeLocale';
import { useStores } from '../../Stores/useStores';

type LanguagePickerProps = {
};

const LanguagePicker: React.FC<LanguagePickerProps> = (props) => {
    const { localeStore } = useStores();
    const classes = useStyles();

    return (
        <Select
            disableUnderline
            labelId="language"
            inputProps={ { className: classes.select } }
            value={ localeStore.locale }
            onChange={ (e) => {
                changeLocale(e.target.value as string);
            } }
        >
            { Object.entries(languages).map(([key, data]) => (
                <MenuItem key={ key } value={ key }>
                    <>
                        <img src={ data.image } alt={ data.label } className={ classes.itemImage } />
                        <Typography component="span">{ data.label }</Typography>
                    </>
                </MenuItem>
            )) }
        </Select>
    );
};

export default observer(LanguagePicker);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    select: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: theme.spacing(4),
        },
    },
    itemImage: {
        width: 22,
        height: 15,
        objectFit: 'cover',
        marginRight: theme.spacing(2),
        border: '1px solid lightgray',
    },
}));
