import { IconButton, Fab } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';

type BackButtonProps = {
    fab?: boolean;
    className?: string;
    edge?: 'start' | 'end' | false;
    onClick: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({
    fab, className, onClick, edge,
}) => (fab
    ? (
        <Fab size="medium" color="primary" aria-label="navigate back" onClick={ onClick }>
            <ArrowBackIcon />
        </Fab>
    )
    : (
        <IconButton
            className={ className }
            color="inherit"
            aria-label="navigate back"
            edge={ edge ?? 'start' }
            onClick={ onClick }
        >
            <ArrowBackIcon />
        </IconButton>
    ));

export default BackButton;
