import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import UserButton from '../../../domain/login/components/UserButton';
import { useStores } from '../../../Stores/useStores';
import { clsx } from '../../../utils/WebHelpers';
import EmptyButton from '../../Common/EmptyButton';
import MainSearchButton from '../../Common/MainSearchButton';
import MenuButton from '../../Common/MenuButton';
import ResponsiveToolbar from '../../Layout/ResponsiveToolbar';
import { Logo } from '../../Logo';

const MainNavigation: React.FC = () => {
    const { layoutStore, appStore } = useStores();
    const { isMobile } = layoutStore;

    const classes = useStyles({ isMobile });
    return (
        <div className={ classes.root }>
            <ResponsiveToolbar>
                <div className={ classes.toolbarButtonContainer }>
                    { isMobile && (
                        <MenuButton edge="start" className={
							(!appStore.isApp ?
								clsx(classes.toolbarButton, classes.menuButton)
							:
								clsx(classes.toolbarButton, classes.menuButton, classes.hamburgerApp)
					 		) } />
                    ) }
                </div>
                <div className={ classes.toolbarContent }>
                    <div className={ classes.searchContent }>
                        { !isMobile ? (
                            <div className={ classes.mainButtonContainer }>
                                <MainSearchButton />
                            </div>
                        ) : (
                            <div className={ clsx((!appStore.isApp ? classes.mobileLogo : classes.mobileLogoApp), classes.fillContentSection) }>
                                <Logo height={ (!appStore.isApp ? 32 : 40) } />
                            </div>
                        ) }
                    </div>
                </div>
                <div className={ classes.toolbarButtonContainer }>
                    { false && isMobile && (
                        <div className={ classes.userButton }>
                            <UserButton />
                        </div>
                    ) }
                </div>
            </ResponsiveToolbar>
        </div>
    );
};

export default observer(MainNavigation);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        flexGrow: 1,
        backgroundColor: params.isActive ? theme.palette.primary.main : theme.palette.common.white,
        color: params.isActive ? theme.palette.getContrastText(theme.palette.primary.main) : 'inherit',
        padding: 0,
    }),
    toolbarContent: (params: any) => ({
        flex: 1,
        padding: params.isActive
            ? theme.spacing(1, 0)
            : 0,
    }),
    searchContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    mobileLogo: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: theme.spacing(3),
        // margin: theme.spacing(.1),
    },
	mobileLogoApp: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: theme.spacing(5),
		marginTop: theme.spacing(0),
		position: 'relative',
		top: '-5px',
    },
    fillContentSection: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(0.5),
    },
    toolbarButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        minHeight: 56,
    },
    toolbarButton: () => ({
        margin: theme.spacing(0, 0.15),
    }),
    menuButton: {
        color: `${theme.palette.primary.main} !important`,
    },
	hamburgerApp: {
		position: 'relative',
		top: '4px',
	},
    mainButtonContainer: {
        paddingBottom: theme.spacing(1),
    },
    userButton: {
        // marginRight: theme.spacing(-1),
    },
}));
