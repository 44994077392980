import {
    Button, Grid, TextField
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { userInputValidationMessages } from '../../../../messages/userInputValidationMessages';
import { useValidationMessages } from '../../../../messages/useValidationMessages';

import EmailInput from './EmailInput';
import { signInMessages } from '../../messages/signInMessages';

export type EmailSignInFormOptions = {
    email: string,
    password: string
}

type EmailSignInFormProps = {
    submitButtonTitle: string;
    presetEmail?: string;
    disableAutocomplete?: boolean;
    hideSubmitButton?: boolean;
    onSubmit: (options: EmailSignInFormOptions | any) => void;
    children?: (form: UseFormMethods<any>, classes: any) => React.ReactNode;
};

const EmailSingInForm: React.FC<EmailSignInFormProps> = ({
    hideSubmitButton,
    disableAutocomplete,
    presetEmail,
    children,
    submitButtonTitle,
    onSubmit,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const form = useForm({
        mode: 'onBlur',
    });
    const {
        register, handleSubmit,
    } = form;
    const { getTextFieldErrorProps } = useValidationMessages(userInputValidationMessages, form);

    const handleSubmitEvent = async (data: any) => {
        onSubmit(data);
    };

    return (
        <form onSubmit={ handleSubmit(handleSubmitEvent) } noValidate>
            <Grid container className={ classes.root }>
                <Grid item xs={ 12 }>
                    <EmailInput
                        form={ form }
                        disableAutocomplete={ disableAutocomplete }
                        initialValue={ presetEmail }
                    />
                </Grid>
                <Grid item xs={ 12 } className={ classes.field }>
                    <TextField
                        required
                        autoComplete={ disableAutocomplete ? 'disabled' : undefined }
                        className={ classes.input }
                        name="password"
                        label={ intl.formatMessage(signInMessages.inputPasswordLabel) }
                        defaultValue=""
                        type="password"
                        { ...getTextFieldErrorProps('password') }
                        inputRef={ register({ required: true }) }
                    />
                </Grid>
                { children && children(form, classes) }
                { !hideSubmitButton && (
                    <Grid item xs={ 12 } className={ classes.submitButton }>
                        <Button variant="contained" color="secondary" fullWidth type="submit">{ submitButtonTitle }</Button>
                    </Grid>
                ) }
            </Grid>
        </form>
    );
};

export default EmailSingInForm;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    input: {
        display: 'flex',
    },
    field: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(4),
    },
}));
