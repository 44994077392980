import { injectable } from 'inversify';

import IEnvironment, { IEnvironment_TYPE } from '../IEnvironment';
import IocContainer from '../IocContainer';
import ClusterSearchResult from '../Models/ClusterSearchResult';
import Configuration from '../Models/Configuration';
import Feedback from '../Models/Feedback';
import { MunicipalityInfo } from '../Models/MunicipalityInfo';
import { MunicipalitySearchResult } from '../Models/MunicipalitySearchResult';
import { NearbyPlacesSearchResult } from '../Models/NearbyPlacesSearchResult';
import PlaceFeedback from '../Models/PlaceFeedback';
import { PlaceInfo } from '../Models/PlaceInfo';
import { PlaceSearchResult } from '../Models/PlaceSearchResult';
import {
    SearchMapNearbyCriteria, SearchMapCriteria, SearchMapPlacesCriteria, SearchMapRegionsCriteria, SearchMapCitiesCriteria
} from '../Models/SearchMapCriteria';
import SearchMunicipalityCriteria from '../Models/SearchMunicipalityCriteria';
import SearchPlacesCriteria from '../Models/SearchPlacesCriteria';
import User, { UserDetails } from '../Models/User';
import SearchHelper from '../Utils/searchHelper';

import HttpService from './HttpService';
import IPerformanceLogger, { IPerformanceLogger_TYPE } from './IPerformanceLogger';

@injectable()
export default class ApiService {
  private performanceLogger = IocContainer.get<IPerformanceLogger>(IPerformanceLogger_TYPE);

  private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

  private httpService = IocContainer.get(HttpService);

  public async searchMapNearby(criteria: SearchMapNearbyCriteria): Promise<NearbyPlacesSearchResult> {
      console.debug('[ApiService]::searchMapNearby', criteria);
      const endpoint = `${this.environment.api.host}/api/list/points`;
      // construct query parameters from criteria
      const params = SearchHelper.convertCriteriaToQueryParams(criteria as SearchMapCriteria);
      // call the API endpoint with query parameters
      // return (await HttpService.get<PlaceSearchResult[]>(endpoint, { params })).slice(0, 20); // TODO temporary;
      return this.performanceLogger.trace('[ApiService]::searchMapNearby', () => this.httpService.get<NearbyPlacesSearchResult>(endpoint, { params }));
  }

  public searchMapPlaces(criteria: SearchMapPlacesCriteria): Promise<PlaceSearchResult[]> {
      console.debug('[ApiService]::searchMapPlaces', criteria);
      const endpoint = `${this.environment.api.host}/api/map/points`;
      // construct query parameters from criteria
      const params = SearchHelper.convertCriteriaToQueryParams(criteria as SearchMapCriteria);
      // call the API endpoint with query parameters
      return this.performanceLogger.trace('[ApiService]::searchMapPlaces', () => this.httpService.get<PlaceSearchResult[]>(endpoint, { params }));
  }

  public searchMapRegions(criteria: SearchMapRegionsCriteria): Promise<ClusterSearchResult[]> {
      console.debug('[ApiService]::searchMapRegions', criteria);
      const endpoint = `${this.environment.api.host}/api/map/regions`;
      // construct query parameters from criteria
      const params = SearchHelper.convertCriteriaToQueryParams(criteria as SearchMapCriteria);
      // call the API endpoint with query parameters
      return this.performanceLogger.trace('[ApiService]::searchMapRegions', () => this.httpService.get<ClusterSearchResult[]>(endpoint, { params }));
  }

  public searchMapCities(criteria: SearchMapCitiesCriteria): Promise<ClusterSearchResult[]> {
      console.debug('[ApiService]::searchMapCities', criteria);
      const endpoint = `${this.environment.api.host}/api/map/cities`;
      // construct query parameters from criteria
      const params = SearchHelper.convertCriteriaToQueryParams(criteria as SearchMapCriteria);
      // call the API endpoint with query parameters
      return this.performanceLogger.trace('[ApiService]::searchMapCities', () => this.httpService.get<ClusterSearchResult[]>(endpoint, { params }));
  }

  public searchPlaces(criteria: SearchPlacesCriteria): Promise<PlaceSearchResult[]> {
      console.debug('[ApiService]::searchPlaces', criteria);
      const endpoint = `${this.environment.api.host}/api/search/${criteria.searchTerm}`;
      // construct query parameters from criteria
      const params = new URLSearchParams();
      if (criteria.location) {
          params.append('lng', criteria.location.lng.toString());
          params.append('lat', criteria.location.lat.toString());
      }
      // call the API endpoint
      return this.performanceLogger.trace('[ApiService]::searchPlaces', () => this.httpService.get<PlaceSearchResult[]>(endpoint, { params }));
  }

  public searchMunicipality(criteria: SearchMunicipalityCriteria): Promise<{ data: MunicipalitySearchResult[] }> {
      console.debug('[ApiService]::searchMunicipality', criteria);
      const endpoint = `${this.environment.api.host}/api/obec/list/search`;
      // construct query parameters from criteria
      const params = new URLSearchParams();
      params.append('query', criteria.searchTerm);
      if (criteria.location) {
          params.append('lng', criteria.location.lng.toString());
          params.append('lat', criteria.location.lat.toString());
      }
      // call the API endpoint
      return this.performanceLogger.trace('[ApiService]::searchMunicipality', () => this.httpService.get<{ data: MunicipalitySearchResult[] }>(endpoint, { params }));
  }

  public getPlace(placeId: string): Promise<PlaceInfo> {
      console.debug('[ApiService]::getPlace');
      const endpoint = `${this.environment.api.host}/api/point/${placeId}`;
      return this.performanceLogger.trace('[ApiService]::getPlace', () => this.httpService.get<PlaceInfo>(endpoint));
  }

  public getMunicipality(municipalityId: string): Promise<MunicipalityInfo> {
      console.debug('[ApiService]::getMunicipality');
      const endpoint = `${this.environment.api.host}/api/obec/${municipalityId}`;
      return this.performanceLogger.trace('[ApiService]::getMunicipality', () => this.httpService.get<MunicipalityInfo>(endpoint));
  }

  public getConfiguration(): Promise<Configuration> {
      console.debug('[ApiService]::getConfiguration');
      const endpoint = `${this.environment.api.host}/api/configure`;
      return this.performanceLogger.trace('[ApiService]::getConfiguration', () => this.httpService.get<Configuration>(endpoint));
  }

  public postFeedback(feedback: Feedback) {
      const endpoint = `${this.environment.api.host}/api/feedback`;
      return this.performanceLogger.trace('[ApiService]::postFeedback', () => this.httpService.post(endpoint, feedback));
  }

  public postPlaceFeedback(feedback: PlaceFeedback) {
      const endpoint = `${this.environment.api.host}/api/feedback-place`;
      return this.performanceLogger.trace('[ApiService]::postPlaceFeedback', () => this.httpService.post(endpoint, feedback));
  }

  public getUserDetails(): Promise<User> {
      const endpoint = `${this.environment.api.host}/api/user`;
      return this.performanceLogger.trace('[ApiService]::getUserDetails', () => this.httpService.get<User>(endpoint));
  }

  public updateUserDetails(details: UserDetails): Promise<User> {
      const endpoint = `${this.environment.api.host}/api/user`;
      return this.performanceLogger.trace('[ApiService]::updateUserDetails', () => this.httpService.put(endpoint, details));
  }
}
