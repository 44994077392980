import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useStores } from '../../../../Stores/useStores';

type SignInContentProps = {
};

const SignInContent: React.FC<SignInContentProps> = ({ children }) => {
    const { layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    return (
        <div className={ classes.root }>
            { children }
        </div>
    );
};

export default SignInContent;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: ({ isMobile }: any) => ({
        padding: isMobile
            ? theme.spacing(5, 4)
            : theme.spacing(5, 8),
    }),
}));
