import {
    Button,
    Checkbox,
    createStyles, FormControl, FormControlLabel, FormHelperText, Grid, makeStyles, TextField, Theme, Typography
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import MarkDown from '../../../../Components/Common/MarkDown';
import { useValidationMessages } from '../../../../messages/useValidationMessages';

import EmailSingInForm, { EmailSignInFormOptions } from './EmailSignInForm';
import { userInputValidationMessages } from '../../../../messages/userInputValidationMessages';
import { userInputMessages } from '../../../../messages/userInputMessages';
import { signInMessages } from '../../messages/signInMessages';

export type EmailSignUpFormOptions = EmailSignInFormOptions & {
    nickname?: string;
    firstName: string;
    lastName: string;
}

type EmailSignUpProps = {
    onSubmit: (options: EmailSignUpFormOptions) => void;
};

const EmailSingUp: React.FC<EmailSignUpProps> = ({
    onSubmit,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);

    const handleSubmitEvent = async (data: any) => {
        if (!data.consent) { return; }
        onSubmit(data);
    };

    return (
        <EmailSingInForm
            disableAutocomplete
            hideSubmitButton={ !expanded }
            submitButtonTitle={ intl.formatMessage(signInMessages.emailSignUpButton) }
            onSubmit={ handleSubmitEvent }
        >
            { (form, formClasses) => (
                <>
                    { expanded
                        ? (
                            <EmailSingUpForm form={ form } formClasses={ formClasses } />
                        ) : (
                            <Button
                                startIcon={ <EmailIcon /> }
                                variant="contained"
                                color="secondary"
                                onClick={ () => setExpanded(true) }
                                fullWidth
                                className={ classes.registerButton }
                            >
                                <FormattedMessage
                                    id="SIGNUP_EMAIL_SIGNUP"
                                    defaultMessage="Registrovat pomocí emailu"
                                />
                            </Button>
                        ) }

                </>
            ) }

        </EmailSingInForm>
    );
};

export default EmailSingUp;

type EmailSingUpFormProps = {
    form: UseFormMethods<Record<string, any>>,
    formClasses: any,
};

const EmailSingUpForm: React.FC<EmailSingUpFormProps> = ({
    form,
    formClasses,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const { register, getValues, errors } = form;
    const { getTextFieldErrorProps, getErrorMessage } = useValidationMessages(userInputValidationMessages, form);
    const [consent, setConsent] = useState(false);

    return (
        <>
            <Grid item xs={ 12 } className={ formClasses.field }>
                <TextField
                    required
                    className={ formClasses.input }
                    name="passwordRetype"
                    label={ intl.formatMessage(signInMessages.inputPasswordCheckLabel) }
                    defaultValue=""
                    type="password"
                    { ...getTextFieldErrorProps('passwordRetype') }
                    inputRef={ register({
                        validate: {
                            passwordNotMatch: (value: string) => {
                                const password = getValues('password');
                                return password === value;
                            },
                        },
                    }) }
                />
            </Grid>
            <Grid item xs={ 12 } className={ classes.section }>
                <Typography variant="body1">
                    <FormattedMessage
                        id="SIGNUP_PROFILE_SECTION_TITLE"
                        defaultMessage="Profil"
                    />
                </Typography>
            </Grid>
            <Grid item xs={ 12 } className={ formClasses.field }>
                <TextField
                    className={ formClasses.input }
                    name="nickname"
                    label={ intl.formatMessage(userInputMessages.inputNicknameLabel) }
                    defaultValue=""
                    { ...getTextFieldErrorProps('nickname') }
                    inputRef={ register() }
                />
            </Grid>
            <Grid item xs={ 12 } className={ formClasses.field }>
                <TextField
                    required
                    className={ formClasses.input }
                    name="firstName"
                    label={ intl.formatMessage(userInputMessages.inputFirstNameLabel) }
                    defaultValue=""
                    { ...getTextFieldErrorProps('firstName') }
                    inputRef={ register({ required: true }) }
                />
            </Grid>
            <Grid item xs={ 12 } className={ formClasses.field }>
                <TextField
                    required
                    className={ formClasses.input }
                    name="lastName"
                    label={ intl.formatMessage(userInputMessages.inputLastNameLabel) }
                    defaultValue=""
                    { ...getTextFieldErrorProps('lastName') }
                    inputRef={ register({ required: true }) }
                />
            </Grid>
            <Grid item xs={ 12 } className={ formClasses.field }>
                <FormControl error={ errors.consent } className={ classes.consent }>
                    <FormControlLabel
                        control={ (
                            <Checkbox
                                checked={ consent }
                                name="consent"
                                onChange={ () => setConsent(!consent) }
                                inputRef={ register({ required: true }) }
                                { ...getTextFieldErrorProps('consent') }
                            />
                        ) }
                        label={ <MarkDown source={ intl.formatMessage(signInMessages.privacyConsent) } /> }
                    />
                    { errors.consent && (
                        <FormHelperText className={ classes.helperText }>{ getErrorMessage('consent') }</FormHelperText>
                    ) }
                </FormControl>
            </Grid>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    section: {
        marginTop: theme.spacing(5),
    },
    registerButton: {
        marginTop: theme.spacing(4),
    },
    helperText: {
        marginTop: theme.spacing(1),
    },
    consent: {
        marginTop: theme.spacing(2),
    },
}));
