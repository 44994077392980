import { useHistory, useRouteMatch, useLocation } from 'react-router';

import { useStores } from '../Stores/useStores';
import { concatPath } from '../utils/concatPath';

import { useNavigation } from './useNavigation';

export function useHomeNavigation() {
    const match = useRouteMatch<any>();
    const location = useLocation();
    const history = useHistory();
    const { homeStore, appStore } = useStores();
    const { constructPath } = useNavigation();

    const getHomePath = () => appStore.rootPath;

    return {
        getHomePath,
        concatPath,
        navigateToDefault,
        navigateToMunicipality,
        navigateBack,
        navigateToCategorySearch,
        navigateToCategoryBrowse,
        navigateToPlace,
        navigateHome,
        navigateToListing,
        navigateToCategoryResults,
        navigateToCategoryProductResults,
        navigateTo,
        getLinkTo,
    };

    function navigateToMunicipality(id?: string) {
        if (id) {
            homeStore.setMunicipality(id);
        } else {
            homeStore.clearMunicipality();
        }
    }

    function navigateToDefault() {
        homeStore.flyToDefaultPosition();
        navigateHome();
    }

    function navigateBack() {
        const isCategoryRoute = location.pathname.includes('/categories');
        const isCategoryBrowseAllRoute = location.pathname.includes('/browse/all');
        const isProductRoute = location.pathname.includes('/products');
        const isPlacesRoute = location.pathname.includes('/places');

        // Note: the prevUrl on the state is injected in the history override
        const canNavigateBackWithinTheApp = !!(location.state as {prevUrl: string } | undefined)?.prevUrl;

        switch (true) {
        case isCategoryBrowseAllRoute:
            navigateHome();
            break;
        case isPlacesRoute && isCategoryRoute:
        {
            let path = concatPath(getHomePath(), `/categories/${match.params.categoryId}`);
            if (isProductRoute) {
                path += `/products/${match.params.productId}`;
            }
            history.replace(constructPath(path));
            break;
        }
        // case isCategoryRoute: {
        //     navigateTo('/browse/all');
        //     break;
        // }

        case canNavigateBackWithinTheApp:
            history.goBack();
            break;
        default:
            navigateHome();
            break;
        }
    }

    function navigateToListing() {
        const parts = location.pathname.split('/');

        const categoryIndex = parts.indexOf('categories');
        const productIndex = parts.indexOf('products');

        const isCategoryRoute = categoryIndex !== -1;
        const isProductRoute = productIndex !== -1;

        let path = '';
        if (isCategoryRoute) {
            path = `/categories/${parts[categoryIndex + 1]}`;
            if (isProductRoute) {
                path += `/products/${parts[productIndex + 1]}`;
            }
        } else {
            // main listing
            path = '/';
        }

        navigateTo(path);
    }

    function navigateToCategorySearch() {
        navigateTo(`/categories/search`);
    }

    function navigateToCategoryBrowse(categoryId: string | 'all') {
        navigateTo(`/browse/${categoryId}`);
    }

    function navigateToCategoryResults(categoryId: string) {
        navigateTo(`/categories/${categoryId}`);
    }

    function navigateToCategoryProductResults(categoryId: string, productId: string) {
        navigateTo(`/categories/${categoryId}/products/${productId}`);
    }

    function navigateToPlace(placeId: string) {
        const { pathname } = window.location;
        // TODO consider using constants for routes and comparing the pathname with the constants
        const isCategoriesRoute = pathname.includes('/categories');
        const isPlacesRoute = pathname.includes('/places');

        let root = getHomePath();
        if (isCategoriesRoute) { root = pathname; }

        // remove places route if present
        if (isPlacesRoute) {
            const i = pathname.indexOf('/places');
            root = pathname.substring(0, i);
        }

        const target = constructPath(concatPath(root, `/places/${placeId}`));

        if (isPlacesRoute) {
            history.replace(target);
        } else {
            history.push(target);
        }
    }

    function navigateHome() {
        navigateTo('/');
    }

    function navigateTo(path: string) {
        const target = getLinkTo(path);
        history.push(target);
    }

    function getLinkTo(path: string) {
        return constructPath(concatPath(getHomePath(), path));
    }
}
