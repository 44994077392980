import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

type DrawerHandleProps = {
};

const DrawerHandle: React.FC<DrawerHandleProps> = (props) => {
    const classes = useStyles();

    return (
        <div className={ classes.handle }>
            <img className={ classes.arrow } alt="up" src="/assets/chevron-up.svg" width="32px" height="8px" />
        </div>
    );
};

export default DrawerHandle;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    handle: {
        position: 'absolute',
        zIndex: 200,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 0, 1.5, 0),
        top: -18,
    },
    arrow: {
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        borderTopLeftRadius: '50px',
        borderTopRightRadius: '50px',
        backgroundColor: 'white', // theme.palette.secondary.main,
    },
}));
