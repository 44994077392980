import { useEffect } from 'react';

import { UserService } from '@shared/Core/Services/UserService';

import { useAuthService } from './useAuthService';
import { useStores } from '../../../Stores/useStores';
import { useService } from '../../../Hooks/useService';

export function useAuthentication(locale: string) {
    const { authStore } = useStores();
    const firebaseAuthSvc = useAuthService();
    const userSvc = useService(UserService);

    if (!firebaseAuthSvc.firebase) {
        firebaseAuthSvc.initialize(locale);
    }

    useEffect(() => {
        firebaseAuthSvc.firebase.auth().onAuthStateChanged(async (fuser) => {
            if (fuser) {
                const user = await userSvc.getUserDetails();
                authStore.logIn(user);
            } else {
                authStore.logOut();
                authStore.setState('anonymous');
            }
        });
    }, []);
}
