import {
    Box,
    Button, Tooltip, Typography, Zoom
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import TruncateMarkup from 'react-truncate-markup';

import CategoryMenuItem from '../../../../shared/src/Core/Models/CategoryMenuItem';
import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useLocationCategoryId } from '../../Hooks/useLocationCategoryId';
import { useStores } from '../../Stores/useStores';
import ImageIcon from '../Common/ImageIcon';

const ICON_SIZE = 36;

type PlaceCategoryProps = {
    item: CategoryMenuItem;
};

const CategoryMenuItemMobilComponent: React.FC<PlaceCategoryProps> = ({ item }) => {
    const { layoutStore } = useStores();
    const { isMobile } = layoutStore;
    const categoryId = useLocationCategoryId();

    const isSelected = categoryId === item.key;
    const classes = useStyles({ isSelected, isMobile });
    const { navigateToCategoryResults } = useHomeNavigation();

    const handleClick = () => {
        navigateToCategoryResults(item.key);
    };

    return (
        <>
            <Tooltip
                className={classes.tooltip}
                title={ item.label }
                TransitionComponent={ Zoom }
            >
                { layoutStore.isMobile ? (
                    <Box className={ classes.root }>
                        { renderButton() }
                    </Box>
                ) : renderButton() }
            </Tooltip>
        </>
    );

    function renderButton() {
        return (
            <Button
                onClick={ handleClick }
                className={ classes.button }
                classes={ {
                    label: classes.buttonLabel,
                } }
                variant="contained"
                disableElevation
            >
                <ImageIcon src={ item.icon } height={ ICON_SIZE } alt={ item.label } className={ classes.icon } />
                <div className={ classes.buttonContent }>
                    { /* <TruncateMarkup lines={ 2 }> */ }

                        { /* <span>hello there heelo world aksjdfaksd asdfkj asdk</span> */ }
                        <span className={ classes.label }>{ item.label }</span>

                    { /* </TruncateMarkup> */ }
                </div>

            </Button>
        );
    }
};

export default observer(CategoryMenuItemMobilComponent);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        // display: 'flex',
        alignItems: 'center',
        // height: ICON_SIZE + theme.spacing(1),
        minWidth: ICON_SIZE,
        // margin: theme.spacing(1, 0),
        // borderWidth: 3,
        borderRadius: theme.spacing(1),
        overflow: 'hidden',

        border: '1px solid gray',
        borderColor: 'whitesmoke',
        // backgroundColor: 'whitesmoke',
        margin: theme.spacing(0.14),
        // shadow: theme.shadows[1],
        boxShadow: '0px 3px 15px -6px rgba(107,107,107,1)',
        textAlign: 'center',
    }),
    button: (params: any) => ({
        padding: theme.spacing(0.3),
        backgroundColor: params.isSelected
            ? theme.palette.primary.light
            : 'transparent',
    }),
    tooltip: {
        boxSizing: 'border-box',
        flex: '1 0 18%',
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 0),
        // height: ICON_SIZE,
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
    },
    buttonContentHolder: {
    },
    buttonContent: (params: any) => ({
        marginLeft: theme.spacing(0),
        paddingTop: theme.spacing(0.5),
        textAlign: 'center',
        display: 'flex',
        color: 'black',
        textTransform: 'none',
        boxSizing: 'border-box',
        // width: params.isMobile ? theme.spacing(5.8) : theme.spacing(10),
        // height: ICON_SIZE,
        // backgroundColor: 'red',
        lineHeight: '0rem',
    }),
    label: {
        fontSize: 12,
        lineHeight: '0.8rem',
        fontWeight: 'lighter',
    },
    icon: {
        borderRadius: theme.spacing(1),
    },
}));
