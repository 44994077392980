import {
    createStyles, Theme, makeStyles, fade
} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { useStores } from '../../Stores/useStores';

import DetailMarkdownInstructions from './DetailMarkdownInstructions';

const ICONS: Record<string, string> = {
    '[?]:': '/assets/questionmark.png',
    '[i]:': '/assets/information.png',
    '[!]:': '/assets/information.png',
    '[idea]:': '/assets/idea.png',
};

type InstructionsHeaderProps = {
    instructionsData: string;
    fill: boolean;
};

const InstructionsHeader: React.FC<InstructionsHeaderProps> = ({ instructionsData, fill }) => {
    const { layoutStore } = useStores();
    const classes = useStyles({
        isMobile: layoutStore.isMobile,
        fillInstructions: fill,
    });

    const { instructions, iconSrc } = useMemo(() => parseInstructionData(instructionsData), [instructionsData]);

    return instructionsData.startsWith('[<img src=')
        ? (
            <div className={ classes.instructionsContainerBanner }>
                <DetailMarkdownInstructions markdown={ instructions } />
            </div>
        ) : renderWithIcon();

    function renderWithIcon() {
        return (
            <div className={ classes.instructionsContainer }>
                <DetailMarkdownInstructions markdown={ instructions } />
                { iconSrc && (
                    <img className={ classes.instructionsIcon } src={ iconSrc } />
                ) }
            </div>
        );
    }
};

function parseInstructionData(instructionsData: string) {
    const iconSrcEntry = Object.entries(ICONS)
        .find(([iconPrefix]) => instructionsData.startsWith(iconPrefix));

    if (!iconSrcEntry) {
        // instructions don't have any icon prefix, use default one
        return {
            iconSrc: null, // default
            instructions: instructionsData,
        };
    }

    const [prefix, iconSrc] = iconSrcEntry;
    // remove prefix from the instructions
    const instructions = instructionsData.substring(prefix.length, instructionsData.length);
    return {
        iconSrc,
        instructions,
    };
}

export default observer(InstructionsHeader);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    instructionsContainer: (params: any) => ({
        display: 'flex',
        backgroundColor: fade(theme.palette.secondary.main, 1),
        color: theme.palette.common.white,
        alignItems: 'flex-start',
        padding: theme.spacing(params.isMobile ? 5 : 2.5, 2.5, 2.5, 2.5),
        height: params.fillInstructions ? '100%' : undefined,
    }),
    instructionsContainerBanner: (params: any) => ({
        display: 'flex',
        alignItems: 'flex-start',
        padding: theme.spacing(params.isMobile ? 5 : 3, 5, 0, 5),
        height: params.fillInstructions ? '100%' : undefined,
    }),
    instructionsIcon: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(0),
        minWidth: '24px',
        height: '24px',
        borderRadius: 100,
    },
}));
