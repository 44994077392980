export type AppHosts = 'browser' | 'ios' | 'android';

export type RegionType = 'country' | 'kraj' | 'orp' | 'okres' | 'obec';

export type MapSearchLevel = 'places' | 'cities' | RegionType;

export type LatLng = {
    lat: number;
    lng: number;
}

export class Bounds {
    constructor(
        public north: number = 0,
        public south: number = 0,
        public west: number = 0,
        public east: number = 0
    ) { }

    public getNorthEast(): LatLng {
        return { lat: this.north, lng: this.east };
    }

    public getSouthWest(): LatLng {
        return { lat: this.south, lng: this.west };
    }
}

export type ActionStatus = 'initial' | 'inProgress' | 'done' | 'error';

export type FeedbackType = 'bug' | 'improvement' | 'other';

export type MapType = 'streets' | 'satellite';
