import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { IMapboxService_TYPE } from '../../../shared/src/Core/Services/IMapboxService';
import { LatLng } from '../../../shared/src/Core/Types';
import MapboxService from '../Services/MapboxService';
import { useStores } from '../Stores/useStores';
import { insertRecentItem } from '../utils/recentLocationsStorage';

import { useService } from './useService';

export function useMapLocationQueryParams() {
    const history = useHistory();
    const location = useLocation();
    const { homeStore } = useStores();
    const mapboxService = useService<MapboxService>(IMapboxService_TYPE);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (!homeStore.ready) { return; }

        updateQueryParameters();
    }, [
        homeStore.ready,
        homeStore.activeMapLocation,
        homeStore.municipality?.id,
    ]);

    useEffect(() => {
        applyQueryParameters();
    }, [location.search]);

    async function init() {
        await applyQueryParameters();
        await setDefaultQueryParameters();
    }

    async function applyQueryParameters() {
        try {
            const params = new URLSearchParams(location.search);
            if (params.has('municipality')) {
                await homeStore.setMunicipality(params.get('municipality'));
            } else if (!homeStore.followUserPosition && params.has('lat') && params.has('lng')) {
                const center: LatLng = {
                    lat: Number(params.get('lat')),
                    lng: Number(params.get('lng')),
                };

                homeStore.setSelectedMapLocation(center);
            }
        } catch (err) {
            console.error('Invalid query parameters.');
        }
    }

    async function setDefaultQueryParameters() {
        if (homeStore.mapLocationType !== 'selected' || !homeStore.selectedMapLocation) {
            return;
        }
        // set default query parameters
        await mapboxService.ready();
        updateQueryParameters();
    }

    function updateQueryParameters() {
        const { activeMapLocation, municipality } = homeStore;

        // if there is focused map location skip query parameters update until it has been reached
        const params = new URLSearchParams(location.search);
        params.delete('lat');
        params.delete('lng');
        params.delete('municipality');

        if (municipality) {
            params.set('municipality', municipality.id);

            insertRecentItem({
                type: 'municipality',
                id: municipality.id,
                name: municipality.name,
            });
        } else if (activeMapLocation) {
            params.set('lat', activeMapLocation.lat.toString());
            params.set('lng', activeMapLocation.lng.toString());
        }

        history.replace(`${location.pathname}?${params.toString()}`);
    }
}
