import { createStyles, makeStyles } from '@material-ui/core/styles';
import ClosedIcon from '@material-ui/icons/AlarmOff';
import OpenIcon from '@material-ui/icons/AlarmOn';
import { OpenType } from '@shared/Core/Models/OpenInfo';
import React from 'react';

import Theme from '../../Theme';
import { clsx } from '../../utils/WebHelpers';

type OpenIndicatorProps = {
    type: OpenType,
    mode: 'normal' | 'small'
};

const OpenIndicator: React.FC<OpenIndicatorProps> = ({ type, mode }) => {
    const classes = useStyles({ mode });

    return (
        <div className={ clsx(classes.root, classes[type]) }>
            { mode === 'normal' && renderInner() }
        </div>
    );

    function renderInner() {
        switch (type) {
        case 'open':
        case 'nonstop':
            return <OpenIcon className={ classes.icon } />;
        case 'closed':
            return <ClosedIcon className={ classes.icon } />;
        // case 'nonstop':
        //     return '24/7';
        case 'unknown':
            return <img src="/assets/openning-unknown.svg" className={ classes.icon } />;
        default: return null;
        }
    }
};

export default OpenIndicator;


const useStyles = makeStyles(() => createStyles({
    root: (mode) => ({
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        // width: 32,
        width: mode === 'small' ? 10 : 20,
        height: mode === 'small' ? 10 : 20,
        // borderRadius: 16,
        fontSize: 12,
        overflow: 'hidden',
        textAlign: 'center',
        // color: 'white',
    }),
    icon: {
        width: 20,
        height: 20,
    },
    open: {
        color: Theme.theme_secondaryColor_hex,
    },
    closed: {
        color: '#e64c4c',
    },
    unknown: {
        color: 'orange',
    },
    nonstop: {
        color: Theme.theme_secondaryColor_hex,
    },
}));
