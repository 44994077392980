import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useStores } from '../../Stores/useStores';
import InstructionsHeader from '../Common/InstructionsHeader';
import Panel from '../Common/Panel';
import NearbyPlacesList from '../Place/NearbyPlacesList';

const CategoryNearbyResultsPanel: React.FC = () => {
    const { params: { categoryId, productId } } = useRouteMatch<{ categoryId: string, productId: string }>();
    const { navigateToDefault } = useHomeNavigation();
    const { searchStore, categoryStore, layoutStore } = useStores();

    const hasInstructionsOnly = !!searchStore.resultsInstructions && !searchStore.showResultsList;
    const showPlacesList = categoryStore.selectedCategory !== null && searchStore.showResultsList;

    // run on every route change
    useEffect(() => {
        init();
    }, [categoryId, productId]);

    // on component mount
    React.useEffect(() => {
        layoutStore.resetToDefaults();
    }, []);

    const onLoadMore = () => searchStore.loadMoreNearbyResults();

    return (
        <Panel
            infiniteScroll
            onLoadMore={ onLoadMore }
            loadingMore={ searchStore.moreResultsNearbyLoading }
            loading={ searchStore.resultsNearbyLoading }
            hasMoreToLoad={ searchStore.resultsNearbyHasMore && searchStore.resultsNearby.length > 0 }
        >
            <>
                { searchStore.resultsInstructions
                    && (
                        <InstructionsHeader
                            fill={ hasInstructionsOnly }
                            instructionsData={ searchStore.resultsInstructions }
                        />
                    ) }
                { showPlacesList && (
                    <>
                        <NearbyPlacesList />
                    </>
                ) }
            </>
        </Panel>
    );

    async function init() {
        try {
            await categoryStore.setSelectedCategory(categoryId, productId);
        } catch(e) {
            console.log(e)
            navigateToDefault();
        }
    }
};

export default observer(CategoryNearbyResultsPanel);
