import {
    Typography, Grid, Paper, CardMedia, IconButton
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Page from '../Components/Layout/Page';
// import LayoutStore from '../Stores/LayoutStore';
// import { useStores } from '../Stores/useStores';

const ContactPageMessages = defineMessages({
    pagesContactOurTeamTitle: {
        id: 'PAGES_CONTACT_OUR_TEAM',
        defaultMessage: 'Náš tým',
    },
    pagesContactContactTitle: {
        id: 'PAGES_CONTACT_CONTACT',
        defaultMessage: 'Kontakt',
    },
    pagesContactRoleMirek: {
        id: 'PAGES_CONTACT_ROLE_MIREK',
        defaultMessage: 'návrh systému, technická realizace, správa dat',
    },
    pagesContactRoleRadek: {
        id: 'PAGES_CONTACT_ROLE_RADEK',
        defaultMessage: 'propagace, reklama',
    },
    pagesContactRolePetr: {
        id: 'PAGES_CONTACT_ROLE_PETR',
        defaultMessage: 'realizace webové aplikace, UI design',
    },
    pagesContactRoleKristyna: {
        id: 'PAGES_CONTACT_ROLE_KRISTYNA',
        defaultMessage: 'analýza zdrojů',
    },
});

const ContactPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles({});

    return (
        <Page currentPage="contact">
            <Typography gutterBottom component="h1" variant="h4">
                { intl.formatMessage(ContactPageMessages.pagesContactOurTeamTitle) }
            </Typography>
            <Grid container spacing={ 2 } className={ classes.container }>
                <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
                    <Paper>
                        <Grid container wrap="nowrap">
                            <CardMedia
                                className={ classes.xichtik }
                                image="https://cdn.kamsnim.cz/images/mk-foto2.jpg"
                                title="Avatar"
                            />
                            <Grid item>
                                <div className={ classes.details }>
                                    <Typography component="h3" variant="h6">
                                        Miroslav Kubásek
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className={ classes.funkce }>
                                        { intl.formatMessage(ContactPageMessages.pagesContactRoleMirek) }
                                    </Typography>
                                    <Typography variant="body2" className={ classes.kontakty }>
                                        <IconButton
                                            aria-label="phone"
                                            className={ classes.icon }
                                        >
                                            <PhoneIcon fontSize="inherit" />
                                        </IconButton>
                                        +420 777 176 674
                                    </Typography>
                                    <Typography variant="body2" className={ classes.kontakty }>
                                        <IconButton
                                            aria-label="email"
                                            className={ classes.icon }
                                        >
                                            <EmailIcon fontSize="inherit" />
                                        </IconButton>
                                        { ' ' }
                                        mirek@uklidmecesko.cz
                                    </Typography>
                                    <Grid container className={ classes.linksContainer }>
                                        <IconButton
                                            aria-label="facebook"
                                            component="a"
                                            target="_blank"
                                            href="https://www.facebook.com/KubasekMiroslav"
                                            className={ classes.icon }
                                        >
                                            <FacebookIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="linkedin"
                                            component="a"
                                            target="_blank"
                                            href="https://www.linkedin.com/in/kubasek/"
                                            className={ classes.icon }
                                        >
                                            <LinkedInIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                            aria-label="twitter"
                                            component="a"
                                            target="_blank"
                                            href="https://twitter.com/KubasekMiroslav"
                                            className={ classes.icon }
                                        >
                                            <TwitterIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
                    <Paper>
                        <Grid container wrap="nowrap">
                            <CardMedia
                                className={ classes.xichtik }
                                image="https://cdn.kamsnim.cz/images/janousek.jpg"
                                title="Avatar"
                            />
                            <Grid item>
                                <div className={ classes.details }>
                                    <Typography component="h3" variant="h6">
                                        Radek Janoušek
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" className={ classes.funkce }>
                                        { intl.formatMessage(ContactPageMessages.pagesContactRoleRadek) }
                                    </Typography>
                                    <Typography variant="body2" className={ classes.kontakty }>
                                        <IconButton
                                            aria-label="phone"
                                            className={ classes.icon }
                                        >
                                            <PhoneIcon fontSize="inherit" />
                                        </IconButton>
                                        +420 777 176 675
                                    </Typography>
                                    <Typography variant="body2" className={ classes.kontakty }>
                                        <IconButton
                                            aria-label="email"
                                            className={ classes.icon }
                                        >
                                            <EmailIcon fontSize="inherit" />
                                        </IconButton>
                                        { ' ' }
                                        radek@uklidmecesko.cz
                                    </Typography>
                                    <Grid container className={ classes.linksContainer }>
                                        <IconButton
                                            aria-label="facebook"
                                            component="a"
                                            target="_blank"
                                            href="https://www.facebook.com/ekologie.priroda"
                                            className={ classes.icon }
                                        >
                                            <FacebookIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
                    <Paper>
                        <Grid container wrap="nowrap">
                            <CardMedia
                                className={ classes.xichtik }
                                image="https://cdn.kamsnim.cz/images/altman.jpg"
                                title="Avatar"
                            />
                            <Grid item>
                                <div className={ classes.details }>
                                    <Typography component="h3" variant="h6">
                                        Petr Altman
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        { intl.formatMessage(ContactPageMessages.pagesContactRolePetr) }
                                    </Typography>
                                    <Grid container className={ classes.linksContainer }>
                                        <IconButton
                                            aria-label="linkedin"
                                            component="a"
                                            target="_blank"
                                            href="https://www.linkedin.com/in/petr-altman"
                                            className={ classes.icon }
                                        >
                                            <LinkedInIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>

            <Typography gutterBottom component="h1" variant="h4" className={ classes.h1 }>
                { intl.formatMessage(ContactPageMessages.pagesContactContactTitle) }
            </Typography>

            <Paper className={ classes.paper }>
                <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 } sm container>
                        <Grid container wrap="nowrap">
                            <Grid item>
                                <div className={ classes.details }>
                                    <Typography component="h3" variant="h6">
                                        Ukliďme Česko z. s.
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Březová 6
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        637 00, Brno
                                    </Typography>

									<Typography variant="body2" color="textSecondary">
									<strong>IČ: 01715542</strong>
									</Typography>
									<br />
									<a href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=789036&typ=PLATNY" target="_blank" rel="noopener noreferrer">Výpis ze spolkového rejstříku</a>
           							<br /><a href="http://www.uklidmecesko.cz/spolek/" target="_blank" rel="noopener noreferrer">Základní informace o spolku</a>

                                    <Grid container />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Page>
    );
};

export default ContactPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    container: {

    },
    h1: {
        marginTop: theme.spacing(7),
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
        marginTop: theme.spacing(2),
    },
    xichtik: (params: any) => ({
        margin: theme.spacing(1),
        marginBottom: theme.spacing(8),
        borderRadius: '50%',
        flexShrink: 0,
        backgroundColor: theme.palette.background.default,
        ...(params.isMobile ? {
            width: theme.spacing(9),
            height: theme.spacing(9),
        } : {
            width: theme.spacing(13),
            height: theme.spacing(13),
        }),
    }),
    details: {
        margin: theme.spacing(1, 1, 1, 0),
    },
    kontakty: {
        paddingLeft: theme.spacing(0),
        '& > button': {
            marginRight: theme.spacing(1.2),
            padding: theme.spacing(0.3),
        },
    },
    icon: {
        fontSize: 18,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(0.75),
    },
    funkce: {
        marginBottom: theme.spacing(0.6),
    },
    linksContainer: {
        marginLeft: -theme.spacing(0.5),
    },
}));
