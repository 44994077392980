import { useRootNavigation } from '../../../Hooks/useRootNavigation';
import { useStores } from '../../../Stores/useStores';
import { useAuthService } from './useAuthService';

export function useSignOut() {
    const { authStore } = useStores();
    const rootNavigation = useRootNavigation();
    const firebaseAuthSvc = useAuthService();

    return async () => {
        await firebaseAuthSvc.firebase.auth().signOut();
        authStore.logOut();
        window.location.href = rootNavigation.getLinkTo('/');
    };
}
