import { List } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import React from 'react';

import PlaceItem from './PlaceItem';
import PlaceItemSkeleton from './PlaceItemSkeleton';

type PlacesListProps = {
    items: PlaceInfo[];
    loading?: boolean;
    onItemClick: (item: PlaceInfo) => void;
};

const PlacesList: React.FC<PlacesListProps> = ({
    loading, items, onItemClick,
}) => {
    const classes = useStyles();

    const handleItemClick = (item: PlaceInfo) => {
        onItemClick(item);
    };

    return (
        <List className={ classes.root }>
            { loading && renderLoader() }
            { items.map((result) => (
                <PlaceItem key={ result.id } place={ result } onItemClick={ handleItemClick } />
            )) }
        </List>
    );

    function renderLoader() {
        return (
            <>
                { (!items || items.length === 0) && [0, 0, 0, 0, 0, 0].map((_, i) => (
                    <PlaceItemSkeleton key={ i } />
                )) }
            </>
        );
    }
};

export default PlacesList;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: 0,
    },
}));
