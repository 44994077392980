import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import MarkDown from './MarkDown';

type DetailMarkdownProps = {
    markdown: string;
};

const DetailMarkdownInstructions: React.FC<DetailMarkdownProps> = observer(({
    markdown,
}) => {
    const classes = useStyles();

    return (
        <MarkDown
            className={ classes.instructions }
            source={ markdown }
        />
    );
});

export default DetailMarkdownInstructions;

const useStyles = makeStyles((theme: Theme) => createStyles({
    instructions: {
        padding: theme.spacing(0),
        // marginLeft: theme.spacing(8),
        textAlign: 'justify',
        '& > p > a > span > img': {
            width: '100%',
        },
        '& > p': {
            margin: 0,
            paddingBottom: theme.spacing(1),
        },
        
        '& a': {
            color: '#ffffca',
        },
        
    },
}));
