import {
    Fab, ListItemIcon, ListItemText, Menu, MenuItem
} from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import MapIcon from '@material-ui/icons/Map';
import SatelliteIcon from '@material-ui/icons/Satellite';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useStores } from '../../Stores/useStores';

const MapTypeButtonMessages = defineMessages({
    mapTypeStreets: {
        id: 'MAPTYPE_STREETS',
        defaultMessage: 'Základní',
    },
    mapTypeSatellite: {
        id: 'MAPTYPE_SATELLITE',
        defaultMessage: 'Satelitní',
    },
});

type MapTypeButtonProps = {
    className?: string;
};

const MapTypeButton: React.FC<MapTypeButtonProps> = ({ className }) => {
    const { mapStore } = useStores();
    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSetStreets = () => {
        mapStore.setMapType('streets');
        handleClose();
    };

    const handleSetSatellite = () => {
        mapStore.setMapType('satellite');
        handleClose();
    };

    return (
        <>
            <Fab
                size="medium"
                color="inherit"
                onClick={ handleClick }
                className={ className }
                aria-label="layers"
            >
                <LayersIcon />
            </Fab>
            <Menu
                anchorEl={ anchorEl }
                keepMounted
                open={ Boolean(anchorEl) }
                onClose={ handleClose }
            >
                <MenuItem onClick={ handleSetStreets } selected={ mapStore.mapType === 'streets' }>
                    <ListItemIcon>
                        <MapIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={ intl.formatMessage(MapTypeButtonMessages.mapTypeStreets) } />
                </MenuItem>
                <MenuItem onClick={ handleSetSatellite } selected={ mapStore.mapType === 'satellite' }>
                    <ListItemIcon>
                        <SatelliteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={ intl.formatMessage(MapTypeButtonMessages.mapTypeSatellite) } />
                </MenuItem>
            </Menu>
        </>
    );
};

export default observer(MapTypeButton);
