import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import AppHelmet from './Components/AppHelmet';
import CookieConsent from './Components/CookieConsent';
import FeedbackView from './Components/Feedback/FeedbackView';
import NotFoundRoute from './Components/NotFoundRoute';
import { useAuthentication } from './domain/login/hooks/useAuthentication';
import ContactPage from './Pages/ContactPage';
import HomePage from './Pages/HomePage';
import SignInPage from './Pages/SignInPage';
import { useStores } from './Stores/useStores';
import OrganizationPage from './Pages/OrganizationPage';
import PartnersPage from './Pages/PartnersPage';
import MediaPage from './Pages/MediaPage';
import HomePageWithoutMap from './Pages/HomePageWithoutMap';

//@ts-ignore
//import SmartBanner from 'react-smartbanner';
//import '../node_modules/react-smartbanner/dist/main.css';


type MainProps = {
};

const Main: React.FC<MainProps> = () => {
    const { localeStore, appStore } = useStores();
    const match = useRouteMatch();
    useAuthentication(localeStore.locale);

	let map = true;
	if(process.env?.REACT_APP_MAP === "NOMAP") {
		map = false;
	}

    const rootPath = match.url === '/' ? '' : match.url;
    return (
        <div className="App">
            <AppHelmet rootPath={ rootPath } />

            <Switch>
                <Route path={ `${rootPath}/signIn` } component={ SignInPage } />
                <Route path={ `${rootPath}/partners` } component={ PartnersPage } />
                <Route path={ `${rootPath}/media` } component={ MediaPage } />
                <Route path={ `${rootPath}/organization` } component={ OrganizationPage } />
                <Route path={ `${rootPath}/contact` } component={ ContactPage } />
                <Route path={ `${rootPath}/404` } component={ () => <div>404</div> } />
                <Route path={ `${rootPath}` } component={ (map ? HomePage : HomePageWithoutMap) } />
                <NotFoundRoute />
                { /* <Route path="*">
                    <Redirect to="/404" />
                </Route> */ }
            </Switch>
			{ map && <FeedbackView /> }
            { false && map && <CookieConsent /> }
			{ /*
				!appStore.isApp && <SmartBanner
				title="Kam s ním?"
				author="Ukliďme Česko"
				// daysHidden={0}
				// daysReminder={0}
				position="top"
				// force="ios"

				/>
				*/
			}
        </div>
    );
};

export default observer(Main);
