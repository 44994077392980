import { Toolbar } from '@material-ui/core';
import {
    createStyles, Theme, makeStyles, fade
} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import BetaBadge from '../Common/BetaBadge';
import MenuButton from '../Common/MenuButton';
import ShareButton from '../Common/ShareButton';
import { Logo } from '../Logo';

const DesktopHeader: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={ classes.root }>
            <Toolbar className={ classes.toolbar }>
                <MenuButton className={ classes.toolbarIconButton } />
                <div className={ classes.logoContainer }>
                    <Logo height={ 50 } />
                </div>
                <ShareButton className={ classes.toolbarIconButton } />
                <BetaBadge />
            </Toolbar>
        </div>
    );
};

export default observer(DesktopHeader);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginBottom: theme.spacing(3),
    },
    toolbar: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 2, 0, 2),
    },
    toolbarIconButton: {
        color: fade(theme.palette.common.black, 0.75),
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'stretch',
        flex: 1,
        // alignSelf: 'flex-start',
        marginLeft: theme.spacing(3),
    },
}));
