import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type CleanLinkProps = {
} & LinkProps;

const CleanLink: React.FC<CleanLinkProps> = (props) => {
    const classes = useStyles();
    const { children } = props;
    return (
        <Link { ...props } className={ classes.root }>
            { children }
        </Link>
    );
};

export default CleanLink;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        textDecoration: 'none',
        color: 'black',
    },
}));
