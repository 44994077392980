import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const EmptyButton: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={ classes.root } />
    );
};

export default EmptyButton;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(1),
        width: theme.spacing(2.5),
    },
}));
