import { InputBase, IconButton } from '@material-ui/core'; //Typography, 
import {
    createStyles, Theme, makeStyles, fade
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { isBrowser } from 'react-device-detect';

type SearchBarProps = {
    value: string;
    placeholder: string;
    onChanged: (value: string) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({
    value, placeholder, onChanged,
}) => {
    const classes = useStyles();

    const onClear = () => {
        onChanged('');
    };

    return (
        <div className={ classes.toolbarContent }>
            <div className={ classes.searchContent }>
                <div className={ classes.searchContentSection }>

                    <div className={ classes.search }>
                        <InputBase
                            autoFocus={ isBrowser }
                            placeholder={ placeholder }
                            classes={ {
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            } }
                            value={ value }
                            inputProps={ { 'aria-label': 'search' } }
                            // onFocus={onSearchCategoriesClicked}
                            onChange={ (e) => onChanged(e.target.value) }
                        />
                        { value && (
                            <div className={ classes.clearSearchIcon }>
                                <IconButton color="inherit" size="small" onClick={ onClear }>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    toolbarContent: () => ({
        flex: 1,
        padding: theme.spacing(1, 0),
    }),
    searchContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    searchContentSection: () => ({
        flex: 1,
        margin: theme.spacing(0.25),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
    search: () => ({
        position: 'relative',
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        cursor: 'pointer',
        // ...(params.isMobile
        //     ? {
        //         backgroundColor: fade(theme.palette.primary.main, 0.75),
        //     }
        //     : {
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        // })

    }),
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearSearchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
        top: 0,
        zIndex: 10,
        color: 'white',
    },
    inputRoot: {
        color: 'inherit',
        flexGrow: 1,
        paddingRight: theme.spacing(4),
    },
    inputInput: {
        cursor: 'pointer',
        padding: theme.spacing(1.5, 2),
        transition: theme.transitions.create('width'),
        flexGrow: 1,
        '&:focus': {
            cursor: 'initial',
        },
        '&::placeholder': {
            userSelect: 'none',
            color: theme.palette.common.white,
            opacity: 1,
        },
        '&:focus::placeholder': {
            color: theme.palette.common.white,
            opacity: 0.5,
        },
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: 120,
        //     '&:focus': {
        //         width: 200,
        //     },
        // },
    },
}));
