import { debounce } from 'debounce';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useRef } from 'react';
import Helmet from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';
import {
    useRouteMatch, Switch, Route, useLocation
} from 'react-router';

import IocContainer from '../../../shared/src/Core/IocContainer';
import IMapboxService, { IMapboxService_TYPE } from '../../../shared/src/Core/Services/IMapboxService';
import { LatLng } from '../../../shared/src/Core/Types';
import { unsubscribe } from '../../../shared/src/Core/Utils/Helpers';
import MainMenu from '../Components/HamburgerMenu';
import CategoriesNavigation from '../Components/Home/Categories/CategoriesNavigation';
import CategoriesView from '../Components/Home/Categories/CategoriesView';
import CategoryNearbyResultsPanel from '../Components/Home/CategoryNearbyResultsPanel';
import CategoryResultsNavigation from '../Components/Home/CategoryResultsNavigation';
import AboutView from '../Components/Home/MainPanel/AboutView';
import MainNavigation from '../Components/Home/MainPanel/MainNavigation';
import NearbyResultsView from '../Components/Home/MainPanel/NearbyResultsView';
import PlaceDetailNavigation from '../Components/Home/PlaceDetailNavigation';
import PlaceDetailPanel from '../Components/Home/PlaceDetails/PlaceDetailPanel';
import SearchLocationModal from '../Components/Home/SearchLocation/SearchLocationModal';
import SearchPlacesNavigation from '../Components/Home/SearchPlaces/SearchPlacesNavigation';
import SearchPlacesView from '../Components/Home/SearchPlaces/SearchPlacesView';
import Layout from '../Components/Layout/Layout';
import NotFoundRoute from '../Components/NotFoundRoute';
import { useHomeNavigation } from '../Hooks/useHomeNavigation';
import { useMapLocationQueryParams } from '../Hooks/useMapLocationQueryParams';
import { useStores } from '../Stores/useStores';
import { trimPathEnd } from '../utils/trimPath';

const ErrorMessages = defineMessages({
    notFound: {
        id: 'ERROR_404',
        defaultMessage: 'Hledaná stránka nenalezena',
    },
});

const HomePage: React.FC = () => {
    useMapLocationQueryParams();
    const intl = useIntl();
    const { path } = useRouteMatch<{ municipalityId: string }>();
    const location = useLocation<{ error404: boolean }>();
    const { enqueueSnackbar } = useSnackbar();

    const { current: mapboxService } = useRef(IocContainer.get<IMapboxService>(IMapboxService_TYPE));
    const {
        homeStore, filterTypeStore, searchStore, mapStore, layoutStore,
    } = useStores();
    const { navigateToPlace, navigateToListing } = useHomeNavigation();

    const [isInitialized, setIsInitialized] = useState(false);
    const isReady = isInitialized;

    useEffect(() => {
        layoutStore.startLoading();

        filterTypeStore.load();

        setIsInitialized(true);

        return () => {
            layoutStore.finishLoading();
        };
    }, []);

    // handle errors
    useEffect(() => {
        if (location.state?.error404) {
            enqueueSnackbar(intl.formatMessage(ErrorMessages.notFound), { variant: 'error' });
        }
    }, [location.state]);

    useEffect(() => {
        if (isReady) {
            const userPositionSubscription = mapboxService.userPosition
                .subscribe(debounce((loc: LatLng | null) => loc && ((homeStore.followUserPosition && homeStore.mapLocationType !== 'user') || homeStore.userMapLocation === null) && homeStore.setUserMapLocation(loc), 500));

            const mapPositionSubscription = mapboxService.mapPosition
                .subscribe(({ bounds, zoom }) => searchStore.setMapPosition(bounds, zoom));

            const pinClick = mapboxService.pinClick
                .subscribe((placeId) => navigateToPlace(placeId));

            const municipalityClick = mapboxService.municipalityClick
                .subscribe(() => homeStore.focusMunicipalityLocation());

            const clusterClick = mapboxService.clusterClick
                .subscribe((coords) => mapStore.zoomToCluster(coords));

            const selectedMapPosition = mapboxService.selectedPosition
                .subscribe((loc) => loc && homeStore.setSelectedMapLocation(loc));

            const clearSelectedMapPosition = mapboxService.clearSelectedPosition
                .subscribe(() => homeStore.clearSelectedMapLocation());

            const locationState = mapboxService.locationState
                .subscribe((state) => homeStore.setMapLocationState(state));

            finalizeInitialization();

            return () => {
                unsubscribe(userPositionSubscription);
                unsubscribe(mapPositionSubscription);
                unsubscribe(pinClick);
                unsubscribe(municipalityClick);
                unsubscribe(selectedMapPosition);
                unsubscribe(clearSelectedMapPosition);
                unsubscribe(clusterClick);
                layoutStore.finishLoading();
            };
        }
    }, [isReady]);

    useEffect(() => {
        // handle navigation back to listing from the place detail when a location was selected on the map
        if (homeStore.mapLocationType === 'selected' && homeStore.place) {
            navigateToListing();
        }
    }, [homeStore.mapLocationType, homeStore.selectedMapLocation]);

    const rootPath = trimPathEnd(path);
	// // Loader.async = true;
    return (
        <div className="HomePage">
			<Helmet>

			</Helmet>
            <Layout
                navigation={ (
                    <Switch>
                        <Route path={ `${rootPath}/` } exact component={ MainNavigation } />
                        <Route path={ `${rootPath}/about` } exact component={ MainNavigation } />
                        <Route path={ `${rootPath}/places/search` } exact component={ SearchPlacesNavigation } />
                        <Route path={ `${rootPath}/places/:placeId` } component={ PlaceDetailNavigation } />
                        <Route path={ `${rootPath}/browse/:categoryId` }>
                            <Route path={ `${rootPath}/browse/:categoryId` } exact component={ CategoriesNavigation } />
                            <Route path={ `${rootPath}/browse/:categoryId/search/:searchTerm` } component={ CategoriesNavigation } />
                        </Route>
                        <Route path={ `${rootPath}/categories/:categoryId` }>
                            <Route path={ `${rootPath}/categories/:categoryId` } exact component={ CategoryResultsNavigation } />
                            <Route path={ `${rootPath}/categories/:categoryId/places/:placeId` } component={ PlaceDetailNavigation } />
                            <Route path={ `${rootPath}/categories/:categoryId/products/:productId` }>
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId` } exact component={ CategoryResultsNavigation } />
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId/places/:placeId` } component={ PlaceDetailNavigation } />
                            </Route>
                        </Route>
                    </Switch>
                ) }
                content={ (
                    <Switch>
                        <Route path={ `${rootPath}/` } exact component={ NearbyResultsView } />
                        <Route path={ `${rootPath}/about` } exact component={ AboutView } />
                        <Route path={ `${rootPath}/places/search` } exact component={ SearchPlacesView } />
                        <Route path={ `${rootPath}/places/:placeId` } component={ PlaceDetailPanel } />
                        <Route path={ `${rootPath}/browse/:categoryId` }>
                            <Route path={ `${rootPath}/browse/:categoryId` } exact component={ CategoriesView } />
                            <Route path={ `${rootPath}/browse/:categoryId/search/:searchTerm` } component={ CategoriesView } />
                        </Route>
                        <Route path={ `${rootPath}/categories/:categoryId` }>
                            <Route path={ `${rootPath}/categories/:categoryId` } exact component={ CategoryNearbyResultsPanel } />
                            <Route path={ `${rootPath}/categories/:categoryId/places/:placeId` } component={ PlaceDetailPanel } />
                            <Route path={ `${rootPath}/categories/:categoryId/products/:productId` }>
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId` } exact component={ CategoryNearbyResultsPanel } />
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId/places/:placeId` } component={ PlaceDetailPanel } />
                            </Route>
                        </Route>
                        <NotFoundRoute />
                    </Switch>
                ) }
            />
            <MainMenu />
            <SearchLocationModal />
        </div>
    );

    async function finalizeInitialization() {
        if (await mapboxService.ready()) {
            layoutStore.finishLoading();
            homeStore.setReady();
        }
    }
};

export default observer(HomePage);
