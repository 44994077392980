export function openLinkInNewTab(link: string) {
    let uri = link;
    if (!link.startsWith('https://') && !link.startsWith('http://')) {
        uri = `http://${link}`;
    }

    const win = window.open(uri, '_blank');
    if (win) {
        win.focus();
    }
}

export function clsx(...classes: (string | undefined)[]) {
    return classes.filter((cls) => !!cls).join(' ');
}
