import ClusterSearchResult from '@shared/Core/Models/ClusterSearchResult';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { Point } from '@shared/Core/Models/Point';
import IMapboxService, { MapLocationState } from '@shared/Core/Services/IMapboxService';
import { LatLng, Bounds, MapType } from '@shared/Core/Types';
import { placeTypeToImageFileName } from '@shared/Core/Utils/Helpers';
import { Point as GeoJsonPoint, FeatureCollection, Feature } from 'geojson';
import { injectable } from 'inversify';
import loadcss from 'loadcss';
import * as mapboxgl from 'mapbox-gl';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';

import IEnvironment, { IEnvironment_TYPE } from '../../../shared/src/Core/IEnvironment';
import IocContainer from '../../../shared/src/Core/IocContainer';
import { MunicipalityInfo } from '../../../shared/src/Core/Models/MunicipalityInfo';
import { locationsEqual } from '../../../shared/src/Core/Utils/locationTolerance';
import Theme from '../Theme';

const placeTypes = ['default', 'collectionPoint', 'container', 'containerPoint', 'lekarna', 'box', 'shop', 'wastePoint', 'pneuShop', 'popelnice', 'covid', 'rossmann',
    'collectionPoint2', 'collectionPoint3','containerPointH', 'containerPoint2', 'containerPoint3', 'container1', 'container2', 'container3', 'track1', 'track2', 'track3',
    'popelnice1', 'popelnice2', 'popelnicePes1', 'popelniceInfekcni1', 'box2', 'box3', 'shop0', 'shop1', 'shop2', 'shop3', 'pneuShop2', 'autovraky2', 'lekarna2',
    'restaurant0', 'restaurant2', 'restaurant3', 'recycle2', 'reuse3', 'charity3', 'repair3', 'wc0', 'voda0', 'post0', 'post1', 'post2', 'gasStation0', 'gasStation1',
    'gasStation2', 'book3', 'rossmann2', 'compost3'
];

const CAMERA_ANIM_DURATION = 2500;
const positionOptions = {
    enableHighAccuracy: false,
    timeout: 10 * 1000, // 10 seconds
    maximumAge: 30 * 1000, // 30 seconds
};
const MAP_FONTS = ['Open Sans Semibold'];

const MAP_STYLE_STREETS = 'mapbox://styles/mapbox/streets-v11?optimize=true';
const MAP_STYLE_SATELLITE = 'mapbox://styles/mapbox/satellite-v9?optimize=true';

@injectable()
export default class MapboxService implements IMapboxService {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    private _locationAccuracy: number = -1;

    public get locationAccuracy() {
        return this.userPosition.getValue()
            ? this._locationAccuracy
            : 0;
    }

    public _map: mapboxgl.Map | null = null;

    public get map() { return this._map!; }

    public mapCameraOffset = new Point(0, 0);

    public userPosition = new BehaviorSubject<LatLng | null>(null);

    public mapPosition = new ReplaySubject<{ bounds: Bounds, center: LatLng, zoom: number }>();

    public pinClick = new Subject<string>();

    public municipalityClick = new Subject<never>();

    public clusterClick = new Subject<LatLng>();

    public selectedPosition = new BehaviorSubject<LatLng | null>(null);

    public locationState = new BehaviorSubject<MapLocationState>('locating');

    public clearSelectedPosition = new Subject<void>();

    public selectedPlace: PlaceInfo | null = null;

    public currentUserLocation: LatLng | null = null;

    private resolveMapReadyPromise: (value?: unknown) => void = () => { throw Error('Resolve map promise not set.'); };

    private rejectMapReadyPromise: (reason?: any) => void = () => { throw Error('Reject map promise not set.'); };

    private mapReadyPromise = this.initMapPromise();

    private initMapPromise() {
        return new Promise((resolve, reject) => {
            this.resolveMapReadyPromise = resolve;
            this.rejectMapReadyPromise = reject;
        });
    }

    private get clustersDataSource() { return this.map.getSource('clustersdata') as mapboxgl.GeoJSONSource; }

    private get municipalityDataSource() { return this.map.getSource('municipality-geojson') as mapboxgl.GeoJSONSource; }

    private get placesDataSource() { return this.map.getSource('placesdata') as mapboxgl.GeoJSONSource; }

    private get userLocationDataSource() { return this.map.getSource('user-coordinates') as mapboxgl.GeoJSONSource; }

    private placesDataCollection: FeatureCollection<GeoJsonPoint> | null = null;

    private selectedPlaceMarker: mapboxgl.Marker | null = null;

    private municipalityPlaceMarker: mapboxgl.Marker | null = null;

    private selectedMarker: mapboxgl.Marker | null = null;

    private get hasSelectedLocation() { return this.selectedMarker !== null; }

    private doFocusUserLocationAfterUpdate = false;

    private clickEventHandled = false;

    constructor() {
        console.debug('MapboxService instance created');
    }

    public setMapType(type: MapType): void {
        if (!this.map) {
            throw Error('Map not set');
        }

        switch (type) {
        case 'streets':
            if (this.map.getStyle().name === 'Mapbox Streets') { break; }
            this.map.setStyle(MAP_STYLE_STREETS, { diff: true });
            break;
        case 'satellite':
            if (this.map.getStyle().name === 'Mapbox Satellite') { break; }
            this.map.setStyle(MAP_STYLE_SATELLITE, { diff: true });
            break;
        default: throw Error('Invalid map type');
        }
    }

    public initializeMap(elementId: string, options: {
        zoom: number,
        center: LatLng,
    }) {
        this.mapReadyPromise = this.initMapPromise();

        loadcss('https://api.tiles.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css');

        // mapbox access token
        (mapboxgl as any).accessToken = this.environment.mapboxKey;

        console.debug('mapbox init');
        if (this.map) {
            this.map.remove();
        }

        // TODO: use free tiles as a source
        // TODO: use free glyphs for the fonts

        this._map = new mapboxgl.Map({
            container: elementId,
            // container: this.element,
            style: MAP_STYLE_STREETS,
            // style: 'https://api.maptiler.com/maps/streets/style.json?key=oTCW2xkdRzlPBahEw9pp',

            /*          style: {
                version: 11,
                sources: {
                    'raster-tiles': {
                        type: 'raster',
                        url: 'mapbox://mapbox/streets',
                        tileSize: 256,
                    },
                },
                // custom glyphs for fonts
                glyphs: `${window.location.origin}/assets/font/{fontstack}/{range}.pbf`,
                layers: [{
                    id: 'simple-tiles',
                    type: 'raster',
                    source: 'raster-tiles',
                    minzoom: 0,
                    maxzoom: 22,
                }],
            },
*/
            // style: {
            //     'version': 8,
            //     'sources': {
            //         'raster-tiles': {
            //             'type': 'raster',
            //             // 'tiles': ['https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'],
            //             'tiles': [`https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=${this.environment.mapTilerKey}`],
            //             // 'tileSize': 256,
            //             'tileSize': 512,
            //             // TODO
            //             // "attribution": 'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>'
            //         }
            //     },
            //     // custom glyphs for fonts
            //     'glyphs': `${window.location.origin}/assets/font/{fontstack}/{range}.pbf`,
            //     'layers': [{
            //         'id': 'simple-tiles',
            //         'type': 'raster',
            //         'source': 'raster-tiles',
            //         'minzoom': 0,
            //         'maxzoom': 22
            //     }]
            // },
            pitchWithRotate: false,
            zoom: options.zoom,
            center: [options.center.lng, options.center.lat],
            trackResize: true,
            // customAttribution: ['<a href="https://www.maptiler.com/copyright/" target="_blank" rel="noopener">© MapTiler</>', '<a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener">© OpenStreetMap contributors</a>']
            // localIdeographFontFamily: "'Noto Sans', 'Noto Sans CJK SC', sans-serif"
        });
        this._map.dragRotate.disable();
        this._map.touchZoomRotate.disableRotation();
        // this._map.touchPitch.disable();

        this.watchUserLocation();
        if (this.currentUserLocation) {
            this._map.setCenter(this.currentUserLocation);
        }

        this._map.on('load', async () => {
            // this.map.on('movestart', e => {
            //     if (e.originalEvent) {
            //         const mapBounds = this.map.getBounds();
            //         const bounds = new Bounds(mapBounds.getNorth(), mapBounds.getSouth(), mapBounds.getWest(), mapBounds.getEast());
            //         const zoom = this.map.getZoom();
            //         this.mapPosition.next({ bounds, zoom });
            //     }
            // });

            this.map.on('moveend', (e) => this.fireMoveendEvent());

            this.map.on('mousemove', (e) => {
                const features = this.map.queryRenderedFeatures(e.point, { layers: ['clusters', 'places', 'clustered-places'] });
                this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
            });

            this.map.on('click', (e) => this.handleClickEvent(e));

            this.buildUi();

            this.resolveMapReadyPromise();

            this.fireMoveendEvent();

                this._map!.resize();
        });

        this._map.on('style.load', async () => {
            await this.loadAssets();
            this.setupDataSources();
            this.buildLayers();
        });
        return this.mapReadyPromise;
    }

    public destroy() {
        this.map.remove();
        this._map = null;
        this.rejectMapReadyPromise();
        this.mapReadyPromise = this.initMapPromise();
    }

    public async ready() {
        try {
            await this.mapReadyPromise;
            return true;
        } catch {
            return false;
        }
    }

    public async flyTo(center: LatLng, zoom: number) {
        if (await this.ready()) {
            this.notifyUserInteraction();
            this.map.flyTo({
                center, zoom, animate: true, duration: CAMERA_ANIM_DURATION, offset: new mapboxgl.Point(this.mapCameraOffset.x, this.mapCameraOffset.y),
            });
        }
    }

    public async flyToLocations(points: LatLng[], defaultZoom: number) {
        if (await this.ready()) {
            if (points.length === 0) {
                return;
            }

            if (points.length === 1) {
                this.flyTo(points[0], defaultZoom);
                return;
            }

            this.notifyUserInteraction();

            const fitBounds = points.reduce((bounds, point) => {
                bounds.north = Math.max(bounds.north, point.lat);
                bounds.east = Math.max(bounds.east, point.lng);
                bounds.south = Math.min(bounds.south, point.lat);
                bounds.west = Math.min(bounds.west, point.lng);
                return bounds;
            }, new Bounds(-180, 180, 90, -90));

            return this.flyToBounds(fitBounds);
        }
    }

    public async flyToBounds(bounds: Bounds) {
        const targetBounds = new mapboxgl.LngLatBounds(
            bounds.getSouthWest(),
            bounds.getNorthEast()
        );
        this.map.fitBounds(targetBounds, {
            animate: true,
            duration: CAMERA_ANIM_DURATION,
            offset: new mapboxgl.Point(this.mapCameraOffset.x, this.mapCameraOffset.y),
            maxZoom: 20,
            padding: 100,
        });
    }

    public setCenter(center: LatLng, zoom: number) {
        this.map.setCenter(new mapboxgl.LngLat(center.lng, center.lat));
        this.map.setZoom(zoom);
    }

    public getCenter(): LatLng {
        return this.map.getCenter();
    }

    public getZoom() { return this.map.getZoom(); }

    public zoomTo(zoom: number) {
        this.notifyUserInteraction();
        this.map.zoomTo(zoom);
    }

    public async locateUser(options?: { skipFlyToLocation: boolean }) {
        this.doFocusUserLocationAfterUpdate = true;
        this.clearMapLocation();

        if (!this.currentUserLocation) { return; }
        // if (!options?.skipFlyToLocation) {
        //     this.flyTo(this.currentUserLocation, 15);
        // }

        this.fireUserLocationEvent();
    }

    public async setPlacesMarkers(places: PlaceInfo[], fitBounds = false) {
        if (await this.ready()) {
            this.clearMarkers();

            const dataSrc = this.placesDataSource;
            if (!dataSrc) {
                return;
            }

            const features = places.map((place) => this.createPlaceFeature(place));

            this.placesDataCollection = {
                type: 'FeatureCollection',
                features,
            };

            const collectionToDisplay = this.placesDataCollectionToDisplay;
            if (collectionToDisplay) {
                dataSrc.setData(collectionToDisplay);
            }
        }
    }

    public async setClustersMarkers(clusters: ClusterSearchResult[]) {
        if (await this.ready()) {
            this.clearMarkers();

            const dataSrc = this.clustersDataSource;
            if (!dataSrc) {
                return;
            }

            const features = clusters.map((cluster, i) => ({
                id: i.toString(),
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [cluster.location.lng, cluster.location.lat] },
                properties: {
                    count: cluster.count.toString(),
                    label: cluster.name,
                },
            } as Feature<GeoJsonPoint>));

            const collection: FeatureCollection<GeoJsonPoint> = {
                type: 'FeatureCollection',
                features,
            };

            dataSrc.setData(collection);
        }
    }

    public clearMarkers() {
        const clustersDataSrc = this.clustersDataSource;
        if (clustersDataSrc) {
            clustersDataSrc.setData({
                type: 'FeatureCollection',
                features: [],
            });
        }
        const placesDataSrc = this.placesDataSource;
        if (placesDataSrc) {
            placesDataSrc.setData({
                type: 'FeatureCollection',
                features: [],
            });
        }
    }

    public async setMunicipality(info: MunicipalityInfo | null) {
        if (await this.ready()) {
            if (info) {
            // marker
            /* 9const element = document.createElement('img');
            element.setAttribute('src', info.image);
            element.setAttribute('width', '48px');
            element.setAttribute('height', '48px');
            element.style.cursor = 'pointer';

            element.onclick = () => {
                this.municipalityClick.next();
                this.clickEventHandled = true;
            };

            this.municipalityPlaceMarker = new mapboxgl.Marker({
                element,
                anchor: 'center',
            });
            this.municipalityPlaceMarker.setLngLat(info.location);
            this.municipalityPlaceMarker.addTo(this.map); */

                // geojson
                this.municipalityDataSource.setData({
                    type: 'Feature',
                    geometry: info.geoJSON,
                } as any);
            } else {
            /* this.municipalityPlaceMarker.remove();
            this.municipalityPlaceMarker = null; */
				if(this.municipalityDataSource) {
					this.municipalityDataSource.setData({
						type: 'FeatureCollection',
						features: [],
					});
				}
            }
        }
    }

    public async setSelectedPlace(place: PlaceInfo | null) {
        if (await this.ready()) {
            this.selectedPlace = place;

            if (this.selectedPlaceMarker) {
                if (this.placesDataCollection && this.placesDataSource) {
                    this.placesDataSource.setData(this.placesDataCollection);
                }

                this.selectedPlaceMarker.remove();
                this.selectedPlaceMarker = null;
            }

            if (place) {
                if (this.placesDataCollection) {
                    const x = this.placesDataCollectionToDisplay;
                    if (x) {
                        this.placesDataSource.setData(x);
                    }
                }

                const element = document.createElement('img');
                element.setAttribute('src', `/assets/${placeTypeToImageFileName(place.type)}`);
                element.setAttribute('width', '64px');
                element.setAttribute('height', '64px');

                this.selectedPlaceMarker = new mapboxgl.Marker({
                    element,
                    anchor: 'bottom-right',
                });
                this.selectedPlaceMarker.setLngLat(place.location);
                this.selectedPlaceMarker.addTo(this.map);

                this.startAnimateSelectedPlace();
            }
        }
    }

    public projectBoundsPosition(bounds: Bounds): Point {
        const point_ne = this.map.project(new mapboxgl.LngLat(bounds.east, bounds.north));
        return new Point(point_ne.x, point_ne.y);
    }

    public projectLngLat(latLng: LatLng) {
        const pos = this.map.project(latLng);
        return new Point(pos.x, pos.y);
    }

    public addPaddingToBounds(bounds: Bounds, padding: number): Bounds {
        const point_ne = this.map.project(bounds.getNorthEast());
        const point_sw = this.map.project(bounds.getSouthWest());

        // add padding in pixels
        const point_ne_added = point_ne.add(new mapboxgl.Point(padding, -padding));
        const point_sw_added = point_sw.add(new mapboxgl.Point(-padding, padding));

        const sw = this.map.unproject(point_sw_added);
        const ne = this.map.unproject(point_ne_added);
        const newBounds = new Bounds(ne.lat, sw.lat, sw.lng, ne.lng);
        return newBounds;
    }

    private handleClickEvent(e: { lngLat: mapboxgl.LngLat, point: mapboxgl.Point }) {
        if (this.clickEventHandled) {
            this.clickEventHandled = false;
            return;
        }
        console.log('clickmap');
        // handle clusters click
        const clusterFeatures = this.map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        if (clusterFeatures.length > 0) {
            const point = clusterFeatures[0].geometry as GeoJsonPoint;
            const coords: LatLng = { lng: point.coordinates[0], lat: point.coordinates[1] };
            // this.flyTo(, this.getNextZoomIn());
            this.clusterClick.next(coords);
            return;
        }
        // handle places click
        const placesFeatures = this.map.queryRenderedFeatures(e.point, { layers: ['places'] });
        if (placesFeatures.length > 0) {
            const place = JSON.parse(placesFeatures[0].properties!.place) as PlaceInfo;
            if (!place) {
                return;
            }
            this.pinClick.next(place.id);
            return;
        }
        // handle clustered places click
        const clusteredPlacesfeatures = this.map.queryRenderedFeatures(e.point, { layers: ['clustered-places'] });
        if (clusteredPlacesfeatures.length > 0) {
            const clusterId = clusteredPlacesfeatures[0].properties!.cluster_id;
            if (!clusterId) {
                return;
            }
            this.placesDataSource.getClusterExpansionZoom(clusterId, (err: Error, zoom: number) => {
                const point = clusteredPlacesfeatures[0].geometry as GeoJsonPoint;
                this.flyTo({ lng: point.coordinates[0], lat: point.coordinates[1] }, zoom + 0.2);
            });
            return;
        }

        // handle map click
        this.selectMapLocation(e.lngLat);
    }

    private selectMapLocation(lngLat: mapboxgl.LngLat) {
        if (this.selectedMarker !== null) {
            this.selectedMarker.remove();
        }

        this.selectedMarker = new mapboxgl.Marker({
            color: Theme.theme_primaryColor_hex,
        });
        this.selectedMarker.setLngLat(lngLat);
        this.selectedMarker.addTo(this.map);
        this.selectedPosition.next({ lat: lngLat.lat, lng: lngLat.lng });

        this.doFocusUserLocationAfterUpdate = false;
    }

    public async setSelectedPosition(latLng: LatLng) {
        await this.mapReadyPromise;
        if (this.selectedPosition.value && this.selectedPosition.value.lat === latLng.lat && this.selectedPosition.value.lng === latLng.lng) {
            return;
        }
        this.selectMapLocation(new mapboxgl.LngLat(latLng.lng, latLng.lat));
    }

    public clearMapLocation() {
        if (this.selectedPosition.value === null) {
            return;
        }

        if (this.selectedMarker !== null) {
            this.selectedMarker.remove();
            this.selectedMarker = null;
        }
        this.selectedPosition.next(null);
        this.clearSelectedPosition.next();
    }

    private fireMoveendEvent() {
        const mapBounds = this.map.getBounds();
        const center = this.map.getCenter();
        const bounds = new Bounds(mapBounds.getNorth(), mapBounds.getSouth(), mapBounds.getWest(), mapBounds.getEast());
        const zoom = this.map.getZoom();
        this.mapPosition.next({ bounds, zoom, center });
    }

    private notifyUserInteraction() {
        this.doFocusUserLocationAfterUpdate = false;
    }

    private watchUserLocation() {
        let shouldMoveMapAfterPositionFound = false;
        const storedLoc = localStorage.getItem('user-coords');
        if (storedLoc) {
            const coords = JSON.parse(storedLoc) as LatLng;
            this.setUserLocation(coords);
            shouldMoveMapAfterPositionFound = true;
        }

        console.debug('LOC::watch started');
        navigator.geolocation.watchPosition((position) => {
            this.doFocusUserLocationAfterUpdate = shouldMoveMapAfterPositionFound;
            shouldMoveMapAfterPositionFound = false;

            this._locationAccuracy = position.coords.accuracy;
            const location = { lng: position.coords.longitude, lat: position.coords.latitude };
            this.locationState.next('located');
            this.setUserLocation(location);
        }, (err) => {
            this.locationState.next('error');
            // console.error('LOC::watch error');
            // TODO
        }, positionOptions);
        // navigator.geolocation.getCurrentPosition(position => {
        //     this.updateUserLocation(position);
        //     navigator.geolocation.watchPosition(position => this.updateUserLocation(position), err => {
        //         // TODO
        //     }, positionOptions);
        // }, err => {
        //     // TODO
        // }, positionOptions);
    }

    private fireUserLocationEvent() {
        if (!this.currentUserLocation) { return; }
        // ignore user location event in case another location is already selected
        if (!this.hasSelectedLocation) {
            this.userPosition.next(this.currentUserLocation);
        }
    }

    private setUserLocation(location: LatLng) {
        if (this.currentUserLocation && locationsEqual(location, this.currentUserLocation)) {
            return;
        }

        this.currentUserLocation = location;
        localStorage.setItem('user-coords', JSON.stringify(location));
        this.setUserLocationMarker();
        this.fireUserLocationEvent();
        // if (this.doFocusUserLocationAfterUpdate) {
        //     this.flyTo(this.currentUserLocation, 15);
        //     this.doFocusUserLocationAfterUpdate = false;
        // }
    }

    private setUserLocationMarker() {
        if (!this.userLocationDataSource || !this.currentUserLocation) { return; }
        this.userLocationDataSource.setData({
            type: 'FeatureCollection',
            features: [
                {
                    id: 'usr',
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Point',
                        coordinates: [this.currentUserLocation.lng, this.currentUserLocation.lat],
                    },
                },
            ],
        });
    }

    private get placesDataCollectionToDisplay() {
        if (!this.placesDataCollection) { return null; }
        if (!this.selectedPlace) { return this.placesDataCollection; }
        const x: FeatureCollection<GeoJsonPoint> = {
            ...this.placesDataCollection,
            features: [
                ...this.placesDataCollection.features.filter((f) => f.id !== this.selectedPlace!.id),
            ],
        };
        return x;
    }

    private buildUi() {
        // Add geolocate control to the map.
        // const gc = new mapboxgl.GeolocateControl({
        //     positionOptions: {
        //         enableHighAccuracy: true
        //     },
        //     showUserLocation: true,
        //     trackUserLocation: true,
        // });
        // gc.on('trackuserlocationstart', () => {
        //     this.clearMapLocation();
        // });
        // this.map.addControl(gc);
        // setTimeout(() => {
        //     gc.trigger();
        // }, 1000);

        // const sc = new mapboxgl.ScaleControl();
        // this.map.addControl(sc, 'bottom-right');

        // const nc = new mapboxgl.NavigationControl({ showZoom: true, showCompass: false });
        // this.map.addControl(nc);
    }

    private dataSourceMap = new Map<string, mapboxgl.AnySourceImpl | undefined>()

    private addDataSource(name: string, dataSource: mapboxgl.AnySourceData) {
        // get the current data source instance
        const currentSource = this.dataSourceMap.get(name);

        // add datasource into the map
        this.map.addSource(name, dataSource);
        const newSource = this.map.getSource(name);

        // set initial data from the previous instance of the data source
        if (currentSource?.type === 'geojson' && newSource.type === 'geojson') {
            const { _data } = currentSource as any;
            newSource.setData(_data);
        }

        // set the current instance of the datasource into the map
        this.dataSourceMap.set(name, newSource);
    }

    private setupDataSources() {
        this.addDataSource('placesdata', {
            type: 'geojson',
            cluster: true,
            clusterRadius: 40,
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });
        this.addDataSource('municipality-geojson', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });
        this.addDataSource('clustersdata', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });
        this.addDataSource('user-coordinates', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        });
    }

    private buildLayers() {
        // municipality
        this.map.addLayer({
            id: 'municipality-geojson-layer',
            type: 'line',
            source: 'municipality-geojson',
            paint: {
                'line-width': 3,
                'line-color': 'red',
                'line-opacity': 0.75,
            },
        });

        // clusters
        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'clustersdata',
            paint: {
                'circle-color': 'white',
                'circle-opacity': 0.55,
                'circle-radius': 30,
                // 'circle-stroke-opacity': .45,
                // 'circle-stroke-width': 1,
                // 'circle-stroke-color': Theme.theme_primaryColor_hex
                'circle-stroke-width': 5,
                'circle-stroke-opacity': 0.6,
                'circle-stroke-color': Theme.theme_primaryColor_hex,
            },
        });
        this.map.addLayer({
            id: 'clusters-counts',
            type: 'symbol',
            source: 'clustersdata',
            layout: {
                'text-allow-overlap': true,
                'text-field': ['get', 'count'],
                'text-font': MAP_FONTS,
            },
            paint: {
                'text-color': Theme.theme_primaryColor_hex,
                'text-halo-color': 'white',
                'text-halo-width': 1.5,
            },
        });
        this.map.addLayer({
            id: 'clusters-labels',
            type: 'symbol',
            source: 'clustersdata',
            layout: {
                'text-allow-overlap': true,
                'text-field': ['get', 'label'],
                'text-offset': [0, 2],
                'text-justify': 'center',
                'text-font': MAP_FONTS,
                'text-size': 13,
            },
            paint: {
                'text-color': Theme.theme_primaryColor_hex,
                'text-halo-color': 'white',
                'text-halo-width': 3,
            },
        });

        // clustered places
        this.map.addLayer({
            id: 'clustered-places',
            type: 'circle',
            source: 'placesdata',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': 'white',
                'circle-opacity': 0.55,
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    25,
                    4,
                    27,
                    6,
                    30,
                ],
                'circle-stroke-width': 5,
                'circle-stroke-opacity': 0.6,
                'circle-stroke-color': Theme.theme_primaryColor_hex,
            },
        });
        this.map.addLayer({
            id: 'clustered-places-labels',
            type: 'symbol',
            source: 'placesdata',
            filter: ['has', 'point_count'],
            layout: {
                'text-allow-overlap': true,
                'text-field': '{point_count_abbreviated}',
                'text-font': MAP_FONTS,
                'text-size': 18,
            },
            paint: {
                'text-color': Theme.theme_primaryColor_hex,
                'text-halo-color': 'white',
                'text-halo-width': 1.5,
            },
        });

        // places
        this.map.addLayer({
            id: 'places',
            type: 'symbol',
            source: 'placesdata',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-allow-overlap': true,
                'icon-image': ['concat', 'pin-', ['get', 'type']],
                'icon-size': 0.22,
                'icon-anchor': 'bottom-right',
            },
        });

        // user position
        this.map.addLayer({
            id: 'user-coordinates',
            source: 'user-coordinates',
            type: 'circle',
            paint: {
                'circle-color': Theme.theme_primaryColor_hex,
                'circle-opacity': 0.75,
                'circle-radius': 10,
                'circle-stroke-width': 4,
                // 'circle-stroke-opacity': .45,
                'circle-stroke-color': 'white',
            },
        });
        this.setUserLocationMarker();
    }

    private loadAsset(name: string, fileName: string) {
        return new Promise<void>((r, reject) => {
            this.map.loadImage(`/assets/${fileName}`, (err: string, img: ImageData) => {
                if (!err) {
                    this.map.addImage(name, img);
                    r();
                } else { reject(); }
            });
        });
    }

    private placeStartAnimationTimestamp = 0;

    private placeAnimationProgress = 0;

    private startAnimateSelectedPlace() {
        this.placeStartAnimationTimestamp = 0;
        this.placeAnimationProgress = 0;
        window.requestAnimationFrame((ts) => this.animateSelectedPlace(ts));
    }

    private animateSelectedPlace(timestamp: number) {
        if (!this.placeStartAnimationTimestamp) this.placeStartAnimationTimestamp = timestamp;
        const ellapsedTime = timestamp - this.placeStartAnimationTimestamp;

        this.placeAnimationProgress += 8 * (ellapsedTime / 1000);
        if (this.selectedPlaceMarker) {
            const offset = Math.cos(this.placeAnimationProgress) * 5 - 5;
            this.selectedPlaceMarker.setOffset(new mapboxgl.Point(offset, offset));
        }

        this.placeStartAnimationTimestamp = timestamp;

        if (this.selectedPlace !== null) {
            window.requestAnimationFrame((ts) => this.animateSelectedPlace(ts));
        }
    }

    private createPlaceFeature(place: PlaceInfo) {
        return {
            id: place.id,
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [place.location.lng, place.location.lat] },
            properties: {
                place,
                type: place.type,
                photoUrl: place.photoUrl,
            },
        } as Feature<GeoJsonPoint>;
    }

    private async loadAssets() {
        // TODO change so it's loaded when data are set
        await Promise.all(placeTypes.map((type) => this.loadAsset(`pin-${type}`, `pin-${type}.png`)));
    }
}
