import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../../Stores/useStores';
import MainSearchButton from '../../Common/MainSearchButton';
import Panel from '../../Common/Panel';
import NearbyPlacesList from '../../Place/NearbyPlacesList';

const NearbyResultsView: React.FC = () => {
    const { searchStore, layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    const init = React.useCallback(async () => {
        layoutStore.resetToDefaults();
        await searchStore.clearCategory();

        // make sure the nearby items are loaded
        if (!searchStore.resultsNearbyLoading) {
            await searchStore.searchNearby();
        }
    }, []); // `useCallback` bez závislostí

    // on component mount
    React.useEffect(() => {
        init();
    }, [init]); // Přidání `init` jako závislosti

    const onLoadMore = () => searchStore.loadMoreNearbyResults();

    return (
        <Panel infiniteScroll onLoadMore={ onLoadMore } loadingMore={ searchStore.moreResultsNearbyLoading } loading={ searchStore.resultsNearbyLoading } hasMoreToLoad={ searchStore.resultsNearbyHasMore && searchStore.resultsNearby.length > 0 }>
            { layoutStore.isMobile && (
                <div className={ classes.searchButtonContainer }>
                    <MainSearchButton />
                </div>
            ) }
            <div className={ classes.listHolder }>
                <NearbyPlacesList showCategoryMenu />
            </div>
        </Panel>
    );

    
};

export default observer(NearbyResultsView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    listHolder: (params: any) => ({
        marginTop: params.isMobile ? theme.spacing(3) : 0,
        // marginTop: params.isMobile ? theme.spacing(8) : 0,
    }),
    searchButtonContainer: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        top: -28,
        zIndex: 200,
    },
}));
