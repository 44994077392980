import {
    Fab, IconButton, Tooltip, Zoom
} from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

type LocateUserButtonProps = {
    type: 'fab' | 'icon';
    className?: string;
};

const LocateUserButton: React.FC<LocateUserButtonProps> = ({ type, className }) => {
    const { homeStore } = useStores();

    const onLocateUser = () => {
        homeStore.focusUserLocation();
    };

    switch (type) {
    case 'fab':
        return (
            <Fab
                size="medium"
                color="inherit"
                aria-label="geolocate"
                className={ `${className} ${homeStore.mapLocationState === 'locating' ? 'spin' : ''}` }
                onClick={ onLocateUser }
            >
                <MyLocationIcon />
            </Fab>
        );
    case 'icon':
        return (
            <Tooltip title="Přejít na mou polohu" TransitionComponent={ Zoom } enterDelay={ 100 }>
                <IconButton
                    className={ `${className} ${homeStore.mapLocationState === 'locating' ? 'spin' : ''}` }
                    color="inherit"
                    size="small"
                    aria-label="geolocate"
                    onClick={ onLocateUser }
                >
                    <MyLocationIcon style={ { fontSize: 18 } } />
                </IconButton>
            </Tooltip>
        );
    default:
        throw Error(`Invalid type: ${type}`);
    }
};

export default observer(LocateUserButton);
