import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useStores } from '../../Stores/useStores';
import BackButton from '../Common/BackButton';
import CloseButton from '../Common/CloseButton';
import ResponsiveToolbar from '../Layout/ResponsiveToolbar';

const CategoryResultsNavigation: React.FC = () => {
    const { navigateBack, navigateHome, navigateToCategoryBrowse } = useHomeNavigation();
    const { categoryStore } = useStores();
    const classes = useStyles();

    const onBackButtonClick = () => navigateBack();
    const onCloseButtonClicked = () => navigateHome();
    const onTitleClicked = () => navigateToCategoryBrowse('all');

    return (
        <div className={ classes.root }>
            <ResponsiveToolbar>
                <>
                    <BackButton onClick={ onBackButtonClick } />
                    <Typography variant="subtitle1"  component="h1" className={ classes.title } onClick={ onTitleClicked }>{ categoryStore.selectedTitle }</Typography>
                    <CloseButton onClick={ onCloseButtonClicked } />
                    { /* <EmptyButton /> */ }
                    { /* <FilterButton color="light" active={filterTypeStore.hasSelectedTypes} /> */ }
                </>
            </ResponsiveToolbar>
        </div>
    );
};

export default observer(CategoryResultsNavigation);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        // padding: theme.spacing(1, 1)
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
    },
}));
