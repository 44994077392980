import { injectable } from 'inversify';
import {
    observable, action, runInAction, computed, reaction, makeObservable
} from 'mobx';

import { ZOOM_LEVELS_MAP } from '../Constants';
import IocContainer from '../IocContainer';
import { MunicipalityInfo } from '../Models/MunicipalityInfo';
import { PlaceInfo } from '../Models/PlaceInfo';
import ApiService from '../Services/ApiService';
import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '../Services/IAnalyticsLogger';
import IGeocoder, { IGeocoder_TYPE } from '../Services/IGeocoder';
import IMapboxService, { IMapboxService_TYPE, MapLocationState } from '../Services/IMapboxService';
import { LocationTypeProvider } from '../Services/LocationTypeProvider';
import PlaceService from '../Services/PlaceService';
import { LatLng, ActionStatus } from '../Types';
import { locationsEqual } from '../Utils/locationTolerance';
import { getZoomFromGeoJson } from '../Utils/mapZoomHelpers';

import FilterTypeStore from './FilterTypeStore';
import { ILocaleStore, ILocaleStore_TYPE } from './ILocaleStore';
import { MapStore } from './MapStore';
import SearchStore from './SearchStore';

export type MapLocationType = 'user' | 'selected' | 'radnice' | 'none';

const municipalityCache: Record<string, MunicipalityInfo> = {};

@injectable()
export default class HomeStore {
    private api = IocContainer.get(ApiService);

    private analyticsLogger = IocContainer.get<IAnalyticsLogger>(IAnalyticsLogger_TYPE);

    private filterTypeStore = IocContainer.get(FilterTypeStore);

    private searchStore = IocContainer.get(SearchStore);

    private placeService = IocContainer.get(PlaceService);

    private mapboxService = IocContainer.get<IMapboxService>(IMapboxService_TYPE);

    private geocoder = IocContainer.get<IGeocoder>(IGeocoder_TYPE);

    private localeStore = IocContainer.get<ILocaleStore>(ILocaleStore_TYPE);

    private mapStore = IocContainer.get(MapStore);

    private locationTypeProvider = IocContainer.get(LocationTypeProvider);

    @observable ready = false;

    @observable mainMenuOpen: boolean = false;

    @observable place: PlaceInfo | null = null;

    @observable placeTypeLabel: string = '';

    @observable selectedMapLocation: LatLng | null = null;

    @observable selectedMapLocationGeocoded: string | null = null;

    @observable userMapLocation: LatLng | null = null;

    @observable userMapLocationStatus: ActionStatus = 'initial';

    @observable municipality: MunicipalityInfo | null = null;

    @observable isMunicipalityLoading = false;

    @observable followUserPosition: boolean = false;

    @observable mapLocationState: MapLocationState = 'locating';

    @computed get mapLocationType(): MapLocationType {
        if (!this.followUserPosition) {
            if (this.selectedMapLocation !== null) { return 'selected'; }
            if (this.municipality !== null) { return 'radnice'; }
        }
        if (this.userMapLocation !== null) { return 'user'; }
        return 'none';
    }

    @computed get activeMapLocation(): LatLng | null {
        switch (this.mapLocationType) {
        case 'selected':
            return this.selectedMapLocation!;
        case 'radnice':
            return this.municipality?.location!;
        case 'user':
            return this.userMapLocation!;
        case 'none':
            return null;
        default:
            throw Error('Invalid map location type.');
        }
    }

    @computed get isLoading() {
        return this.searchStore.resultsNearbyLoading
            || this.searchStore.moreResultsNearbyLoading
            || this.searchStore.mapResultsLoading
            || this.isMunicipalityLoading;
    }

    @computed get canFocusNearbyPlaces() {
        return this.place === null && this.mapLocationType !== 'radnice';
    }

    constructor() {
        makeObservable(this);
        this.setupReactions();
    }

    @action setReady() {
        this.ready = true;
    }

    @action async setPlace(placeId: string) {
        this.searchStore.setSearchNearbyEnabled(false);

        const place = await this.placeService.loadPlace(placeId);
        const placeTypeLabel = await this.filterTypeStore.mapTypeToName(place.type);
        runInAction(() => {
            this.place = place;
            this.placeTypeLabel = placeTypeLabel;
        });

        this.logPlace();
    }

    @action clearPlace() {
        this.searchStore.setSearchNearbyEnabled(true);
        this.place = null;
    }

    @action async setUserMapLocation(location: LatLng) {
        this.userMapLocation = location;
    }

    @action setUserMapLocationStatus(status: ActionStatus) {
        this.userMapLocationStatus = status;
    }

    @action setSelectedMapLocation(location: LatLng) {
        this.selectedMapLocation = location;
        this.municipality = null;
        this.followUserPosition = false;
    }

    @action clearSelectedMapLocation() {
        if (this.mapLocationType === 'selected') {
            this.selectedMapLocation = null;
            this.mapboxService.locateUser({ skipFlyToLocation: true });
        }
    }

    @action setMapLocationState(state: MapLocationState): void {
        this.mapLocationState = state;
        if (state === 'error') {
            this.setUserMapLocationStatus('error');
        }
    }

    @action setMainMenuOpen(open: boolean) {
        this.mainMenuOpen = open;
    }

    @action async setMunicipality(municipalityId: string | null) {
        this.isMunicipalityLoading = false;

        if (municipalityId === this.municipality?.id) {
            return;
        }

        if (!municipalityId) {
            this.municipality = null;
            return;
        }

        if (municipalityCache[municipalityId]) {
            this.municipality = municipalityCache[municipalityId];

            // clear the previous location state
            this.followUserPosition = false;
            this.selectedMapLocation = null;
            return;
        }

        this.isMunicipalityLoading = true;

        const municipality = await this.api.getMunicipality(municipalityId);
        runInAction(() => {
            this.municipality = municipality;
            this.isMunicipalityLoading = false;

            // clear the previous location state
            this.followUserPosition = false;
            this.selectedMapLocation = null;
        });
        municipalityCache[municipality.id] = municipality;
    }

    @action clearMunicipality() {
        if (!this.municipality) { return; }

        this.municipality = null;
        this.isMunicipalityLoading = false;
    }

    private logPlace() {
        if (!this.place) {
            return;
        }

        this.analyticsLogger.track({
            type: 'ViewContent',
            data: {
                content_category: 'place',
                content_ids: [this.place.id],
            },
        });
    }

    private focusNearbyPlaces() {
        const locations: LatLng[] = [];

        // include the active location on the map
        if (this.activeMapLocation) {
            locations.push(this.activeMapLocation);
        }

        // fit the first three places on the map
        const pointsToZoomOn = this.searchStore.resultsNearby
            .slice(0, 3)
            .map((p) => p.location);
        locations.push(...pointsToZoomOn);
        this.mapboxService.flyToLocations(locations, this.mapStore.maxZoomLevel);
    }

    private focusActiveLocation() {
        if (!this.activeMapLocation) { return; }

        let zoom = 15;
        if (this.mapLocationType === 'none') {
            zoom = 7;
        } else if (this.municipality && this.mapLocationType === 'radnice') {
            zoom = Math.max(getZoomFromGeoJson(this.municipality.geoJSON), ZOOM_LEVELS_MAP.get('cities')!);
        }
        this.mapboxService.flyTo(this.activeMapLocation, zoom);
    }

    @action public focusMunicipalityLocation() {
        this.clearSelectedMapLocation();
        this.followUserPosition = false;
        this.focusActiveLocation();
    }

    @action public focusSelectedLocation() {
        this.followUserPosition = false;
        this.focusActiveLocation();
    }

    @action public focusUserLocation() {
        this.clearSelectedMapLocation();
        this.setUserMapLocationStatus('inProgress');
        this.municipality = null;
        this.followUserPosition = true;
        this.mapboxService.userPosition.subscribe((location) => {
            if (location && this.userMapLocation && locationsEqual(location, this.userMapLocation)) {
                return;
            }

            runInAction(() => {
                this.userMapLocation = location;
            });
            this.focusLocationsOfInterest();
        });
        this.mapboxService.locateUser();
    }

    public focusLocationsOfInterest() {
        // ignore locations of interest if place is focused
        if (this.place) { return; }

        if (this.canFocusNearbyPlaces && this.searchStore.resultsNearby.length > 0) {
            this.focusNearbyPlaces();
        } else {
            this.focusActiveLocation();
        }
    }

    public flyToDefaultPosition() {
        switch (this.mapLocationType) {
        case 'user':
            this.focusUserLocation();
            break;
        case 'radnice':
            this.focusMunicipalityLocation();
            break;
        case 'selected':
            this.focusSelectedLocation();
            break;
        default:
            throw Error('Unsupported location');
        }
    }

    private setupReactions() {
        reaction(() => this.mapLocationType,
            (mapLocationType) => {
                this.locationTypeProvider.setFromMapLocationType(mapLocationType);
            });

        reaction(() => [
            this.mapLocationType,
        ], () => {
            this.focusLocationsOfInterest();
        });

        reaction(() => [
            this.searchStore.resultsNearby,
        ], () => {
            if (this.searchStore.resultsNearby.length === 0) {
                return;
            }
            this.focusLocationsOfInterest();
        });

        reaction(() => [this.ready], () => {
            if (!this.activeMapLocation) { return; }
            this.searchStore.setLocation(this.activeMapLocation);
        });

        reaction(() => [
            this.activeMapLocation,
            this.mapLocationType],
        () => {
            if (!this.activeMapLocation) { return; }
            this.searchStore.setLocation(this.activeMapLocation);
        });

        // reaction on selected location in order to retrieve geocoded location on the location change
        reaction(() => this.activeMapLocation,
            async (loc) => {
                if (!loc) { return; }
                const r = await this.geocoder.reverseGeocode(loc, this.localeStore.locale);

                runInAction(() => {
                    this.selectedMapLocationGeocoded = r.label;
                });
            });

        reaction(() => this.selectedMapLocation,
            (loc) => {
                if (!loc) {
                    this.mapboxService.clearMapLocation();
                }
            });
    }
}
