import { LocationSearchItem } from '../../../shared/src/Core/Models/LocationSearchItem';

export function insertRecentItem(item: LocationSearchItem) {
    let recents = loadRecentItems();
    if (recents.find((i) => i.id === item.id)) {
        return;
    }
    recents.splice(0, 0, item);
    recents = recents.slice(0, 3);
    saveRecentItems(recents);
}

export function loadRecentItems(): LocationSearchItem[] {
    try {
        const data = localStorage.getItem('search-locations-recent');
        const recents: any = (data && JSON.parse(data)) ?? {};
        return Object.values(recents);
    } catch {
        return [];
    }
}

export function saveRecentItems(items: LocationSearchItem[]) {
    localStorage.setItem('search-locations-recent', JSON.stringify(items));
}
