import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';

import React from 'react';

import LayoutStore from '../../Stores/LayoutStore';
import { useStores } from '../../Stores/useStores';
import BetaBadge from '../Common/BetaBadge';

import MainLoader from './MainLoader';


type LayoutProps = {
    navigation?: React.ReactNode,
    content: React.ReactNode,
};

const LayoutWithoutMap: React.FC<LayoutProps> = ({ navigation, content }) => {
    const { layoutStore } = useStores();
    const classes = useStyles({
        useFixedNavigation: layoutStore!.size === 'small' && !layoutStore.mobilePanelFullscreen,
    });

    React.useEffect(() => {
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    const onWindowResize = () => {
        layoutStore.setSize(LayoutStore.calculateWindowSize());
    };

    switch (layoutStore.size) {
    case 'small':
        return renderMobile();
    default:
        return renderMobile();
    }

    function renderMobile() {
        return (
            <div className={ classes.mobileLayout }>
                <MainLoader />
                { navigation && (
                    <Box boxShadow={ 3 } className={ classes.mobileNavigation }>
                        { navigation }
                        { layoutStore.isMobile && (
                            <BetaBadge small />
                        ) }
                    </Box>
                ) }
				<div className={ classes.desktopLayout }>
				{ content }
				</div>
            </div>
        );
    }

    function renderDesktop() {
        return (
			<div>
				<MainLoader />
				{ navigation && (
					<Box boxShadow={ 3 } className={ classes.mobileNavigation }>
						{ navigation }
					</Box>
				) }
				<div className={ classes.desktopLayout }>
				{ content }
				</div>
			</div>
			);
    }
};

export default observer(LayoutWithoutMap);

const useStyles = makeStyles(() => createStyles({
    root: {},
    inner: {
        display: 'flex',
        flexDirection: 'column',
        pointerEvents: 'all',
        height: '100%',
    },
    content: {
        position: 'relative',
        flexGrow: 1,
    },
    contentWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    navigation: {
        display: 'flex',
    },
    desktopLayout: {
        display: 'flex',
        height: '100vh',
        width: '100%',
		position: 'relative',
		top: '90px',
    },
	mobileDetail: {
		position: 'relative',
		top: '50px',
	},
    mobileLayout: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mobileNavigation: (state: any) => ({
        pointerEvents: 'all',
        zIndex: 100,
        width: '100%',
        position: state.useFixedNavigation ? 'fixed' : 'relative',
    }),
}));
