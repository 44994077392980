import { History, LocationDescriptor, LocationDescriptorObject } from 'history';
import queryString from 'query-string';

type CreateHistory<O, H> = (options?: O) => History & H

function preserveQueryParameters(history: History, preserve: string[], location: LocationDescriptorObject): LocationDescriptorObject {
    const currentQuery = queryString.parse(history.location.search);
    if (currentQuery) {
        const preservedQuery: { [key: string]: unknown } = {};
        for (const p of preserve) {
            const v = currentQuery[p];
            if (v) {
                preservedQuery[p] = v;
            }
        }
        if (location.search) {
            Object.assign(preservedQuery, queryString.parse(location.search));
        }
        location.search = queryString.stringify(preservedQuery);
    }
    return location;
}

function createLocationDescriptorObject(location: LocationDescriptor, state?: History.LocationState): LocationDescriptorObject {
    if (typeof location === 'string') {
        const [pathname, search] = location.split('?');
        return { pathname, search, state };
    }

    return location;
}

function injectPreviousPathIntoLocationState(location: LocationDescriptor) {
    if (typeof location !== 'object') {
        throw Error('Location description must be an object');
    }

    const prevUrl = location.pathname !== window.location.pathname
        ? window.location.pathname
        : undefined;

    const state = { ...(location.state as any || {}), prevUrl };
    return { ...location, state };
}

export function createPreserveQueryHistory<O, H>(createHistory: CreateHistory<O, H>, queryParametersToPreserve: string[]): CreateHistory<O, H> {
    return (options?: O) => {
        const history = createHistory(options);
        const oldPush = history.push;
        const oldReplace = history.replace;
        history.push = (path: LocationDescriptor, state?: History.LocationState) => oldPush.apply(history, [preserveQueryParameters(history, queryParametersToPreserve, injectPreviousPathIntoLocationState(createLocationDescriptorObject(path, state)))]);
        history.replace = (path: LocationDescriptor, state?: History.LocationState) => oldReplace.apply(history, [preserveQueryParameters(history, queryParametersToPreserve, injectPreviousPathIntoLocationState(createLocationDescriptorObject(path, state)))]);
        return history;
    };
}
