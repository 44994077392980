
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
    useRouteMatch, Switch, Route, useLocation
} from 'react-router';
import LayoutWithoutMap from '../Components/Layout/LayoutWithoutMap';

import MainMenu from '../Components/HamburgerMenu';
import CategoriesNavigation from '../Components/Home/Categories/CategoriesNavigation';
import CategoriesView from '../Components/Home/Categories/CategoriesView';
import CategoryNearbyResultsPanel from '../Components/Home/CategoryNearbyResultsPanel';
import CategoryResultsNavigation from '../Components/Home/CategoryResultsNavigation';
import AboutView from '../Components/Home/MainPanel/AboutView';
import MainNavigation from '../Components/Home/MainPanel/MainNavigation';
import NearbyResultsView from '../Components/Home/MainPanel/NearbyResultsView';
import PlaceDetailNavigation from '../Components/Home/PlaceDetailNavigation';
import PlaceDetailPanel from '../Components/Home/PlaceDetails/PlaceDetailPanel';
import SearchLocationModal from '../Components/Home/SearchLocation/SearchLocationModal';
import SearchPlacesNavigation from '../Components/Home/SearchPlaces/SearchPlacesNavigation';
import SearchPlacesView from '../Components/Home/SearchPlaces/SearchPlacesView';

import NotFoundRoute from '../Components/NotFoundRoute';
import { useHomeNavigation } from '../Hooks/useHomeNavigation';
// import { useMapLocationQueryParams } from '../Hooks/useMapLocationQueryParams';
import { useStores } from '../Stores/useStores';
import { trimPathEnd } from '../utils/trimPath';

const ErrorMessages = defineMessages({
    notFound: {
        id: 'ERROR_404',
        defaultMessage: 'Hledaná stránka nenalezena',
    },
});

const HomePageWithoutMap: React.FC = () => {

    // useMapLocationQueryParams();
    const intl = useIntl();
    const { path } = useRouteMatch<{ municipalityId: string }>();
    const location = useLocation<{ error404: boolean }>();
    const { enqueueSnackbar } = useSnackbar();

    // const { current: mapboxService } = useRef(IocContainer.get<IMapboxService>(IMapboxService_TYPE));
    const {
        homeStore, filterTypeStore, searchStore, mapStore, layoutStore,
    } = useStores();
    const { navigateToPlace, navigateToListing } = useHomeNavigation();

    const [isInitialized, setIsInitialized] = useState(false);
    const isReady = isInitialized;

    useEffect(() => {
        layoutStore.startLoading();

        filterTypeStore.load();

        setIsInitialized(true);

        return () => {
            layoutStore.finishLoading();
        };
    }, []);

    // handle errors
    useEffect(() => {
        if (location.state?.error404) {
            enqueueSnackbar(intl.formatMessage(ErrorMessages.notFound), { variant: 'error' });
        }
    }, [location.state]);

    useEffect(() => {
        if (isReady) {


			layoutStore.finishLoading();
			homeStore.setReady();

            return () => {
                layoutStore.finishLoading();
            };
        }
    }, [isReady]);

    useEffect(() => {
        // handle navigation back to listing from the place detail when a location was selected on the map
        if (homeStore.mapLocationType === 'selected' && homeStore.place) {
            navigateToListing();
        }
    }, [homeStore.mapLocationType, homeStore.selectedMapLocation]);

    const rootPath = trimPathEnd(path);
    return (
        <div className="HomePage">
            <LayoutWithoutMap
                navigation={ (
                    <Switch>
                        <Route path={ `${rootPath}/` } exact component={ MainNavigation } />
                        <Route path={ `${rootPath}/about` } exact component={ MainNavigation } />
                        <Route path={ `${rootPath}/places/search` } exact component={ SearchPlacesNavigation } />
                        <Route path={ `${rootPath}/places/:placeId` } component={ PlaceDetailNavigation } />
                        <Route path={ `${rootPath}/browse/:categoryId` }>
                            <Route path={ `${rootPath}/browse/:categoryId` } exact component={ CategoriesNavigation } />
                            <Route path={ `${rootPath}/browse/:categoryId/search/:searchTerm` } component={ CategoriesNavigation } />
                        </Route>
                        <Route path={ `${rootPath}/categories/:categoryId` }>
                            <Route path={ `${rootPath}/categories/:categoryId` } exact component={ CategoryResultsNavigation } />
                            <Route path={ `${rootPath}/categories/:categoryId/places/:placeId` } component={ PlaceDetailNavigation } />
                            <Route path={ `${rootPath}/categories/:categoryId/products/:productId` }>
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId` } exact component={ CategoryResultsNavigation } />
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId/places/:placeId` } component={ PlaceDetailNavigation } />
                            </Route>
                        </Route>
                    </Switch>
                ) }
                content={ (
                    <Switch>
                        <Route path={ `${rootPath}/` } exact component={ NearbyResultsView } />
                        <Route path={ `${rootPath}/about` } exact component={ AboutView } />
                        <Route path={ `${rootPath}/places/search` } exact component={ SearchPlacesView } />
                        <Route path={ `${rootPath}/places/:placeId` } component={ PlaceDetailPanel } />
                        <Route path={ `${rootPath}/browse/:categoryId` }>
                            <Route path={ `${rootPath}/browse/:categoryId` } exact component={ CategoriesView } />
                            <Route path={ `${rootPath}/browse/:categoryId/search/:searchTerm` } component={ CategoriesView } />
                        </Route>
                        <Route path={ `${rootPath}/categories/:categoryId` }>
                            <Route path={ `${rootPath}/categories/:categoryId` } exact component={ CategoryNearbyResultsPanel } />
                            <Route path={ `${rootPath}/categories/:categoryId/places/:placeId` } component={ PlaceDetailPanel } />
                            <Route path={ `${rootPath}/categories/:categoryId/products/:productId` }>
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId` } exact component={ CategoryNearbyResultsPanel } />
                                <Route path={ `${rootPath}/categories/:categoryId/products/:productId/places/:placeId` } component={ PlaceDetailPanel } />
                            </Route>
                        </Route>
                        <NotFoundRoute />
                    </Switch>
                ) }
            />
            <MainMenu />
            <SearchLocationModal />
        </div>
    );


};

export default observer(HomePageWithoutMap);
