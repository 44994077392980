import { Icon } from '@material-ui/core';
import React from 'react';

export type ImageIconProps = {
    alt: string;
    height?: number;
    src: string;
    className?: string;
};

const ImageIcon: React.FC<ImageIconProps> = ({
    height, alt, src, className,
}) => (
    <Icon style={ { height, width: height, lineHeight: height ? `${height + 4}px` : undefined } } className={ className }>
        <img
            src={ src }
            alt={ alt }
            height={ height ? `${height}px` : undefined }
        />
    </Icon>
);

export default ImageIcon;
