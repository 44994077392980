import {
    Button, Grid, Input, TextField, Typography
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { UserService } from '@shared/Core/Services/UserService';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import AccountIcon from '../domain/login/components/AccountIcon';
import UserAvatar from '../domain/login/components/UserAvatar';
import { useService } from '../Hooks/useService';
import { userInputMessages } from '../messages/userInputMessages';
import { useStores } from '../Stores/useStores';

type UserProfileViewProps = {
    onSave: () => void;
};

const UserProfileView: React.FC<UserProfileViewProps> = ({ onSave }) => {
    const intl = useIntl();
    const { authStore } = useStores();
    const {
        register, handleSubmit, watch, errors,
    } = useForm();
    const classes = useStyles();
    const userSvc = useService(UserService);

    const onSubmit = async (data: any) => {
        console.log('submit', data);

        const user = await userSvc.updateUserDetails(data);
        authStore.updateUser(user);

        onSave();
    };

    const { user } = authStore;
    if (!user) {
        throw Error('User not authenticated.');
    }

    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid container>
                <Grid item xs={ 3 }>
                    <UserAvatar user={ user } />
                </Grid>
                <Grid item xs={ 9 }>
                    { user.email }
                </Grid>
                <Grid item xs={ 12 } className={ classes.field }>
                    <TextField
                        name="nickname"
                        label={ intl.formatMessage(userInputMessages.inputNicknameLabel) }
                        defaultValue={ user.nickname }
                        inputRef={ register }
                    />
                </Grid>
                <Grid item xs={ 6 } className={ classes.field }>
                    <TextField
                        name="firstName"
                        label={ intl.formatMessage(userInputMessages.inputFirstNameLabel) }
                        defaultValue={ user.firstName }
                        inputRef={ register }
                    />
                </Grid>
                <Grid item xs={ 6 } className={ classes.field }>
                    <TextField
                        name="lastName"
                        label={ intl.formatMessage(userInputMessages.inputLastNameLabel) }
                        defaultValue={ user.lastName }
                        inputRef={ register }
                    />
                </Grid>
            </Grid>

            <div className={ classes.submitContainer }>
                <Button type="submit" color="primary" variant="contained">
                    <FormattedMessage
                        id="USER_PROFILE_SAVE_BUTTON"
                        defaultMessage="Uložit"
                    />
                </Button>
            </div>
        </form>
    );
};

export default observer(UserProfileView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    field: {
        margin: theme.spacing(2, 0),
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));
