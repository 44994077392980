import { Typography, Button } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PinIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import UnknownLocationIcon from '@material-ui/icons/NotListedLocation';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import TruncateMarkup from 'react-truncate-markup';

import { useStores } from '../../Stores/useStores';

const LocationPickerMessages = defineMessages({
    userLocation: {
        id: 'LOCATION_PICKER_LOCATION_USER',
        defaultMessage: 'moje poloha',
    },
    selectedLocation: {
        id: 'LOCATION_PICKER_LOCATION_SELECTED',
        defaultMessage: '{ address }',
    },
    municipalityLocation: {
        id: 'LOCATION_PICKER_LOCATION_MUNICIPALITY',
        defaultMessage: '{ municipality }',
    },
    unavailableLocation: {
        id: 'LOCATION_PICKER_LOCATION_UNAVAILABLE',
        defaultMessage: 'čekám na polohu...',
    },
    unknownLocation: {
        id: 'LOCATION_PICKER_LOCATION_UKNOWN',
        defaultMessage: 'neznámá poloha',
    },
});

type LocationInfoProps = {
};

const LocationPicker: React.FC<LocationInfoProps> = observer(() => {
    const intl = useIntl();
    const classes = useStyles();
    const { homeStore, searchMunicipalityStore } = useStores();

    const {
        selectedMapLocationGeocoded, mapLocationType, municipality,
    } = homeStore;

    const handleAction = () => {
        searchMunicipalityStore.openModal();
    };

    return (
        <Button
            onClick={ handleAction }
            variant="text"
            color="primary"
            className={ classes.button }
            classes={ {
                startIcon: classes.startIcon,
            } }
            startIcon={ renderIcon() }
            endIcon={ <ArrowDropDownIcon /> }
        >
            <TruncateMarkup lines={ 1 }>
                <Typography component="div" align="center">{ getLocationLabel() }</Typography>
            </TruncateMarkup>
        </Button>
    );

    function getLocationLabel() {
        switch (mapLocationType) {
        case 'user':
        case 'selected':
            return intl.formatMessage(LocationPickerMessages.selectedLocation, {
                address: selectedMapLocationGeocoded
                    ?? intl.formatMessage(LocationPickerMessages.unknownLocation),
            });
        case 'radnice':
            return intl.formatMessage(LocationPickerMessages.municipalityLocation, {
                municipality: municipality?.nameShort,
            });
        default:
            return intl.formatMessage(LocationPickerMessages.unavailableLocation);
        }
    }

    function renderIcon() {
        switch (mapLocationType) {
        case 'user':
            return <MyLocationIcon />;
        case 'selected':
            return <PinIcon />;
        case 'radnice':
            return <LocationCityIcon />;
        default:
            return <UnknownLocationIcon />;
        }
    }
});

export default LocationPicker;

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        borderRadius: 48,
        padding: theme.spacing(0.5, 2),
        paddingLeft: 20,
    },
    startIcon: {
        paddingBottom: 3,
    },
}));
