export const languages = {
    cs: {
        locale: 'cs',
        label: 'Česky',
        image: '/assets/lang-czech.svg',
    },
    sk: {
        locale: 'sk',
        label: 'Slovenský',
        image: '/assets/lang-slovakia.svg',
    },
    en: {
        locale: 'en',
        label: 'English',
        image: '/assets/lang-english.svg',
    },
    ht: {
        locale: 'ht',
        label: 'Hantec',
        image: '/assets/lang-hantec.png',
    },
    // de: {
    //     locale: 'de',
    //     label: 'DE',
    // },
    // pl: {
    //     locale: 'pl',
    //     label: 'PL',
    // },
};

export const FIREBASE_LANG_MAP = {
    cs: 'cs', en: 'en', sk: 'sk', ht: 'cs', default: 'cs',
};

export const languagesRouteRegex = Object.keys(languages).join('|');

export const defaultLocale = 'cs';
