import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CategoryStore from '@shared/Core/Stores/CategoryStore';
import HomeStore from '@shared/Core/Stores/HomeStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useStores } from '../../Stores/useStores';
import BackButton from '../Common/BackButton';
import CloseButton from '../Common/CloseButton';
import EmptyButton from '../Common/EmptyButton';
import ResponsiveToolbar from '../Layout/ResponsiveToolbar';

type PlaceDetailNavigationProps = {
    homeStore?: HomeStore;
    categoryStore?: CategoryStore;
};

const PlaceDetailNavigation: React.FC<PlaceDetailNavigationProps> = () => {
    const { homeStore, categoryStore } = useStores();
    const { navigateBack, navigateHome, navigateToCategoryBrowse } = useHomeNavigation();
    const classes = useStyles();

    const onBackButtonClick = () => navigateBack();
    const onCloseButtonClicked = () => navigateHome();
    const onTitleClicked = () => navigateToCategoryBrowse('all');

    return (
        <div className={ classes.root }>
            <ResponsiveToolbar>
                <>
                    <BackButton onClick={ onBackButtonClick } />
                    <Typography variant="subtitle1" className={ classes.title }  onClick={ onTitleClicked }>
                        {
                            categoryStore.selectedCategory
                                ? categoryStore.selectedTitle
                                : (homeStore.place && (
                                    <FormattedMessage
                                        id="PLACE_DETAIL_NAVIGATION_TITLE"
                                        defaultMessage="Detail místa"
                                    />
                                ))
                        }
                    </Typography>
                    { /* <Typography variant="subtitle1" className={classes.title}>{(!!homeStore.place && homeStore.place.name) || ''}</Typography>
                    <FilterButton /> */ }
                    { categoryStore.selectedCategory
                        ? (
                            <CloseButton onClick={ onCloseButtonClicked } />
                        ) : (
                            <EmptyButton />
                        ) }
                </>
            </ResponsiveToolbar>
        </div>
    );
};

export default observer(PlaceDetailNavigation);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        // padding: theme.spacing(1, 1)
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',
    },
}));
