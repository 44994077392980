import {
    Link, createStyles, makeStyles, Theme
} from '@material-ui/core';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import { useRootNavigation } from '../../../../Hooks/useRootNavigation';

import EmailSingInForm, { EmailSignInFormOptions } from './EmailSignInForm';
import { signInMessages } from '../../messages/signInMessages';

type EmailSignInProps = {
    onSubmit: (options: EmailSignInFormOptions) => void;
};

const EmailSingIn: React.FC<EmailSignInProps> = ({
    onSubmit,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const nav = useRootNavigation();

    const handleSubmitEvent = async (data: any) => {
        console.log('submit', data);
        onSubmit(data);
    };

    return (
        <div className={ classes.root }>
            <EmailSingInForm
                submitButtonTitle={ intl.formatMessage(signInMessages.emailSignInButton) }
                onSubmit={ handleSubmitEvent }
            />
            <div className={ classes.resetPasswordLink }>
                <Link to={ nav.getLinkTo('signIn/resetPassword') } component={ RouterLink }>
                    <FormattedMessage
                        id="SIGNIN_EMAIL_FRGOT_PASSWORD_BUTTON"
                        defaultMessage="Zapomenuté heslo"
                    />
                </Link>
            </div>
        </div>
    );
};

export default EmailSingIn;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    resetPasswordLink: {
        marginTop: theme.spacing(1.5),
        alignSelf: 'center',
    },
}));
