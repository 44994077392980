import { TextField } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REGEX_VALIDATION_EMAIL } from '../../../../constants/validationRegex';
import { userInputValidationMessages } from '../../../../messages/userInputValidationMessages';
import { useValidationMessages } from '../../../../messages/useValidationMessages';

import { signInMessages } from '../../messages/signInMessages';

type EmailInputProps = {
    disableAutocomplete?: boolean;
    form: UseFormMethods<Record<string, any>>;
    initialValue?: string;
};

const EmailInput: React.FC<EmailInputProps> = ({
    form,
    disableAutocomplete,
    initialValue,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const { register } = form;
    const { getTextFieldErrorProps: getErrorProps } = useValidationMessages(userInputValidationMessages, form);

    return (
        <TextField
            required
            autoComplete={ disableAutocomplete ? 'disabled' : undefined }
            disabled={ !!initialValue }
            className={ classes.root }
            name="email"
            label={ intl.formatMessage(signInMessages.inputEmailLabel) }
            defaultValue={ initialValue ?? '' }
            { ...getErrorProps('email') }
            inputRef={ register({ required: true, pattern: REGEX_VALIDATION_EMAIL }) }
        />
    );
};

export default EmailInput;

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
    },
}));
