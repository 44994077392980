import { injectable } from 'inversify';
import * as uuidv4 from 'uuid/v4';

import { ApiOptions } from '../ApiOptions';
import IocContainer from '../IocContainer';
import ResponseError from '../Utils/ResponseError';

import IAuthService, { IAuthService_TYPE } from './IAuthService';
import IMapboxService, { IMapboxService_TYPE } from './IMapboxService';
import { LocationTypeProvider } from './LocationTypeProvider';

const __ENABLE_DATA_LOGGING__ = false;

@injectable()
export default class HttpService {
    private mapboxService = IocContainer.get<IMapboxService>(IMapboxService_TYPE);

    private authService = IocContainer.get<IAuthService>(IAuthService_TYPE);

    private locationTypeProvider = IocContainer.get(LocationTypeProvider);

    public sessionId: string = uuidv4.default();

    private apiOptions = IocContainer.get(ApiOptions);

    private get defaultHeaders() {
        return {
            'X-KAMSNIM-SESSION': this.sessionId,
            'X-KAMSNIM-LOCATION-TYPE': this.locationTypeProvider.locationType,
            'X-KAMSNIM-LOCATION-ACCURACY': this.mapboxService.locationAccuracy,
            'X-KAMSNIM-LANG': this.apiOptions.locale,
            'X-KAMSNIM-APP': this.apiOptions.hostApp,
        };
    }

    private async authenticateRequest(headers: any) {
        const accessToken = await this.authService.getToken();
        if (!accessToken) {
            return;
        }

        headers.Authorization = `Bearer ${accessToken}`;
    }

    public async get<T>(endpointUrl: string, options?: { params?: URLSearchParams }): Promise<T> {
        let url = endpointUrl;
        if (options) {
            if (options.params) {
                url += `?${options.params.toString()}`;
            }
        }

        try {
            console.debug('ApiService::get', url, options);
            const headers: any = {
                ...this.defaultHeaders,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            await this.authenticateRequest(headers);

            const response = await fetch(url, {
                headers,
            });

            if (__ENABLE_DATA_LOGGING__) {
                console.debug('ApiService::get', response);
            }
            if (!response.ok) {
                throw new ResponseError(response, await response.text());
            }

            const data = (await response.json()) as T;
            return data;
        } catch (err) {
            console.debug('ApiService::get::ERROR', err);
            throw err;
        }
    }

    public async post<T, TResult>(endpointUrl: string, data: T, options?: { params?: URLSearchParams }): Promise<TResult> {
        let url = endpointUrl;
        if (options) {
            if (options.params) {
                url += `?${options.params.toString()}`;
            }
        }

        try {
            console.debug('ApiService::post', url, options);

            const headers: any = {
                ...this.defaultHeaders,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            await this.authenticateRequest(headers);

            const response = await fetch(url, {
                method: 'POST',
                headers,
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data),
            });

            if (__ENABLE_DATA_LOGGING__) {
                console.debug('ApiService::post', response);
            }
            if (!response.ok) {
                throw new ResponseError(response, await response.text());
            }

            const responseData = (await response.json()) as TResult;
            return responseData;
        } catch (err) {
            console.debug('ApiService::post::ERROR', err);
            throw err;
        }
    }

    public async put<T, TResult>(endpointUrl: string, data: T, options?: { params?: URLSearchParams }): Promise<TResult> {
        let url = endpointUrl;
        if (options) {
            if (options.params) {
                url += `?${options.params.toString()}`;
            }
        }

        try {
            console.debug('ApiService::put', url, options);

            const headers: any = {
                ...this.defaultHeaders,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };

            await this.authenticateRequest(headers);

            const response = await fetch(url, {
                method: 'PUT',
                headers,
                redirect: 'follow', // manual, *follow, error
                referrer: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data),
            });

            if (__ENABLE_DATA_LOGGING__) {
                console.debug('ApiService::put', response);
            }
            if (!response.ok) {
                throw new ResponseError(response, await response.text());
            }

            const responseData = (await response.json()) as TResult;
            return responseData;
        } catch (err) {
            console.debug('ApiService::put::ERROR', err);
            throw err;
        }
    }
}
