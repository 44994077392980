import IEnvironment, { IEnvironment_TYPE } from '@shared/Core/IEnvironment';
import IocContainer from '@shared/Core/IocContainer';
import firebase from 'firebase/app';
import { injectable } from 'inversify';
import 'firebase/analytics';
import 'firebase/performance';

@injectable()
export default class FirebaseService {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    public initialize() {
        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: this.environment.firebase.apiKey,
            projectId: this.environment.firebase.projectId,
            appId: this.environment.firebase.appId,
            measurementId: this.environment.firebase.measurementId,
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);
        firebase.analytics();
    }

    public get firebase() { return firebase; }

    // public test() {
    //     const t = firebase.performance().trace('sample');
    //     t.start();
    //     setTimeout(() => {
    //         t.stop();
    //     }, 1000);
    // }
}
