import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import CategoryMenuItem from '../Models/CategoryMenuItem';

import ConfigurationService from './ConfigurationService';

@injectable()
export default class CategoryMenuService {
    private configurationService = IocContainer.get(ConfigurationService);

    public async getMenuItems(): Promise<CategoryMenuItem[]> {
        const config = await this.configurationService.getConfiguration();
        const { categoriesMenu } = config;

        return categoriesMenu;
    }
}
