// import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

type AspectRatioProps = {
    ratio: number;
};

const AspectRatio: React.FC<AspectRatioProps> = (props) => {
    const { ratio, children, ...rest } = props;
    const classes = useStyles({ ratio });
    return (
        <div { ...rest } className={ classes.root } style={ { paddingTop: `${ratio * 100}%` } }>
            <div className={ classes.wrapper }>
                <div className={ classes.inner }>
                    { children }
                </div>
            </div>
        </div>
    );
};

export default AspectRatio;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        // paddingTop: '56.25%', /* 16:9 Aspect Ratio */
        position: 'relative',
        overflow: 'hidden',
    },
    wrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: -1,
    },
    inner: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
}));
