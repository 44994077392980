import {
    Typography, List, ListItem, ListItemText, ListItemAvatar, Box
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CategorySearchResult from '@shared/Core/Models/CategorySearchResult';
import CategoryStore from '@shared/Core/Stores/CategoryStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import LayoutStore from '../../../Stores/LayoutStore';
import { useStores } from '../../../Stores/useStores';

type CategoriesResultsProps = {
    categoryStore?: CategoryStore;
    layoutStore?: LayoutStore;
};

const CategoriesResults: React.FC<CategoriesResultsProps> = () => {
    const { navigateToCategoryResults, navigateToCategoryProductResults } = useHomeNavigation();
    const { categoryStore } = useStores();
    const classes = useStyles();

    const onItemClick = (result: CategorySearchResult) => () => {
        switch (result.type) {
        case 'category':
            navigateToCategoryResults(result.categoryId);
            break;
        case 'product':
            navigateToCategoryProductResults(result.categoryId, result.productId!);
            break;
        default:
            throw Error('ivalid result type');
        }
    };

    return (
        <List>
            { categoryStore.searchResults.length > 0
                ? categoryStore.searchResults.map((result) => (
                    <ListItem key={ result.id } button onClick={ onItemClick(result) } className={ classes.item }>
                        <ListItemAvatar>
                            <Box className={ classes.itemImageContainer } boxShadow={ 2 }>
                                <img src={ result.icon } height="64px" alt={ result.name } />
                            </Box>
                        </ListItemAvatar>
                        <ListItemText
                            className={ classes.itemContent }
                            primary={ (
                                <Typography component="div">{ result.name }</Typography>
                            ) }
                        />
                    </ListItem>

                ))
                : (
                    <div className={ classes.emptyResultsHolder }>
                        <FormattedMessage
                            id="CATEGORIES_RESULTS_EMPTY"
                            defaultMessage="Zadanému názvu neodpovídá žádná kategorie ani produkt."
                        />
                    </div>
                ) }
        </List>
    );
};

export default observer(CategoriesResults);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    item: {
        padding: theme.spacing(2, 2),
    },
    itemContent: {
        paddingLeft: theme.spacing(2),
    },
    itemImageContainer: {
        display: 'flex',
        overflow: 'hidden',
        borderRadius: '8px',
    },
    emptyResultsHolder: {
        textAlign: 'center',
    },
}));
