import { RegionType, LatLng, Bounds } from '../Types';

export interface SearchMapCriteria {
    categoryId?: string;
    productId?: string;
    types?: string[];
    zoom?: number;
    bounds?: Bounds;
    location?: LatLng;
    region?: RegionType;
    date?: Date;
    time?: string;
    offset?: number;
    limit?: number;
}

export const DEFAULT_SEARCH_CRITERIA = {};

export type SearchMapPlacesCriteria = Pick<SearchMapCriteria,
    | 'categoryId'
    | 'types'
    | 'bounds'
    | 'date'
    | 'time'
>;

export type SearchMapRegionsCriteria = Pick<SearchMapCriteria,
    | 'categoryId'
    | 'types'
    | 'region'
>;

export type SearchMapCitiesCriteria = Pick<SearchMapCriteria,
    | 'categoryId'
    | 'types'
    | 'bounds'
>;

export type SearchMapNearbyCriteria = Pick<SearchMapCriteria,
    | 'location'
    | 'categoryId'
    | 'types'
    | 'date'
    | 'time'
    | 'offset'
    | 'limit'
>;
