import {
    IconButton, Fab
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import ResponsiveModal from '../Layout/ResponsiveModal';
import LegendView from '../LegendView';

import AssetIcon from './AssetIcon';

const LegendModalMessages = defineMessages({
    title: {
        id: 'LEGEND_TITLE',
        defaultMessage: 'Legenda',
    },
});

type LegendButtonProps = {
    className?: string;
    fab?: boolean,
};

const LegendButton: React.FC<LegendButtonProps> = observer(({ className, fab }) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleModalClosed = () => {
        setOpen(false);
    };

    return (
        <>
            { fab
                ? (
                    <Fab
                        size="medium"
                        color="inherit"
                        onClick={ handleClick }
                        className={ className }
                        aria-label="legend"
                    >
                        <AssetIcon name="legend.svg" height={ 28 } />
                    </Fab>
                )
                : (
                    <IconButton onClick={ handleClick } className={ className } color="inherit" aria-label="legend">
                        <AssetIcon name="legend.svg" height={ 28 } />
                    </IconButton>
                ) }
            <ResponsiveModal open={ open } title={ intl.formatMessage(LegendModalMessages.title) } onClose={ handleModalClosed }>
                <LegendView />
            </ResponsiveModal>
        </>
    );
});

export default LegendButton;
