import IocContainer, { IIocContainer } from '@shared/Core/IocContainer';
import CategoryStore from '@shared/Core/Stores/CategoryStore';
import FeedbackStore from '@shared/Core/Stores/FeedbackStore';
import FilterTypeStore from '@shared/Core/Stores/FilterTypeStore';
import HomeStore from '@shared/Core/Stores/HomeStore';
import { MapStore } from '@shared/Core/Stores/MapStore';
import SearchPlacesStore from '@shared/Core/Stores/SearchPlacesStore';
import SearchStore from '@shared/Core/Stores/SearchStore';
import { createContext } from 'react';

import { AuthStore } from '../../../shared/src/Core/Stores/AuthStore';
import { ILocaleStore_TYPE } from '../../../shared/src/Core/Stores/ILocaleStore';
import { SearchLocationStore } from '../../../shared/src/Core/Stores/SearchLocationStore';
import { SignInStore } from '../domain/login/stores/SignInStore';

import { AppStore } from './AppStore';
import LayoutStore from './LayoutStore';
import LocaleStore from './LocaleStore';

export type Stores = {
    appStore: AppStore,
    authStore: AuthStore,
    searchStore: SearchStore,
    categoryStore: CategoryStore,
    searchPlacesStore: SearchPlacesStore,
    searchMunicipalityStore: SearchLocationStore,
    homeStore: HomeStore,
    filterTypeStore: FilterTypeStore,
    layoutStore: LayoutStore,
    feedbackStore: FeedbackStore,
    mapStore: MapStore,
    localeStore: LocaleStore,
    signInStore: SignInStore,
};

export function buildStores(container: IIocContainer): Stores {
    return {
        appStore: container.get(AppStore),
        authStore: container.get(AuthStore),
        searchStore: container.get(SearchStore),
        categoryStore: container.get(CategoryStore),
        searchPlacesStore: container.get(SearchPlacesStore),
        searchMunicipalityStore: container.get(SearchLocationStore),
        homeStore: container.get(HomeStore),
        filterTypeStore: container.get(FilterTypeStore),
        layoutStore: container.get(LayoutStore),
        feedbackStore: container.get(FeedbackStore),
        mapStore: container.get(MapStore),
        localeStore: container.get(ILocaleStore_TYPE),
        signInStore: container.get(SignInStore),
    };
}

export const RootStoreContext = createContext(buildStores(IocContainer));
