import { injectable } from 'inversify';
import { observable, action, runInAction, makeObservable } from 'mobx';

import { ApiOptions } from '../../../shared/src/Core/ApiOptions';
import IocContainer from '../../../shared/src/Core/IocContainer';
import { defaultLocale } from '../languages';
import { IntlService } from '../Services/IntlService';

@injectable()
export default class LocaleStore {
    private apiOptions = IocContainer.get(ApiOptions);

    private intlService = IocContainer.get(IntlService);

    @observable public locale = defaultLocale;

    @observable public messages: Record<string, string> = {};

    @observable public isSet = false;

    constructor() {
        makeObservable(this);
    }

    @action async setLocale(locale: string) {
        this.isSet = false;
        const m = await this.intlService.loadMessages(locale);
        runInAction(() => {
            this.apiOptions.locale = locale;
            this.locale = locale;
            this.messages = m;
            this.isSet = true;
        });
    }
}
