import { defineMessages } from 'react-intl';

export const authErrorMessages = defineMessages({
    'auth/user-not-found': {
        id: 'AUTH_ERROR_USERNOTEFOUND',
        defaultMessage: 'Zadaný uživatel neexistuje',
    },
    'auth/wrong-password': {
        id: 'AUTH_ERROR_WRONGPASSWORD',
        defaultMessage: 'Zadané heslo je špatně',
    },
    'auth/email-already-in-use': {
        id: 'AUTH_ERROR_EMAILALREADYUSED',
        defaultMessage: 'Zadaný email je již obsazen',
    },
    'auth/weak-password': {
        id: 'AUTH_ERROR_WEAKPASSWORD',
        defaultMessage: 'Zadané heslo je příliš slabé',
    },
    'auth/invalid-credential': {
        id: 'AUTH_ERROR_INVALIDCREDENTIAL',
        defaultMessage: 'Nebylo možné získat platné přihlašovací údaje.',
    },
    unknown: {
        id: 'AUTH_ERROR_UNKNOWN',
        defaultMessage: 'Nastala chyba při přihlášení. Pokud potíže přetrvávají, prosím kontaktujte nás.',
    },
});
