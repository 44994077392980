import { Fab } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import DirectionsIcon from '@material-ui/icons/Directions';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { LatLng } from '@shared/Core/Types';
import React from 'react';

import GoogleMapsHelper from '../../utils/GoogleMapsHelper';
import { openLinkInNewTab } from '../../utils/WebHelpers';

type NavigationFabProps = {
    fromLocation: LatLng | null,
    place: PlaceInfo;
};

const NavigationFab: React.FC<NavigationFabProps> = (props) => {
    const classes = useStyles();

    const onNavigateClicked = () => {
        if (!props.place) { return; }

        openLinkInNewTab(GoogleMapsHelper.getNavigationLink(props.place.location, props.fromLocation));
    };

    return (
        <Fab color="primary" className={ classes.root } onClick={ onNavigateClicked }>
            <DirectionsIcon />
        </Fab>
    );
};

export default NavigationFab;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        zIndex: 200,
        right: theme.spacing(1),
        top: -30,
    },
}));
