import { injectable } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';

import IocContainer from '../IocContainer';
import Feedback from '../Models/Feedback';
import ApiService from '../Services/ApiService';
import { ActionStatus, FeedbackType } from '../Types';

@injectable()
export default class FeedbackStore {
    private api = IocContainer.get(ApiService);

    @observable public type: FeedbackType | null = null;

    @observable public content: string = '';

    @observable public email: string = '';

    @observable public feedbackActive: boolean = false;

    @observable public submitStatus: ActionStatus = 'initial';

    constructor() {
        makeObservable(this);
    }

    @action startNewFeedback() {
        this.type = null;
        this.content = '';
        this.email = '';
        this.submitStatus = 'initial';
        this.feedbackActive = true;
    }

    @action cancelFeedback() {
        this.feedbackActive = false;
    }

    @action setType(type: FeedbackType) {
        this.type = type;
    }

    @action setContent(content: string) {
        this.content = content;
    }

    @action setEmail(email: string) {
        this.email = email;
    }

    @action async submitFeedback() {
        this.submitStatus = 'inProgress';

        try {
            const feedback: Feedback = {
                type: this.type!,
                content: this.content,
                email: this.email,
            };
            await this.api.postFeedback(feedback);
            runInAction(() => {
                this.submitStatus = 'done';
            });
        } catch (err) {
            this.submitStatus = 'error';
        }
    }
}
