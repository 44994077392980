export default interface IEnvironment {
    isProd: boolean;
    api: {
        host: string
    },
    mapboxKey: string,
    mapTilerKey: string,
    facebookPixelId: string,
    firebase: {
        projectId: string,
        apiKey: string,
        appId: string,
        measurementId: string
    },
    firebaseAuth: {
        projectId: string,
        apiKey: string,
        appId: string,
        authDomain: string
    },
    versionHash: string,
}

export const IEnvironment_TYPE = Symbol.for('IEnvironment');
