import {
    Button, Grid, TextField, Typography
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { PlaceInfo } from '../../../../shared/src/Core/Models/PlaceInfo';
import ApiService from '../../../../shared/src/Core/Services/ApiService';
import { useService } from '../../Hooks/useService';
import PlaceIcon from '../Place/PlaceIcon';

const PlaceFeedbackMessages = defineMessages({
    description: {
        id: 'PLACE_FEEDBACK_DESCRIPTION',
        defaultMessage: 'Prosím, popište jaké údaje jsou nesprávné a případně doplňte jak je třeba je opravit.',
    },
    messageInputLabel: {
        id: 'PLACE_FEEDBACK_MESSAGE_LABEL',
        defaultMessage: 'Vaše zpráva',
    },
    submitTitle: {
        id: 'PLACE_FEEDBACK_SUBMIT_TITLE',
        defaultMessage: 'Odeslat',
    },
    thankYouMessage: {
        id: 'PLACE_FEEDBACK_THANKYOU',
        defaultMessage: 'Děkujeme za zpětnou vazbu!',
    },
});

type PlaceFeedbackViewProps = {
    place: PlaceInfo;
    onSubmit: () => void;
};

const PlaceFeedbackView: React.FC<PlaceFeedbackViewProps> = ({
    place,
    onSubmit,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const form = useForm({
        mode: 'onChange',
    });
    const {
        register, handleSubmit, getValues,
    } = form;
    const api = useService(ApiService);

    const handleSubmitEvent = async (data: any) => {
        api.postPlaceFeedback({
            placeId: place.idPoint,
            name: place.name,
            address: place.address,
            content: data.message,
            url: window.location.href,
        });
        enqueueSnackbar(intl.formatMessage(PlaceFeedbackMessages.thankYouMessage), { variant: 'success' });
        onSubmit();
    };

    const messageEmpty = !getValues('message');

    return (
        <form onSubmit={ handleSubmit(handleSubmitEvent) } noValidate>
            <Grid container className={ classes.root }>
                <Grid item xs={ 12 } className={ classes.titleContainer }>
                    <PlaceIcon placeType={ place.type } size="56px" />
                    <Typography variant="h5" className={ classes.title }>{ place.name }</Typography>
                </Grid>
                <Grid item xs={ 12 } className={ classes.field }>
                    <Typography variant="body1">{ intl.formatMessage(PlaceFeedbackMessages.description) }</Typography>
                </Grid>
                <Grid item xs={ 12 } className={ classes.field }>
                    <TextField
                        autoFocus
                        required
                        rows="4"
                        rowsMax="8"
                        multiline
                        autoComplete="disabled"
                        className={ classes.input }
                        name="message"
                        label={ intl.formatMessage(PlaceFeedbackMessages.messageInputLabel) }
                        defaultValue=""
                        // { ...getTextFieldErrorProps('password') }
                        inputRef={ register({ required: true }) }
                    />
                </Grid>
                <Grid item xs={ 12 } className={ classes.submitButton }>
                    <Button
                        disabled={ messageEmpty }
                        variant="contained"
                        color="secondary"
                        fullWidth
                        type="submit"
                    >
                        { intl.formatMessage(PlaceFeedbackMessages.submitTitle) }
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default PlaceFeedbackView;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    input: {
        display: 'flex',
        flexGrow: 1,
    },
    field: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(4),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        padding: theme.spacing(0, 2),
    },
}));
