import * as React from "react";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

type ContainerIcoProps = {
    color?: string;
	size?: number,
};

function jeBarvaSvetla(rgbKod: string): boolean {
  // Získání hodnot červené, zelené a modré složky z RGB kódu
  const r: number = parseInt(rgbKod.substring(0, 2), 16);
  const g: number = parseInt(rgbKod.substring(2, 4), 16);
  const b: number = parseInt(rgbKod.substring(4, 6), 16);

  // Výpočet průměru hodnot červené, zelené a modré složky
  const prumer: number = (r + g + b) / 3;

  // Rozhodnutí, zda je barva světlá nebo tmavá
  return prumer > 128;
}

const ContainerIco: React.FC<ContainerIcoProps> = ({ color='', size=56 }) => {
	const classes = makeStyles((theme) => createStyles({
		background: {
			fill: (jeBarvaSvetla(color) ? '#00A4E1' : '#ffffff')
		},
		color: {
			fill: (color !== '' ? '#'+color : '#00A4E1')
		},
	}))();

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`}
	 viewBox={`0 0 300 300`} xmlSpace="preserve">
	<g id="hafeF9_1_">
		<g>
			<g>
			<path className={classes.background} d="M219.2,216.6c-1.1,6.6-2.9,13.9-10.9,13.5c-7.6-0.4-10.1-7-9.9-14.1c0.2-7.1,2.5-13.7,10.5-13.5
		C216.9,202.7,218.3,210,219.2,216.6z"/>
			<path className={classes.background} d="M158.4,243.7c-1.3,7.5-3.3,15.8-12.4,15.4c-8.6-0.4-11.5-8-11.3-16.1c0.2-8.1,2.9-15.6,11.9-15.4
				C155.8,227.8,157.4,236.1,158.4,243.7z"/>
			<path className={classes.background} d="M77.8,214.1c-1.1,6.6-2.9,13.9-10.9,13.5c-7.6-0.4-10.1-7-9.9-14.1c0.2-7.1,2.5-13.7,10.5-13.5
				C75.5,200.1,76.9,207.4,77.8,214.1z"/>
			<path className={classes.background} d="M53.3,107c33.7,7.8,66.1,15.2,98.5,23.1c5.2,1.2,9.6,0.9,14.4-0.8c20-7.4,40.1-14.7,61.4-22.4c-4.5,29.4-9,57.5-13.1,85.5
				c-0.6,4.3-2.5,6.5-6.2,8.2c-17.7,8.3-35.3,16.6-52.9,25.2c-4.2,2.1-7.9,2.4-12.2,0.6c-23.9-9.8-47.7-19.6-71.7-29.1
				c-4.2-1.7-6.1-3.7-6.8-8.5C61.2,162,57.3,135.2,53.3,107z"/>
			<path className={classes.background} d="M47.9,96.4c8-13.7,14.8-27.7,27.7-36.8c16.9-11.8,34.5-8,52.3-5.3c28.2,4.3,56.3,8.3,84.5,12.3c5.3,0.7,10.2,2.3,14.8,5.1
				c6.8,4.2,7.4,12.6,8.3,19.4c0.7,5.1-6.3,4.6-10.1,6.1c-19.7,7.7-39.6,14.9-59.2,22.6c-7,2.8-13.6,3.2-21,1.3
				c-30.5-7.9-61.2-15-91.8-22.5C51.7,98.1,49.9,97.2,47.9,96.4z"/>
			<path  className={classes.color} d="M57,112.1c32.3,7.3,63.3,14.1,94.3,21.4c4.9,1.2,9.2,0.9,13.8-0.8c19.2-6.9,38.4-13.6,58.8-20.8
				c-4.3,27.4-8.6,53.4-12.5,79.5c-0.6,4-2.4,6.1-5.9,7.6c-16.9,7.7-33.8,15.5-50.6,23.5c-4,1.9-7.6,2.3-11.7,0.6
				c-22.8-9.1-45.7-18.2-68.7-27c-4-1.5-5.9-3.4-6.5-7.9C64.6,163.2,60.9,138.4,57,112.1z"/>
			<path  className={classes.color} d="M51.9,94.6c7.7-12.1,14.2-24.4,26.7-32.4c16.3-10.4,33.2-7,50.4-4.6c27.1,3.8,54.3,7.3,81.5,10.8
				c5.1,0.7,9.9,2,14.3,4.5c6.6,3.7,7.1,11.1,8,17.1c0.7,4.5-6.1,4.1-9.7,5.4c-19,6.8-38.1,13.1-57.1,19.9c-6.8,2.4-13.1,2.8-20.2,1.1
				c-29.4-6.9-59-13.2-88.5-19.8C55.5,96.1,53.8,95.2,51.9,94.6z"/>
			<path  className={classes.color} d="M156.5,243.4c-0.8,6.7-2.1,13.7-10.4,13.4c-7.5-0.3-9.2-6.7-9.4-13.1c-0.2-7.7,4.7-14.4,10.2-13.5
				C154.3,231.4,155.9,237.3,156.5,243.4z"/>
			<path  className={classes.color} d="M75.8,213.8c-0.9,5.5-2.4,11.5-8.9,11.2c-6.2-0.3-8.3-5.8-8.1-11.8c0.2-5.9,2.1-11.4,8.6-11.2
				C73.9,202.2,75,208.2,75.8,213.8z"/>
			<path  className={classes.color} d="M217.3,216c-0.7,5.5-1.5,11.6-8.5,11.6c-7.1,0-8.3-6-8.4-11.5c-0.1-5.3,1.5-10.6,7.9-11
				C215.6,204.6,216.3,210.6,217.3,216z"/>
			</g>
		</g>
	</g>
</svg>
    );
};

export default ContainerIco;


