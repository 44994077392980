import { injectable } from 'inversify';
import {
    action, observable, runInAction, computed, makeObservable
} from 'mobx';

import IocContainer from '../IocContainer';
import ClusterSearchResult from '../Models/ClusterSearchResult';
import MapSearchResults from '../Models/MapSearchResults';
import { NearbyPlacesSearchResult } from '../Models/NearbyPlacesSearchResult';
import { PlaceSearchResult } from '../Models/PlaceSearchResult';
import {
    SearchMapCriteria, SearchMapPlacesCriteria, SearchMapCitiesCriteria, SearchMapRegionsCriteria, DEFAULT_SEARCH_CRITERIA
} from '../Models/SearchMapCriteria';
import ApiService from '../Services/ApiService';
import IMapboxService, { IMapboxService_TYPE } from '../Services/IMapboxService';
import {
    RegionType, Bounds, LatLng, MapSearchLevel
} from '../Types';
import CachedObject from '../Utils/cachedObject';
import { locationsEqual } from '../Utils/locationTolerance';
import OrderedInvoker from '../Utils/orderedInvoker';
import SearchHelper from '../Utils/searchHelper';

import { MapStore } from './MapStore';

const MAP_VIEW_PADDING = 200; // pixels
const RESULTS_PER_PAGE = 20;

@injectable()
export default class SearchStore {
  private api = IocContainer.get(ApiService);

  private mapboxService = IocContainer.get<IMapboxService>(IMapboxService_TYPE);

  private mapStore = IocContainer.get(MapStore);

  @observable mapResults: MapSearchResults = {};

  @observable mapResultsLoading = false;

  @observable resultsNearby: PlaceSearchResult[] = [];

  @observable resultsInstructions = ''; // zobrazit komentar MD

  @observable resultsSupportedRegion = true;

  @observable resultsRegionComment: string | null = null;

  @observable showResultsList = true; // pokud false, nezobrazovat mista v okoli

  @observable resultsNearbyLoading = false;

  @observable moreResultsNearbyLoading = false;

  @observable resultsNearbyPageIndex = 0;

  @observable resultsNearbyHasMore = false;

  @observable searchCriteria: SearchMapCriteria = DEFAULT_SEARCH_CRITERIA;

  @observable searchNearbyEnabled = true;

  @computed get isLoadingNearbyResults() {
      return this.resultsNearbyLoading || this.moreResultsNearbyLoading;
  }

  private searchNearbyInvoker = new OrderedInvoker();

  private searchMapInvoker = new OrderedInvoker();

  private nearbyPlacesCache = new CachedObject<NearbyPlacesSearchResult>(null);

  private placesCache = new CachedObject<PlaceSearchResult[]>(null);

  private citiesCache = new CachedObject<ClusterSearchResult[]>(null);

  private regionsCache = new Map<MapSearchLevel, CachedObject<ClusterSearchResult[]>>([
      ['country', new CachedObject<ClusterSearchResult[]>()],
      ['orp', new CachedObject<ClusterSearchResult[]>()],
      ['okres', new CachedObject<ClusterSearchResult[]>()],
      ['kraj', new CachedObject<ClusterSearchResult[]>()],
  ]);

  constructor() {
      makeObservable(this);
  }

  @action async setMapPosition(viewBounds: Bounds, zoom: number) {
      console.debug('SearchStore::mapMoved', viewBounds, zoom);

      // add a padding for the map view bounds
      let bounds = this.mapboxService.addPaddingToBounds(viewBounds, MAP_VIEW_PADDING);

      // handle map view movement tolerance
      if (this.searchCriteria.bounds) {
      // project both bounds to the screen coordinates
          const criteriaPosition = this.mapboxService.projectBoundsPosition(this.searchCriteria.bounds);
          const newPosition = this.mapboxService.projectBoundsPosition(bounds);
          // get distance of both bounds in pixels
          const dx = Math.abs(criteriaPosition.x - newPosition.x);
          const dy = Math.abs(criteriaPosition.y - newPosition.y);
          // check if any of the direction crosses the threshold - in this case the padding value
          if (!(dx >= MAP_VIEW_PADDING || dy >= MAP_VIEW_PADDING)) {
              bounds = this.searchCriteria.bounds;
          }
      }

      const newCriteria: SearchMapCriteria = { ...this.searchCriteria, bounds, zoom };
      if (SearchHelper.compareCriteria(this.searchCriteria, newCriteria)) {
          return;
      }
      this.searchCriteria = newCriteria;
      await this.searchMap();
  }

  @action async setLocation(location: LatLng) {
      const hasMoved = () => {
      // if there is no location set previously in criteria the user position has moved
          if (!this.searchCriteria.location) {
              return true;
          }

          return !locationsEqual(location, this.searchCriteria.location);
      };

      // set new location to criteria if the user position significantly moved
      if (hasMoved()) {
          this.searchCriteria = { ...this.searchCriteria, location };
          await this.searchNearby();
      }
  }

  @action async setFilterTypes(types: string[]) {
      this.searchCriteria = { ...this.searchCriteria, types };
      await Promise.all([this.searchMap(), this.searchNearby()]);
  }

  @action async setCategory(categoryId: string, productId?: string) {
      this.searchCriteria = { ...this.searchCriteria, categoryId, productId };
      await Promise.all([this.searchMap(), this.searchNearby()]);
  }

  @action async clearCategory() {
      const { categoryId, productId, ...preserved } = this.searchCriteria;
      if (categoryId || productId) {
          this.searchCriteria = preserved;
          this.clearResults();
          await Promise.all([this.searchMap(), this.searchNearby()]);
      }
  }

  @action clearResults() {
      this.resultsNearby = [];
      this.resultsInstructions = '';
      this.resultsRegionComment = null;
      this.resultsSupportedRegion = true;
      this.showResultsList = true;
      this.mapResults = {};
  }

  @action setSearchNearbyEnabled(value: boolean) {
      this.searchNearbyEnabled = value;
  }

  @action async searchNearby() {
      if (!this.searchNearbyEnabled) {
          return;
      }

      if (!this.searchCriteria.location) {
      // cannot search nearby when there is no location
          return;
      }

      this.resultsNearbyLoading = true;
      this.moreResultsNearbyLoading = false;

      const searchNearbyCriteria = this.getNearbySearchCriteria();

      // detect criteria change in order to clear the resuls if they changed
      const nearbyCriteriaHasChanged = !SearchHelper.compareCriteria(searchNearbyCriteria as SearchMapCriteria, this.nearbyPlacesCache.state);
      if (nearbyCriteriaHasChanged) {
          this.resultsNearby = [];
          this.nearbyPlacesCache.expire();
      }
      this.nearbyPlacesCache.state = searchNearbyCriteria;

      await this.nearbyPlacesCache.update(async () => this.searchNearbyInvoker.continueIfNewest(
          () => this.api.searchMapNearby({
              ...searchNearbyCriteria,
              offset: 0,
              limit: RESULTS_PER_PAGE,
          })
      ));

      const resultsNearby = this.nearbyPlacesCache.getData();

      if (!resultsNearby) { return; }

      // apply most recent results
      runInAction(() => {
          this.resultsNearbyPageIndex = 0;
          this.resultsNearby = resultsNearby.data;
          this.resultsInstructions = resultsNearby.instructions;
          this.resultsSupportedRegion = resultsNearby.supportedRegion;
          this.resultsRegionComment = resultsNearby.regionComment || null;
          this.showResultsList = resultsNearby.list;
          this.resultsNearbyHasMore = this.showResultsList;
          this.resultsNearbyLoading = false;
      });

      if (this.searchCriteria.categoryId) {
          this.mapStore.setCategoryZoomLevels();
      } else {
          this.mapStore.setDefaultZoomLevels();
      }
  }

  @action async loadMoreNearbyResults() {
      // cannot search nearby when there is no location
      if (!this.searchCriteria.location) {
          return;
      }

      // cannot search more if results are empty
      if (this.resultsNearby.length === 0) {
      // TODO find better way to throttle this method
          await new Promise((r) => setTimeout(r, 1000));
          return;
      }

      this.moreResultsNearbyLoading = true;

      const pageIndex = this.resultsNearbyPageIndex + 1;

      await this.searchNearbyInvoker.continueIfNewest(
          () =>
          // call api
              this.api.searchMapNearby({
                  ...this.getNearbySearchCriteria(),
                  offset: pageIndex * RESULTS_PER_PAGE,
                  limit: RESULTS_PER_PAGE,
              }),
          async (moreResultsNearby) => {
              // apply most recent results
              runInAction(() => {
                  this.resultsNearbyPageIndex = pageIndex;
                  this.resultsNearbyHasMore = moreResultsNearby.data.length > 0;
                  this.resultsNearby = [...this.resultsNearby, ...moreResultsNearby.data];
                  this.moreResultsNearbyLoading = false;
              });
          }
      );
  }

  @action private async searchMap() {
      let searchMethod: (() => Promise<MapSearchResults>) | null = null;

      let searchLevel: MapSearchLevel = 'country';
      if (this.searchCriteria.zoom) {
          searchLevel = this.mapStore.getLevel(this.searchCriteria.zoom);
      }

      // set the search method based on the search level
      switch (searchLevel) {
      case 'places':
          searchMethod = async () => {
              const places = await this.searchPlaces();
              return { places: places || [] };
          };
          break;
      case 'cities':
          searchMethod = async () => {
              const clusters = await this.searchCities();
              return { clusters: clusters || [] };
          };
          break;
      case 'country':
      // case 'orp':
      case 'okres':
      case 'kraj':
          searchMethod = async () => {
              const clusters = await this.searchRegions(searchLevel as RegionType);
              return { clusters: clusters || [] };
          };
          break;
      }

      if (searchMethod) {
          this.mapResultsLoading = true;

          await this.searchMapInvoker.continueIfNewest(
              // invoke search method
              () => searchMethod!(),
              // apply map result
              async (mapResults) => {
                  runInAction(() => {
                      this.mapResults = mapResults;
                      this.mapResultsLoading = false;
                  });
              }
          );
      }
  }

  private getNearbySearchCriteria() {
      return ({
          location: this.searchCriteria.location,
          categoryId: this.searchCriteria.categoryId,
          productId: this.searchCriteria.productId,
          types: this.searchCriteria.types,
          date: this.searchCriteria.date,
          time: this.searchCriteria.time,
      });
  }

  private async searchPlaces() {
      const criteria: SearchMapPlacesCriteria = {
          bounds: this.searchCriteria.bounds,
          categoryId: this.searchCriteria.categoryId,
          types: this.searchCriteria.types,
          date: this.searchCriteria.date,
          time: this.searchCriteria.time,
      };
      // handle cache expiration based on the current criteria
      if (!SearchHelper.compareCriteria(criteria, this.placesCache.state)) {
          this.placesCache.expire();
      }
      this.placesCache.state = criteria;
      // update the cache
      await this.placesCache.update(() => this.api.searchMapPlaces(criteria));
      return this.placesCache.getData();
  }

  private async searchCities() {
      const criteria: SearchMapCitiesCriteria = {
          bounds: this.searchCriteria.bounds,
          categoryId: this.searchCriteria.categoryId,
          types: this.searchCriteria.types,
      };
      // handle cache expiration based on the current criteria
      if (!SearchHelper.compareCriteria(criteria, this.citiesCache.state)) {
          this.citiesCache.expire();
      }
      this.citiesCache.state = criteria;
      // update the cache
      await this.citiesCache.update(() => this.api.searchMapCities(criteria));
      return this.citiesCache.getData();
  }

  private async searchRegions(region: RegionType) {
      const criteria: SearchMapRegionsCriteria = {
          region,
          categoryId: this.searchCriteria.categoryId,
          types: this.searchCriteria.types,
      };
      // get the current cache for the current region type
      const cache = this.regionsCache.get(region);
      if (!cache) {
          throw Error(`Cache not found for region '${region}'`);
      }
      // handle cache expiration based on the current criteria
      if (!SearchHelper.compareCriteria(criteria, cache.state)) {
          cache.expire();
      }
      cache.state = criteria;
      // update the cache
      await cache.update(() => this.api.searchMapRegions(criteria));
      return cache.getData();
  }
}
