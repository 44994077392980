import { injectable } from 'inversify';
import { observable, action, computed, makeObservable } from 'mobx';

import User from '../Models/User';

export type AuthenticationState = 'unknown' | 'anonymous' | 'signingOut' | 'signingIn' | 'singningUp' | 'authenticated';

@injectable()
export class AuthStore {
    @observable public state: AuthenticationState = 'unknown';

    @observable public user: User | null = null;

    @computed public get isSignedIn() { return !!this.user && this.state === 'authenticated'; }

    constructor() {
        makeObservable(this);
    }

    @action public setState(state: AuthenticationState) {
        this.state = state;
    }

    @action public logIn(user: User) {
        this.updateUser(user);
        this.setState('authenticated');
    }

    @action public logOut() {
        this.user = null;
        this.setState('signingOut');
    }

    @action public updateUser(user: User) {
        this.user = user;
    }
}
