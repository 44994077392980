import { Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { PlaceInfo } from '@shared/Core/Models/PlaceInfo';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useStores } from '../../Stores/useStores';
import CategoryMenu from '../CategoryMenu/CategoryMenu';
import LocationPicker from '../Common/LocationPicker';
import MarkDown from '../Common/MarkDown';

import PlacesList from './PlacesList';

const NearbyPlacesListMessages = defineMessages({
    placesNearbyTitle: {
        id: 'PLACES_NEARBY_TITLE',
        defaultMessage: 'Místa v okolí',
    },
});

type NearbyPlacesListProps = {
    showCategoryMenu?: boolean;
}

const NearbyPlacesList: React.FC<NearbyPlacesListProps> = ({ showCategoryMenu: showPlaceCategories }) => {
    const {
        searchStore, layoutStore, homeStore,
    } = useStores();
    const intl = useIntl();
    const { navigateToPlace } = useHomeNavigation();
    const classes = useStyles({ isMobile: layoutStore.isMobile, showPlaceCategories });

    const onItemClick = (item: PlaceInfo) => {
        navigateToPlace(item.id);
    };

    const placesNearbyTitle = intl.formatMessage(NearbyPlacesListMessages.placesNearbyTitle);

    useEffect(() => {
        if (!homeStore.activeMapLocation) {
            searchStore.clearResults();
        }
    }, [homeStore.activeMapLocation]);

    // useEffect(() => {
    //     layoutStore.setDrawerScrollTitle(placesNearbyTitle);
    //     return () => {
    //         layoutStore.clearDrawerScrollTitle();
    //     };
    // }, []);

    return (
        <div className={ classes.root }>
            { showPlaceCategories && layoutStore.isMobile && (
                <div className={ classes.placeCategoriesHolder }>
                    <CategoryMenu />
                </div>
            ) }
            <div className={ classes.header }>
                <div className={ classes.location }>
                    <Typography component="div" variant="h5" align="center">{ placesNearbyTitle }</Typography>
                    { /* { homeStore.mapLocationType !== 'selected' && renderHelpIcon() } */ }
                </div>
                <div className={ classes.location }>
                    <LocationPicker />
                </div>
            </div>
            { !searchStore.isLoadingNearbyResults && searchStore.resultsNearby.length === 0 ? (
                <div className={ classes.emptyResultsContainer }>
                    { renderEmptyResults() }
                </div>
            ) : (
                <PlacesList items={ searchStore.resultsNearby } loading={ searchStore.isLoadingNearbyResults } onItemClick={ onItemClick } />
            ) }
        </div>
    );

    function renderEmptyResults() {
        if (!homeStore.activeMapLocation || homeStore.userMapLocationStatus === 'error') {
            return (
                <Typography>
                    <FormattedMessage
                        id="PLACES_NEARBY_LOCATION_UNAVAILABLE"
                        defaultMessage="K zobrazení míst v okolí prosím nejprve vyberte pozici na mapě."
                    />
                </Typography>
            );
        }

        if (searchStore.resultsSupportedRegion || !searchStore.resultsRegionComment) {
            return (
                <Typography>
                    <FormattedMessage
                        id="PLACES_NEARBY_EMPTY"
                        defaultMessage="V okolí nebyla nalezena žádná místa"
                    />
                </Typography>
            );
        }

        return (
            <MarkDown source={ searchStore.resultsRegionComment } />
        );
    }

    // function renderHelpIcon() {
    //     return (
    //         <PopupState variant="popover" popupId="location-help-popover">
    //             { (popupState: any) => (
    //                 <div>
    //                     <IconButton
    //                         { ...bindTrigger(popupState) }
    //                         className={ classes.helpIcon }
    //                         color="inherit"
    //                         size="small"
    //                         aria-label="help"
    //                     >
    //                         <HelpIcon style={ { fontSize: 18 } } />
    //                     </IconButton>
    //                     <Popover
    //                         { ...bindPopover(popupState) }
    //                         anchorOrigin={ {
    //                             vertical: 'bottom',
    //                             horizontal: 'center',
    //                         } }
    //                         transformOrigin={ {
    //                             vertical: 'top',
    //                             horizontal: 'right',
    //                         } }
    //                     >
    //                         <Box p={ 2 }>
    //                             <Typography>
    //                                 <FormattedMessage
    //                                     id="PLACES_NEARBY_SELECTED_LOCATION_HELP"
    //                                     defaultMessage="Kliknutím do mapy můžete zvolit libovolnou výchozí polohu."
    //                                 />
    //                             </Typography>
    //                         </Box>
    //                     </Popover>
    //                 </div>
    //             ) }
    //         </PopupState>
    //     );
    // }
};

export default observer(NearbyPlacesList);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        paddingTop: params.showPlaceCategories ? theme.spacing(1.5) : theme.spacing(2),
    }),
    header: {
        margin: theme.spacing(1, 0, 0, 0),
    },
    location: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearIcon: {
        margin: theme.spacing(0, 0.75),
    },
    helpIcon: {
        margin: theme.spacing(0, 0.75),
        marginLeft: 9,
        marginRight: -30,
        color: theme.palette.secondary.main,
    },
    emptyResultsContainer: {
        display: 'flex',
        padding: theme.spacing(4),
        justifyContent: 'center',
    },
    placeCategoriesHolder: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(0.3),
        marginBottom: theme.spacing(2),
    },
}));
