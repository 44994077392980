import { InputBase } from '@material-ui/core';
import {
    createStyles, fade, Theme, makeStyles
} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { ChangeEventHandler } from 'react';
import { isBrowser } from 'react-device-detect';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import { useStores } from '../../../Stores/useStores';
import BackButton from '../../Common/BackButton';
import CloseButton from '../../Common/CloseButton';
import EmptyButton from '../../Common/EmptyButton';
import ResponsiveToolbar from '../../Layout/ResponsiveToolbar';

const CategoriesNavigationMessages = defineMessages({
    categoriesSearchPlaceholder: {
        id: 'CATEGORIES_SEARCH_PLACEHOLDER',
        defaultMessage: 'Čeho se chci zbavit?',
    },
});

const CategoriesNavigation: React.FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const { categoryStore, layoutStore } = useStores();
    const {
        navigateBack, navigateHome, getHomePath: getRootPath, concatPath,
    } = useHomeNavigation();

    const match = useRouteMatch<{
        categoryId: string,
        searchTerm: string
     }>();

    const headerVisible = !categoryStore.searchTerm;
    const { isMobile } = layoutStore;
    const isMainCategories = categoryStore.browsedCategories.length === 0 && !categoryStore.isSearchActive;

    const classes = useStyles({ headerVisible, isMobile });

    const onSearchBarBlur = () => { };
    
    let searchEdit = false;
    if(match.params.searchTerm !== undefined && match.params.searchTerm !== '') {
        categoryStore.setSearchTerm(match.params.searchTerm);
        searchEdit = true;
    }

    const onSearchTermChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        categoryStore.setSearchTerm(e.target.value);

        if(e.target.value !== "") {
            if (history.location.pathname.indexOf('/browse/all/search/') === -1 ) {
                history.push(concatPath(getRootPath(), '/browse/all/search/' + categoryStore.searchTerm));
            } else {
                history.replace(concatPath(getRootPath(), '/browse/all/search/' + categoryStore.searchTerm));
            }
            searchEdit = true;
        } else {
            const browseAllPath = concatPath(getRootPath(), '/browse/all');
            // make sure the categories are on the top level when searching category by term
            if (history.location.pathname !== browseAllPath) {
                history.replace(browseAllPath);
            }
        }
    };

    const onBackButtonClicked = () => {
        if (categoryStore.isSearchActive) {
            categoryStore.clearSearch();
            if (history.location.pathname.indexOf('/browse/all/search/') !== -1 ) {
                history.replace(concatPath(getRootPath(), '/browse/all'));
            }
        } else {
            navigateBack();
        }
    };

    const onCloseButtonClicked = () => navigateHome();

    const searchTermValue = categoryStore.searchTerm;
    return (
        <div className={ classes.root }>
            <ResponsiveToolbar>
                <div>
                    { isMainCategories
                        ? <EmptyButton />
                        : <BackButton onClick={ onBackButtonClicked } /> }
                </div>
                <div className={ classes.toolbarContent }>

                    <div className={ classes.searchContent }>
                        <div className={ classes.searchContentSection }>
                            <div className={ classes.search }>
                                <InputBase
                                    autoFocus={ isBrowser || searchEdit }
                                    placeholder={ intl.formatMessage(CategoriesNavigationMessages.categoriesSearchPlaceholder) }
                                    classes={ {
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    } }
                                    value={ searchTermValue }
                                    inputProps={ { 'aria-label': 'search' } }
                                    // onFocus={onSearchCategoriesClicked}
                                    onBlur={ onSearchBarBlur }
                                    onChange={ onSearchTermChange }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CloseButton onClick={ onCloseButtonClicked } />
                </div>
            </ResponsiveToolbar>
        </div>
    );
};

export default observer(CategoriesNavigation);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: () => ({
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: 0,
    }),
    toolbarContent: () => ({
        flex: 1,
        padding: theme.spacing(0.5, 0),
        // : theme.spacing(
        //     0,
        //     params.isMobile ? 0 : 4,
        //     0,
        //     params.isMobile ? 0 : 4
        // )
    }),
    searchContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    searchContentSection: () => ({
        flex: 1,
        margin: theme.spacing(0.25, 1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
    toolbarButton: () => ({
        margin: theme.spacing(0, 0.15),
    }),
    header: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: params.headerVisible ? '36px' : '0px',
        opacity: params.headerVisible ? 1 : 0,
        overflow: 'hidden',
        transition: theme.transitions.create(['height', 'opacity']),
        padding: '4px 6px',
        // animation: '$showHeaderAnimation 500ms',
    }),
    breadCrumbs: {
        color: 'white',
    },
    search: () => ({
        position: 'relative',
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        cursor: 'pointer',
        // ...(params.isMobile
        //     ? {
        //         backgroundColor: fade(theme.palette.primary.main, 0.75),
        //     }
        //     : {
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        // })

    }),
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        flexGrow: 1,
        paddingRight: theme.spacing(4),
    },
    inputInput: {
        cursor: 'pointer',
        padding: theme.spacing(1.5, 2),
        transition: theme.transitions.create('width'),
        flexGrow: 1,
        '&:focus': {
            cursor: 'initial',
        },
        '&::placeholder': {
            userSelect: 'none',
            color: theme.palette.common.white,
            opacity: 1,
        },
        '&:focus::placeholder': {
            color: theme.palette.common.white,
            opacity: 0.5,
        },
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: 120,
        //     '&:focus': {
        //         width: 200,
        //     },
        // },
    },
}));
