import IEnvironment, { IEnvironment_TYPE } from '@shared/Core/IEnvironment';
import IocContainer from '@shared/Core/IocContainer';
import IPerformanceLogger from '@shared/Core/Services/IPerformanceLogger';
import { injectable } from 'inversify';

import FirebaseService from './FirebaseService';


@injectable()
export default class PerformanceLogger implements IPerformanceLogger {
    private environment = IocContainer.get<IEnvironment>(IEnvironment_TYPE);

    private firebaseService = IocContainer.get(FirebaseService);

    public async trace<T>(traceName: string, action: () => Promise<T>): Promise<T> {
        let t: firebase.performance.Trace | null = null;
        if (this.environment.isProd) {
            t = this.firebaseService.firebase.performance().trace(traceName);
            t.start();
        }
        const result = await action();
        if (t !== null) {
            t.stop();
        }
        return result;
    }
}
