import {
    Typography, InputAdornment, Button, Grid, InputLabel, OutlinedInput, FormControl
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import CopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import React, {
    useRef, useState, useMemo
} from 'react';
import { FormattedMessage } from 'react-intl';

type ShareViewProps = {
};

const ShareView: React.FC<ShareViewProps> = () => {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>();
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        if (!inputRef.current) { return; }

        inputRef.current.select();
        inputRef.current.setSelectionRange(0, 99999);

        /* Copy the text inside the text field */
        document.execCommand('copy');

        setCopied(true);
    };

    const shareLink = window.location.href.replace("iosApp=1&", "").replace("androidApp=1&", "");
    const content = useMemo(() => getOGDescription(), []);

    return (
        <div className={ classes.inner }>
            <Grid container direction="row" spacing={ 4 }>
                <Grid item xs={ 6 }>
                    <Button
                        color="primary"
                        aria-label="share on facebook"
                        href={ `https://www.facebook.com/sharer.php?u=${shareLink}` }
                        target="_blank"

                        startIcon={ <FacebookIcon /> }
                    >
                        <FormattedMessage
                            id="SHARE_FACEBOOK"
                            defaultMessage="Sdílet na Facebooku"
                        />
                    </Button>
                </Grid>
                <Grid item xs={ 6 }>
                    <Button
                        color="primary"
                        aria-label="share on twitter"
                        href={ `https://twitter.com/intent/tweet?text=${content}&url=${shareLink}` }
                        target="_blank"
                        startIcon={ <TwitterIcon /> }
                    >
                        <FormattedMessage
                            id="SHARE_TWITTER"
                            defaultMessage="Sdílet na Twitteru"
                        />
                    </Button>
                </Grid>
                <Grid item xs={ 12 }>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="input-link-share" variant="outlined">
                            <div className={ classes.shareLinkTitleContainer }>
                                <LinkIcon color="primary" />
                                <Typography className={ classes.shareLinkTitle } component="span" variant="button">
                                    <FormattedMessage
                                        id="SHARE_LINK"
                                        defaultMessage="Sdílet odkaz"
                                    />
                                </Typography>
                            </div>
                        </InputLabel>
                        <OutlinedInput
                            inputRef={ inputRef }
                            id="input-link-share"
                            value={ shareLink }
                            readOnly
                            endAdornment={ (
                                <InputAdornment position="end">
                                    <Button onClick={ handleCopyClick }>
                                        <CopyIcon color="primary" />
                                    </Button>
                                </InputAdornment>
                            ) }
                        />
                        <div>
                            { copied && (
                                <Typography component="div" variant="caption" className={ classes.copiedMessage }>
                                    <FormattedMessage
                                        id="SHARE_LINK_COPIED_MESSAGE"
                                        defaultMessage="Odkaz byl zkopírován do schránky"
                                    />
                                </Typography>
                            ) }
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );

    function getOGDescription() {
        return document.querySelector("meta[property='og:description']")?.getAttribute('content') ?? '';
    }
};

export default ShareView;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: { },
    inner: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        padding: theme.spacing(5, 2),
        marginBottom: params.isMobile ? '20vh' : undefined,
    }),
    shareLinkTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: theme.spacing(0, 1),
    },
    shareLinkTitle: {
        marginLeft: theme.spacing(1),
    },
    copiedMessage: {
        position: 'absolute',
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(1),
    },
}));
