export default class OrderedInvoker {
    private timestamp = new Date().getTime();

    public async continueIfNewest<T, TResult = T>(handler: () => Promise<T>, continueHandler?: (result: T) => Promise<T | TResult | null>): Promise<T | TResult | null> {
        const timestamp = new Date().getTime();
        this.timestamp = timestamp;
        const result = await handler();
        if (this.timestamp > timestamp) {
            // the request is outdated let's drop it
            return null;
        }
        return continueHandler ? continueHandler(result) : result;
    }
}
