import { useHistory } from 'react-router';

import { useStores } from '../Stores/useStores';
import { concatPath } from '../utils/concatPath';

import { useNavigation } from './useNavigation';

export function useRootNavigation() {
    const history = useHistory();
    const { appStore } = useStores();
    const { constructPath } = useNavigation();

    const getRootPath = () => appStore.rootPath;

    return {
        getRootPath,
        navigateTo,
        getLinkTo,
    };

    function navigateTo(path: string) {
        const target = getLinkTo(path);
        history.push(target);
    }

    function getLinkTo(path: string) {
        return constructPath(concatPath(getRootPath(), path));
    }
}
