import moment from 'moment';

import { SearchMapCriteria } from '../Models/SearchMapCriteria';

import { isNullOrUndefined } from './Helpers';

export default class SearchHelper {
    public static convertCriteriaToQueryParams(criteria: SearchMapCriteria) {
    // transform criteria to query paramaters
        const params = new URLSearchParams();
        if (criteria.categoryId) {
            params.append('category', criteria.categoryId);
        }
        if (criteria.productId) {
            params.append('product', criteria.productId);
        }
        if (criteria.types && criteria.types.length > 0) {
            criteria.types.forEach((type) => params.append('types', type));
        }
        if (criteria.zoom) {
            params.append('zoom', criteria.zoom.toString());
        }
        if (criteria.bounds) {
            params.append('west', criteria.bounds.west.toString());
            params.append('east', criteria.bounds.east.toString());
            params.append('north', criteria.bounds.north.toString());
            params.append('south', criteria.bounds.south.toString());
        }
        if (criteria.location) {
            params.append('lng', criteria.location.lng.toString());
            params.append('lat', criteria.location.lat.toString());
        }
        if (criteria.region) {
            params.append('region', criteria.region);
        }
        if (criteria.date) {
            params.append('date', moment(criteria.date).format('MM/DD/YYYY'));
        }
        if (criteria.time) {
            params.append('time', criteria.time);
        }
        if (!isNullOrUndefined(criteria.offset)) {
            params.append('offset', criteria.offset!.toString());
        }
        if (!isNullOrUndefined(criteria.limit)) {
            params.append('limit', criteria.limit!.toString());
        }
        return params;
    }

    public static compareCriteria<T>(a: T, b: T) {
        if ((a === null && b !== null) || (b === null && a !== null)) { return true; }
        if (a === null && b === null) { return false; }
        const aqp = this.convertCriteriaToQueryParams(a as any as SearchMapCriteria);
        const bqp = this.convertCriteriaToQueryParams(b as any as SearchMapCriteria);
        return aqp.toString() === bqp.toString();
    }
}
