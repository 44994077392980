import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

@injectable()
export class SignInStore {
    @observable public errorToDisplay: string | undefined;

    constructor() {
        makeObservable(this);
    }

    @action public setErrorToDisplay(err: string) {
        this.errorToDisplay = err;
    }

    @action public clearErrorToDisplay() {
        this.errorToDisplay = undefined;
    }

    @action reset() {
        this.clearErrorToDisplay();
    }
}
