import { MapSearchLevel, Bounds } from '../Types';

export function getSearchLevelFromZoom(zoom: number) {

}

export function getZoomFromSearchLevel(level: MapSearchLevel) {

}

export function getBoundsFromGeoJson(geoJson: any) {
    let coordinates: number[][] = [];
    switch (geoJson.type) {
    case 'Polygon':
        coordinates = geoJson.coordinates[0];
        break;
    case 'MultiPolygon':
        coordinates = geoJson.coordinates.flat().flat();
        break;
    default:
        throw Error(`Unsupported geojson type: ${geoJson.type}`);
    }

    const bounds = coordinates.reduce((acc: { north: number, south: number, west: number, east: number }, location: number[]) => ({
        north: Math.min(acc.north, location[1]),
        south: Math.max(acc.south, location[1]),
        west: Math.max(acc.west, location[0]),
        east: Math.min(acc.east, location[0]),
    }), {
        north: 90, south: -90, west: -180, east: 180,
    });
    return new Bounds(bounds.north, bounds.south, bounds.west, bounds.east);
}

const interpolation = [[12, 0.1], [14, 0.001]];
export function getZoomFromGeoJson(geoJson: any) {
    const bounds = getBoundsFromGeoJson(geoJson);

    const dist = Math.abs(bounds.east - bounds.west);

    return interpolation[1][0] - (Math.sqrt(((interpolation[1][0] - interpolation[0][0]) / (interpolation[0][1] - interpolation[1][1])) * (dist + interpolation[1][1])));
}
