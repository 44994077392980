import { injectable } from 'inversify';

import { MapLocationType } from '../Stores/HomeStore';

export type LocationType = 'GPS' | 'city' | 'custom' | 'none';

@injectable()
export class LocationTypeProvider {
    public locationType: LocationType = 'none';

    public setFromMapLocationType(mapLocationType: MapLocationType) {
        switch (mapLocationType) {
        case 'selected':
            this.locationType = 'custom';
            break;
        case 'user':
            this.locationType = 'GPS';
            break;
        case 'radnice':
            this.locationType = 'city';
            break;
        default:
            this.locationType = 'none';
            break;
        }
    }
}
