import { observer } from 'mobx-react-lite';
import React from 'react';

import { useHomeNavigation } from '../Hooks/useHomeNavigation';

type LogoProps = {
    className?: string;
    height: number;
};

const LogoDefault: React.FC<LogoProps> = (props) => {
    const { navigateToDefault } = useHomeNavigation();

    const onLogoClick = () => {
        navigateToDefault();
    };

    return (
        <img
            onClick={ onLogoClick }
            alt="Kam s ním?"
            src="/assets/logo_h.png"
            height={ `${props.height}px` }
            style={ {
                userSelect: 'none',
                cursor: 'pointer',
                marginTop: 0,
            } }
        />
    );
};

export default observer(LogoDefault);
