import { InputBase, IconButton } from '@material-ui/core';
import {
    createStyles, fade, Theme, makeStyles
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import React, { ChangeEventHandler } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { useStores } from '../../../Stores/useStores';
import BackButton from '../../Common/BackButton';
import EmptyButton from '../../Common/EmptyButton';
import ResponsiveToolbar from '../../Layout/ResponsiveToolbar';

const SearchPlacesNavigationMessages = defineMessages({
    placesSearchPlaceholder: {
        id: 'PLACES_SEARCH_PLACEHOLDER',
        defaultMessage: 'Napište název hledaného místa',
    },
});

const SearchPlacesNavigation: React.FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const { searchPlacesStore, layoutStore } = useStores();

    const { isMobile } = layoutStore;

    const classes = useStyles({ isMobile });

    const onSearchBarBlur = () => { };

    const onSearchTermChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        searchPlacesStore.setSearchTerm(e.target.value);
    };

    const onClearTermClicked = () => {
        searchPlacesStore.setSearchTerm('');
    };

    const onBackButtonClicked = () => history.goBack();

    const searchTermValue = searchPlacesStore.searchTerm;
    return (
        <div className={ classes.root }>
            <ResponsiveToolbar>
                <div className={ classes.toolbarButtonContainer }>
                    <BackButton onClick={ onBackButtonClicked } />
                </div>
                <div className={ classes.toolbarContent }>
                    <div className={ classes.searchContent }>
                        <div className={ classes.searchContentSection }>

                            <div className={ classes.search }>
                                <InputBase
                                    placeholder={ intl.formatMessage(SearchPlacesNavigationMessages.placesSearchPlaceholder) }
                                    classes={ {
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    } }
                                    value={ searchTermValue }
                                    inputProps={ { 'aria-label': 'search' } }
                                    // onFocus={onSearchCategoriesClicked}
                                    onBlur={ onSearchBarBlur }
                                    onChange={ onSearchTermChange }
                                />
                                { searchTermValue && (
                                    <div className={ classes.clearSearchIcon }>
                                        <IconButton color="inherit" size="small" onClick={ onClearTermClicked }>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
                { /* {layoutStore.isMobile && */ }
                <div className={ classes.toolbarButtonContainer }>
                    <EmptyButton />
                </div>
                { /* } */ }
            </ResponsiveToolbar>
        </div>
    );
};

export default observer(SearchPlacesNavigation);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: () => ({
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: 0,
    }),
    toolbarContent: () => ({
        flex: 1,
        padding: theme.spacing(1, 0),
    }),
    searchContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    searchContentSection: () => ({
        flex: 1,
        margin: theme.spacing(0.25),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
    mobileLogo: {
        // position: 'absolute',
        // left: 0,
        // top: 0,
        // width: '100%',
        // flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0.1),
    },
    fillContentSection: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(0.5),
    },
    mainToolbarButtons: () => ({
        position: 'relative',
        display: 'flex',
        margin: theme.spacing(0.25),
        color: theme.palette.primary.main,
    }),
    buttons: {
        display: 'flex',
    },
    toolbarButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'flex-start',
        minHeight: '60px',
    },
    toolbarButton: () => ({
        margin: theme.spacing(0, 0.15),
    }),
    menuButton: {
        color: `${theme.palette.primary.main} !important`,
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    header: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: params.headerVisible ? '36px' : '0px',
        opacity: params.headerVisible ? 1 : 0,
        overflow: 'hidden',
        transition: theme.transitions.create(['height', 'opacity']),
        padding: '4px 6px',
        // animation: '$showHeaderAnimation 500ms',
    }),
    breadCrumbs: {
        color: 'white',
    },
    search: () => ({
        position: 'relative',
        display: 'flex',
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        cursor: 'pointer',
        // ...(params.isMobile
        //     ? {
        //         backgroundColor: fade(theme.palette.primary.main, 0.75),
        //     }
        //     : {
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        // })

    }),
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clearSearchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 0,
        top: 0,
        zIndex: 10,
        color: 'white',
    },
    inputRoot: {
        color: 'inherit',
        flexGrow: 1,
        paddingRight: theme.spacing(4),
    },
    inputInput: {
        cursor: 'pointer',
        padding: theme.spacing(1.5, 2),
        transition: theme.transitions.create('width'),
        flexGrow: 1,
        '&:focus': {
            cursor: 'initial',
        },
        '&::placeholder': {
            userSelect: 'none',
            color: theme.palette.common.white,
            opacity: 1,
        },
        '&:focus::placeholder': {
            color: theme.palette.common.white,
            opacity: 0.5,
        },
        // width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: 120,
        //     '&:focus': {
        //         width: 200,
        //     },
        // },
    },
}));
