import {
    Typography, Card, CardActionArea, CardMedia, Breadcrumbs
} from '@material-ui/core';
import {
    createStyles, Theme, makeStyles, fade
} from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIosSharp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CategoryViewModel from '@shared/Core/Models/CategoryViewModel';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import TruncateMarkup from 'react-truncate-markup';

import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import { useStores } from '../../../Stores/useStores';
import Square from '../../Common/Square';

const CategoriesBrowser: React.FC = () => {
    const match = useRouteMatch<{ categoryId: string | 'all' }>();
    const { categoryStore } = useStores();
    const classes = useStyles();
    const { navigateToCategoryBrowse, navigateToCategoryResults } = useHomeNavigation();

    const preselectedCategoryId = match.params.categoryId;

    useEffect(() => {
        categoryStore.setBrowsedCategory(preselectedCategoryId);
    }, [preselectedCategoryId]);

    const onItemClick = (category: CategoryViewModel) => () => {
        if (category.children) {
            categoryStore.setBrowsedCategory(category.id);
            // make sure the route is set in URL
            navigateToCategoryBrowse(category.id);
        } else {
            navigateToCategoryResults(category.id);
        }
    };

    return (
        <div className={ classes.root }>
            <div className={ classes.breadcrumbsHolder }>
                { /* <Typography variant="subtitle1" component="div">Hledat v kategoriích</Typography> */ }
                { categoryStore.browsedCategories.length > 0 && (
                    <Breadcrumbs separator={ <NavigateNextIcon fontSize="small" /> } aria-label="breadcrumb" className={ classes.breadCrumbs }>
                        { categoryStore.browsedCategories.map((category) => (
                            <Typography key={ category.id } variant="subtitle1">{ category.name }</Typography>
                        )) }
                    </Breadcrumbs>
                ) }
            </div>
            <div className={ classes.list }>
                { categoryStore.categories.filter((c) => c.id !== 'hidden').map((category) => (
                    <div key={ category.id } className={ classes.cardWrapper }>
                        { renderItem(category) }
                    </div>
                )) }
            </div>
        </div>
    );

    function renderItem(category: CategoryViewModel) {
        const hasChildren = !!category.children;
        return (
            <Card className={ classes.card }>
                <CardActionArea onClick={ onItemClick(category) } className={ classes.cardContentContainer }>
                    <Square>
                        <CardMedia
                            className={ classes.media }
                            image={ category.icon || './assets/category.png' }
                            title={ category.name }
                        />
                        { hasChildren && (
                            <div className={ classes.cardArrow }>
                                { /* <div>{category.totalCount}</div> */ }
                                <ArrowForwardIcon />
                            </div>
                        ) }
                    </Square>
                    <div className={ classes.itemContent }>
                        <TruncateMarkup lines={ 2 }>
                            <Typography component="div" className={ classes.itemContentText }>
                                { category.name }
                            </Typography>
                        </TruncateMarkup>
                    </div>
                </CardActionArea>
            </Card>
        );
    }
};

export default observer(CategoriesBrowser);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'stretch',
        // marginTop: -theme.spacing(1.25),
    },
    cardWrapper: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'stretch',

        position: 'relative',
        padding: '8px',
        width: `calc(33.3% - 16px)`,
    },
    card: {
        width: '100%',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
    },
    cardContentContainer: {
        flexGrow: 1,
        // flexDirection: 'column'
    },
    cardContent: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alighItems: 'flex-start',
    },
    media: {
        height: '100%',
        // filter: 'brightness(0.8)'
    },
    cardArrow: {
        position: 'absolute',

        // top: 0,
        // left: 0,
        // borderBottomRightRadius: '8px',

        top: 0,
        right: 0,
        borderBottomLeftRadius: '8px',

        width: 'auto',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // backgroundColor: fade(theme.palette.common.white, 1),
        // backgroundColor: fade(theme.palette.primary.main, 1),
        backgroundColor: fade(theme.palette.secondary.main, 1),
        '& > *': {
            color: fade(theme.palette.common.white, 1),
            margin: '4px 8px',
            ...theme.typography.subtitle1,
        },
    },
    itemContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        // left: 0,
        // bottom: 0,
        width: '100%',
        boxSizing: 'border-box',
        height: '3.75rem',
        // backgroundColor: theme.palette.common.white,
        backgroundColor: fade(theme.palette.secondary.main, 1),
        color: fade(theme.palette.common.white, 1),
        padding: theme.spacing(1.25, 1),
    },
    itemContentText: {
        textAlign: 'center',
        fontSize: '1rem',
    },
    breadcrumbsHolder: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1.5),
    },
    breadCrumbs: {
        color: theme.palette.common.black,
    },
}));
