import {
    Snackbar, Button, Link, SnackbarContent
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { useStores } from '../Stores/useStores';

const CookieConsentMessages = defineMessages({
    cookieConsentContent: {
        id: 'COOKIE_CONSENT_CONTENT',
        defaultMessage: 'Webová aplikace používá k poskytování služeb a analýze návštěvnosti <link>cookies</link>. Pokračováním s tímto souhlasíte.',
    },
    cookieConsentCtaAgree: {
        id: 'COOKIE_CONSENT_CTA_AGREE',
        defaultMessage: 'Souhlasím',
    },
});

const CookieConsent: React.FC = observer(() => {
    const { localeStore } = useStores();
    const intl = useIntl();
    const [open, setOpen] = useState(!localStorage.getItem('ksn-cookie-consent'));
    const classes = useStyles();

    const handleClose = () => {
        localStorage.setItem('ksn-cookie-consent', 'true');
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
            open={ open }
        >
            <SnackbarContent
                className={ classes.root }
                message={
                    intl.formatMessage(CookieConsentMessages.cookieConsentContent, {
                        link: (...chunks: React.ReactNode[]) => (
                            <Link
                                component="a"
                                href={ `https://policies.google.com/technologies/cookies?hl=${localeStore.locale}` }
                                target="_blank"
                            >
                                { chunks }
                            </Link>
                        ),
                    })
                }
                action={ (
                    <Button color="primary" size="small" onClick={ handleClose }>
                        { intl.formatMessage(CookieConsentMessages.cookieConsentCtaAgree) }
                    </Button>
                ) }
            />
        </Snackbar>
    );
});

export default CookieConsent;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
}));
