import {
    Typography, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

type PlaceItemSkeletonProps = {
};

const PlaceItemSkeleton: React.FC<PlaceItemSkeletonProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem className={ classes.root }>
            <ListItemIcon>
                <Skeleton variant="circle" width={ 48 } height={ 48 } animation="wave" />
            </ListItemIcon>
            <ListItemText
                className={ classes.itemContent }
                primary={ (
                    <Skeleton height={ 20 } style={ { marginBottom: 6 } } animation="wave" />
                ) }
                secondary={ (
                    <Typography component="div">
                        <>
                            <Skeleton height={ 10 } style={ { marginBottom: 6 } } animation="wave" />
                            <Skeleton height={ 10 } style={ { marginBottom: 6 } } animation="wave" />
                        </>
                    </Typography>
                ) }
            />
        </ListItem>
    );
};

export default PlaceItemSkeleton;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        padding: theme.spacing(2, 2, 2, 1),
    },
    itemContent: {
        paddingLeft: theme.spacing(2),
    },
}));
