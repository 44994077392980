import { injectable } from 'inversify';
import {
    observable, action, computed, makeObservable
} from 'mobx';

import { ZOOM_LEVELS_MAP, ZOOM_LEVELS_MAP_CATEGORY } from '../Constants';
import IocContainer from '../IocContainer';
import IMapboxService, { IMapboxService_TYPE } from '../Services/IMapboxService';
import { LatLng, MapType } from '../Types';

@injectable()
export class MapStore {
    private mapboxService = IocContainer.get<IMapboxService>(IMapboxService_TYPE);

    @observable mapType: MapType = 'streets';

    @observable zoomLevelsMap = ZOOM_LEVELS_MAP;

    @computed get maxZoomLevel() {
        return Math.max(...Array.from(this.zoomLevelsMap.values()));
    }

    @computed get zoomLevels() {
        return Array.from(this.zoomLevelsMap.values()).sort((a, b) => a - b);
    }

    constructor() {
        makeObservable(this);
    }

    @action setDefaultZoomLevels() {
        this.zoomLevelsMap = ZOOM_LEVELS_MAP;
    }

    @action setCategoryZoomLevels() {
        this.zoomLevelsMap = ZOOM_LEVELS_MAP_CATEGORY;
    }

    @action zoomIn() {
        this.mapboxService.zoomTo(this.getNextZoomIn());
    }

    @action zoomOut() {
        this.mapboxService.zoomTo(this.getNextZoomOut());
    }

    @action zoomToCluster(coords: LatLng) {
        this.mapboxService.flyTo(coords, this.getNextZoomIn());
    }

    @action setMapType(type: MapType) {
        this.mapType = type;
        this.mapboxService.setMapType(type);
    }

    public getLevel(zoom: number) {
        const entries = Array.from(this.zoomLevelsMap.entries())
            .sort((a, b) => b[1] - a[1]);
        const level = entries.find((a) => zoom >= a[1]);
        if (!level) {
            throw Error(`Level not found for zoom: ${zoom}`);
        }
        return level[0];
    }

    private getNextZoomIn() {
        const levels = this.zoomLevels;
        const currentZoom = this.mapboxService.getZoom();
        const nextLevelIndex = levels.findIndex((z) => z > currentZoom);
        return nextLevelIndex !== -1
            ? levels[nextLevelIndex]
            : currentZoom + 1;
    }

    private getNextZoomOut() {
        const levels = this.zoomLevels;
        const currentZoom = this.mapboxService.getZoom();
        const prevLevelIndex = currentZoom <= this.maxZoomLevel
            ? Math.max(levels.findIndex((z) => z >= currentZoom) - 1, -1)
            : levels.length - 1;
        return prevLevelIndex !== -1
            ? levels[prevLevelIndex]
            : currentZoom - 1;
    }
}
