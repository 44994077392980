import {
    Button, ButtonGroup, CircularProgress, TextField, Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import BugIcon from '@material-ui/icons/BugReportOutlined';
import CheckIcon from '@material-ui/icons/Check';
import BulbIcon from '@material-ui/icons/EmojiObjectsOutlined';
import DotsIcon from '@material-ui/icons/MoreHoriz';
import SendIcon from '@material-ui/icons/Send';
import { FeedbackType } from '@shared/Core/Types';
import { observer } from 'mobx-react-lite';
import React, { FormEventHandler, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { useStores } from '../../Stores/useStores';
import ResponsiveModal from '../Layout/ResponsiveModal';

const FeedbackMessages = defineMessages({
    title: {
        id: 'FEEDBACK_TITLE',
        defaultMessage: 'Zpětná vazba',
    },
});

const FeedbackTitleMessages = defineMessages({
    improvement: {
        id: 'FEEDBACK_TITLE_IDEA_FOR_IMPROVEMENT',
        defaultMessage: 'Popište Váš námět na vylepšení',
    },
    bug: {
        id: 'FEEDBACK_TITLE_BUG',
        defaultMessage: 'Popište nastalou chybu',
    },
    other: {
        id: 'FEEDBACK_TITLE_OTHER',
        defaultMessage: 'Popište, co nám chcete sdělit',
    },
});

const FeedbackPlaceholderMessages = defineMessages({
    improvement: {
        id: 'FEEDBACK_PLACEHOLDER_IDEA_FOR_IMPROVEMENT',
        defaultMessage: 'Váš námět',
    },
    bug: {
        id: 'FEEDBACK_PLACEHOLDER_BUG',
        defaultMessage: 'Hlášení chyby',
    },
    other: {
        id: 'FEEDBACK_PLACEHOLDER_OTHER',
        defaultMessage: 'Cokoli Vás napadne',
    },
});

const FeedbackDescriptionMessages = defineMessages({
    improvement: {
        id: 'FEEDBACK_DESCRIPTION_IDEA_FOR_IMPROVEMENT',
        defaultMessage: ' ',
    },
    bug: {
        id: 'FEEDBACK_DESCRIPTION_BUG',
        defaultMessage: 'Prosím, stručně popište chybu a uveďte kroky, které k této chybě vedou, tak aby jste nám pomohli chybu reprodukovat.',
    },
    other: {
        id: 'FEEDBACK_DESCRIPTION_OTHER',
        defaultMessage: ' ',
    },
});

const emailRegexp = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

const FeedbackView: React.FC = () => {
    const intl = useIntl();
    const { layoutStore, feedbackStore, authStore } = useStores();
    const [emailIsValid, setEmailIsValid] = useState(true);
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    const handleSelectedType = (type: FeedbackType) => () => {
        feedbackStore.setType(type);
    };

    const onContentChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        feedbackStore.setContent(e.target.value);
    };

    const onEmailChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        feedbackStore.setEmail(e.target.value);
    };

    const onEmailBlur = () => {
        const valid = !feedbackStore.email || emailRegexp.test(feedbackStore.email);
        setEmailIsValid(valid);
    };

    const onSubmit: FormEventHandler = (e) => {
        if (e) { e.preventDefault(); }
        feedbackStore.submitFeedback();
    };

    const handleModalClosed = () => {
        feedbackStore.cancelFeedback();
    };

    const canSubmit = feedbackStore.content.length > 0 && emailIsValid;

    return (
        <ResponsiveModal open={ feedbackStore.feedbackActive } title={ intl.formatMessage(FeedbackMessages.title) } onClose={ handleModalClosed }>
            <div className={ classes.inner }>
                { renderInner() }
            </div>
        </ResponsiveModal>
    );

    function renderInner() {
        if (feedbackStore.submitStatus === 'done') {
            return renderSuccess();
        }
        return renderFeedbackForm();
    }

    function renderFeedbackForm() {
        return (
            <>
                <Typography variant="body2" component="p">
                    <FormattedMessage
                        id="FEEDBACK_INTRODUCTION"
                        defaultMessage="Jsme vděčni za jakoukoli zpětnou vazbu, ať už v případě, kdy narazíte na chybu, či máte věcnou připomínku nebo nápad, jak aplikaci dále vylepšit."
                    />
                </Typography>
                <Typography variant="h5" component="h1" className={ classes.title }>
                    <FormattedMessage
                        id="FEEDBACK_TITLE_CHOOSE_TYPE"
                        defaultMessage="Vyberte druh zpětné vazby"
                    />
                </Typography>
                <ButtonGroup fullWidth size="large" aria-label="small contained button group" className={ classes.typeButtons }>
                    <Button color={ feedbackStore.type === 'improvement' ? 'secondary' : 'default' } variant={ feedbackStore.type === 'improvement' ? 'contained' : 'outlined' } className={ classes.typeButton } onClick={ handleSelectedType('improvement') }>
                        <BulbIcon className={ classes.buttonIcon } style={ { color: '#a28f00' } } />
                        <div>
                            <FormattedMessage
                                id="FEEDBACK_CHOICE_REPORT_IDEA_FOR_IMPROVEMENT"
                                defaultMessage="Námět na vylepšení"
                            />
                        </div>
                    </Button>
                    <Button color={ feedbackStore.type === 'bug' ? 'secondary' : 'default' } variant={ feedbackStore.type === 'bug' ? 'contained' : 'outlined' } className={ classes.typeButton } onClick={ handleSelectedType('bug') }>
                        <BugIcon className={ classes.buttonIcon } style={ { color: 'darkred' } } />
                        <div>
                            <FormattedMessage
                                id="FEEDBACK_CHOICE_REPORT_BUG"
                                defaultMessage="Hlášení o chybě"
                            />
                        </div>
                    </Button>
                    <Button color={ feedbackStore.type === 'other' ? 'secondary' : 'default' } variant={ feedbackStore.type === 'other' ? 'contained' : 'outlined' } className={ classes.typeButton } onClick={ handleSelectedType('other') }>
                        <DotsIcon className={ classes.buttonIcon } />
                        <div>
                            <FormattedMessage
                                id="FEEDBACK_CHOICE_REPORT_OTHER"
                                defaultMessage="Jiné"
                            />
                        </div>
                    </Button>
                </ButtonGroup>

                { feedbackStore.type !== null ? (
                    <>
                        <Typography variant="h5" component="h1" className={ classes.title }>{ intl.formatMessage(FeedbackTitleMessages[feedbackStore.type]) }</Typography>
                        <Typography variant="body1" className={ classes.description }>{ intl.formatMessage(FeedbackDescriptionMessages[feedbackStore.type]) }</Typography>
                        <form noValidate autoComplete="off" className={ classes.form } onSubmit={ onSubmit }>
                            <TextField
                                id="outlined-textarea"
                                autoFocus
                                required
                                // label={TEXTS_FEEDBACK_CONTENT[feedbackStore.type].label}
                                placeholder={ intl.formatMessage(FeedbackPlaceholderMessages[feedbackStore.type]) }
                                multiline
                                rows="4"
                                rowsMax="8"
                                margin="normal"
                                variant="outlined"
                                onChange={ onContentChange }
                            />

                            { !authStore.isSignedIn && (
                                <>
                                    <Typography variant="body1" component="p" className={ classes.emailDescription }>
                                        <FormattedMessage
                                            id="FEEDBACK_EMAIL_INFO"
                                            defaultMessage="Pokud si přejete dostat odpověď k Vaší zpětné vazbě, prosím uveďte platný email:"
                                        />
                                    </Typography>
                                    <TextField
                                        id="email"
                                        label={ (
                                            <FormattedMessage
                                                id="FEEDBACK_EMAIL_LABEL"
                                                defaultMessage="Váš email (nepovinné)"
                                            />
                                        ) }
                                        placeholder="john.doe@email.com"
                                        variant="outlined"
                                        onChange={ onEmailChange }
                                        onBlur={ onEmailBlur }
                                        error={ !emailIsValid }
                                        helperText={ !emailIsValid && (
                                            <FormattedMessage
                                                id="FEEDBACK_VALIDATION_INVALID_EMAIL"
                                                defaultMessage="Zadaný email není platný"
                                            />
                                        ) }
                                    />
                                </>
                            ) }
                            <Button
                                className={ classes.submitButton }
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                                disabled={ !canSubmit }
                                onClick={ onSubmit }
                            >
                                <FormattedMessage
                                    id="FEEDBACK_CTA_SEND"
                                    defaultMessage="Odeslat"
                                />
                                { feedbackStore.submitStatus === 'inProgress' ? (
                                    <CircularProgress color="secondary" className={ classes.sendIcon } />
                                ) : (
                                    <SendIcon className={ classes.sendIcon } />
                                ) }
                            </Button>
                        </form>
                    </>
                ) : (
                    <Button variant="contained" color="primary" className={ classes.closeButton } onClick={ handleModalClosed }>
                        <FormattedMessage
                            id="FEEDBACK_CANCEL_BUTTON"
                            defaultMessage="Zrušit"
                        />
                    </Button>
                ) }
            </>
        );
    }

    function renderSuccess() {
        return (
            <>
                <Typography variant="h5" className={ classes.mesage }>
                    <CheckIcon />
                    <FormattedMessage
                        id="FEEDBACK_SENT_MESSAGE"
                        defaultMessage="Děkujeme za Vaši zpětnou vazbu!"
                    />
                </Typography>
                <Button variant="contained" color="primary" className={ classes.closeButton } onClick={ handleModalClosed }>
                    <FormattedMessage
                        id="FEEDBACK_CLOSE_BUTTON"
                        defaultMessage="Zavřít"
                    />
                </Button>
            </>
        );
    }
};

export default observer(FeedbackView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    inner: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: params.isMobile ? '20vh' : undefined,
    }),
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
    },
    typeButtons: {
        margin: theme.spacing(2, 0),
    },
    typeButton: {
        '& > span': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    description: {
        textAlign: 'center',
    },
    emailDescription: {
        textAlign: 'center',
        marginBottom: theme.spacing(1.5),
    },
    sendIcon: {
        marginLeft: theme.spacing(1),
    },
    mesage: {
        padding: theme.spacing(2, 0),
    },
    closeButton: {
        margin: theme.spacing(2, 0),
    },
    buttonIcon: {
        margin: theme.spacing(1, 0),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
}));
