import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { useStores } from '../../Stores/useStores';

import DrawerHandle from './DrawerHandle';

type ContentDrawerProps = {
    backgroundComponent: React.ReactNode,
    fill?: boolean,
};

const ContentDrawer: React.FC<ContentDrawerProps> = ({ fill, backgroundComponent, children }) => {
    const history = useHistory();
    const { layoutStore } = useStores();
    const classes = useStyles({ fill });
    const containerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const unregisterListener = history.listen(() => {
            containerRef.current!.scroll({ top: 0 });
        });
        layoutStore.setDrawerIsScrolled(false);

        return () => {
            unregisterListener();
        };
    }, []);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (event.currentTarget.scrollTop > 0) {
            layoutStore.setDrawerIsScrolled(true);
        } else {
            layoutStore.setDrawerIsScrolled(false);
        }
    };

    const handleScrollArrowClick = () => {
        if (containerRef.current) {
            containerRef.current.scroll({ top: window.screen.availHeight * 0.50, behavior: 'smooth' });
        }
    };

    return (
        <div className={ fill ? classes.rootFill : classes.root }>
            <div
                ref={ containerRef }
                onScroll={ handleScroll }
                className={ classes.container }
            >
                <div className={ classes.backgroundComponent }>
                    { backgroundComponent }
                </div>
                { !fill && (
                    <div className={ classes.padding } />
                ) }
                <Box boxShadow={ 4 } className={ classes.content }>
                    <>
                        { !fill && (
                            <DrawerHandle />
                        ) }
                        { children }
                    </>
                </Box>
            </div>
            { !fill && layoutStore.drawerScrollButtonVisible && (
                <div className={ classes.scrollArrowContainer }>
                    <Box boxShadow={ 5 } className={ classes.scrollArrow } onClick={ handleScrollArrowClick }>
                        <Typography variant="caption" style={ { marginBottom: 5 } }>{ layoutStore.drawerScrollTitle }</Typography>
                        <KeyboardArrowDownIcon style={ { fontSize: 32, alignSelf: 'flex-end' } } />
                    </Box>
                </div>
            ) }
        </div>
    );
};

export default observer(ContentDrawer);

const FILL_HEIGHT = '60vh';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        pointerEvents: 'none',
        position: 'relative',
        height: '100%',
        width: '100vw',
        display: 'flex',
        alignItems: 'stretch',
        top: 0,
    },
    rootFill: {
        pointerEvents: 'all',
        position: 'relative',
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'stretch',
    },
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        pointerEvents: 'all',
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    padding: {
        pointerEvents: 'none',
        height: FILL_HEIGHT,
        minHeight: FILL_HEIGHT,
        visibility: 'hidden',
    },
    content: () => ({
        position: 'relative',
        backgroundColor: 'white',
        width: '100%',
        flexGrow: 1,
        willChange: 'transform',
        touchAction: 'manipulation',
        backfaceVisibility: 'hidden',
        paddingBottom: 320, // TODO temporary fix
    }),
    backgroundComponent: {
        pointerEvents: 'all',
        position: 'fixed',
        width: '100%',
        height: `calc(${FILL_HEIGHT} - 56px)`,
        top: 56,
        left: 0,
    },
    scrollArrowContainer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.86) 60%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)',
        // paddingBottom: theme.spacing(1),
    },
    scrollArrow: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 3, 0, 3),
        borderTopRightRadius: 22,
        borderTopLeftRadius: 22,
        // width: 28,
        height: 28,
        pointerEvents: 'all',
    },
}));
