import { defineMessages } from 'react-intl';

export const signInMessages = defineMessages({
    tabSignIn: {
        id: 'SIGNIN_TAB_SIGNIN',
        defaultMessage: 'Přihlášení',
    },
    tabSignUp: {
        id: 'SIGNIN_TAB_SIGNUP',
        defaultMessage: 'Registrace',
    },
    emailSignInButton: {
        id: 'SIGNIN_EMAIL_SIGNIN_BUTTON',
        defaultMessage: 'Přihlásit se',
    },
    emailSignUpButton: {
        id: 'SIGNUP_SUBMIT_BUTTON',
        defaultMessage: 'Registrovat se',
    },
    linkEmailConfirmPasswordButton: {
        id: 'SIGNIN_LINKEMAIL_CONFIRM_PASSWORD_BUTTON',
        defaultMessage: 'Potvrdit heslo',
    },
    inputEmailLabel: {
        id: 'INPUT_EMAIL_LABEL',
        defaultMessage: 'Email',
    },
    inputPasswordLabel: {
        id: 'INPUT_PASSWORD_LABEL',
        defaultMessage: 'Heslo',
    },
    inputPasswordCheckLabel: {
        id: 'INPUT_PASSWORDCHECK_LABEL',
        defaultMessage: 'Heslo znovu',
    },
    privacyConsent: {
        id: 'SIGNUP_EMAIL_PRIVACY_CONSENT_CHECKBOX',
        defaultMessage: 'Souhlasím se zpracováním a uchováváním poskytnutých osobních údajů spolkem **Ukliďme Česko z.s.**. Více informací naleznete [zde](https://www.uklidmecesko.cz/gdpr/).',
    },
});
