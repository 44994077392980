import { Button, Grid, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useSignInService } from '../../hooks/useSIgnInService';

import EmailInput from './EmailInput';
import SignInContent from './SignInContent';

type SignInForgotPasswordProps = {
};

const SignInForgotPassword: React.FC<SignInForgotPasswordProps> = (props) => {
    const classes = useStyles();
    const signInSvc = useSignInService();

    const form = useForm({ mode: 'onBlur' });
    const { handleSubmit } = form;

    const handleSubmitEvent = async (data: any) => {
        console.log(data);
        signInSvc.resetPassword(data.email);
    };

    return (
        <form onSubmit={ handleSubmit(handleSubmitEvent) } noValidate>
            <SignInContent>
                <Grid container className={ classes.root }>
                    <Grid item xs={ 12 }>
                        <Typography variant="h5">
                            <FormattedMessage
                                id="SIGNIN_FORGOT_PASSWORD_TITLE"
                                defaultMessage="Obnova hesla"
                            />
                        </Typography>
                        <Typography variant="caption">
                            <FormattedMessage
                                id="SIGNIN_FORGOT_PASSWORD_HELPER_TEXT"
                                defaultMessage="Zadejte email, pro který chcete heslo obnovit. Po potvrzení Vám bude poslán email s instrukcemi."
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={ 12 }>
                        <EmailInput
                            form={ form }
                            disableAutocomplete
                        />
                    </Grid>
                    <Grid item xs={ 12 } className={ classes.submitButton }>
                        <Button variant="contained" color="secondary" fullWidth type="submit">
                            <FormattedMessage
                                id="SIGNIN_FORGOT_PASSWORD_SUBMIT"
                                defaultMessage="Obnovit heslo"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </SignInContent>
        </form>
    );
};

export default SignInForgotPassword;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    submitButton: {
        marginTop: theme.spacing(4),
    },
}));
