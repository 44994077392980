import { defaultLocale, languagesRouteRegex } from '../languages';

const regex = new RegExp(`^/(${languagesRouteRegex})(\/+.*|$)`);
const replaceRegex = new RegExp(`^/(${languagesRouteRegex})`);

export function changeLocale(locale: string) {
    let path = window.location.pathname;
    if (locale === defaultLocale) {
        path = path.replace(replaceRegex, '');
    } else if (regex.test(path)) {
        path = path.replace(replaceRegex, locale);
    } else {
        path = `/${locale}${path}`;
    }

    window.location.pathname = path;
	if (window.hasOwnProperty('ReactNativeWebView') && typeof window?.ReactNativeWebView !== 'undefined') {
		window.ReactNativeWebView.postMessage(JSON.stringify({event: "changeLanguage", language: locale}));
	}
}
