import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

type MenuButtonProps = {
    className?: string;
    edge?: 'start' | 'end' | false;
};

const MenuButton: React.FC<MenuButtonProps> = ({ className, edge }) => {
    const { homeStore } = useStores();
    const onClick = () => {
        homeStore.setMainMenuOpen(true);
    };

    return (
        <IconButton
            edge={ edge }
            className={ className }
            color="inherit"
            aria-label="open drawer"
            onClick={ onClick }
        >
            <MenuIcon />
        </IconButton>
    );
};

export default observer(MenuButton);
