import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import Configuration from '../Models/Configuration';
import CachedObject from '../Utils/cachedObject';

import ApiService from './ApiService';


@injectable()
export default class ConfigurationService {
    private api = IocContainer.get(ApiService);

    private cachedConfiguration = new CachedObject<Configuration>();

    public async getConfiguration(): Promise<Configuration> {
        await this.cachedConfiguration.update(() => this.api.getConfiguration());
        return this.cachedConfiguration.getData()!;
    }
}
