import { Button, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSignInService } from '../../hooks/useSIgnInService';
import { PromptUserPasswordCallback } from '../../services/SignInService';

import EmailSingInForm from './EmailSignInForm';
import SignInContent from './SignInContent';
import { signInMessages } from '../../messages/signInMessages';

type LinkEmailFormProps = {
    onError: (err: Error) => void;
    linkError: firebase.auth.AuthError;
};

type PromptCallbackOptions = {
    resolve: (password: string) => void;
    reject: () => void;
}

const LinkEmailForm: React.FC<LinkEmailFormProps> = ({
    linkError,
    //  onSubmit,
    onError,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const signInSvc = useSignInService();
    const [promptForPasswordOptions, setPromptForPasswordOptions] = useState<PromptCallbackOptions>();
    const [email, setEmail] = useState<string>();

    useEffect(() => {
        handleLinkLogic();
    }, []);

    const handleSubmitEvent = async (data: any) => {
        if (!promptForPasswordOptions) {
            throw Error('Password has not been prompted.');
        }

        promptForPasswordOptions.resolve(data.password);
    };

    const handleCancelProcess = () => {
        if (promptForPasswordOptions) {
            promptForPasswordOptions.reject();
        }
    };

    const propmtForPassword: PromptUserPasswordCallback = (emailPreset, resolve, reject) => {
        setEmail(emailPreset);
        setPromptForPasswordOptions({ resolve, reject });
    };

    if (!promptForPasswordOptions || !email) {
        return (
            <div>
                <FormattedMessage
                    id="SIGNIN_LINKEMAIL_INPROGRESS"
                    defaultMessage="Čekejte prosím..."
                />
            </div>
        );
    }

    return (
        <SignInContent>
            <Typography variant="body1" className={ classes.caption }>
                <FormattedMessage
                    id="SIGNIN_LINKEMAIL_DESCRIPTION"
                    defaultMessage="Pro daný email již existuje přihlášení pomocí hesla, prosím, nejprve potvrďte heslo abychom mohli účty propojit."
                />
            </Typography>
            <EmailSingInForm
                presetEmail={ email }
                submitButtonTitle={ intl.formatMessage(signInMessages.linkEmailConfirmPasswordButton) }
                onSubmit={ handleSubmitEvent }
            />
            <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={ handleCancelProcess }
                className={ classes.cancelButton }
            >
                <FormattedMessage
                    id="SIGNIN_LINKEMAIL_BACK_TO_SIGNIN_BUTTON"
                    defaultMessage="Zpět na přihlášení"
                />
            </Button>
        </SignInContent>
    );

    async function handleLinkLogic() {
        try {
            const success = await signInSvc.handleAccountExistsWithDifferentCredential(linkError, propmtForPassword, onError);

            if (success) {
                // success, redirect to landing
                window.location.href = '/';
            } else {
                // failed, redirect back to signin
                window.location.href = '/signIn';
            }
        } catch (err) {
            onError(err);
        }
    }
};

export default LinkEmailForm;

const useStyles = makeStyles((theme: Theme) => createStyles({
    caption: {
        marginBottom: theme.spacing(4),
    },
    cancelButton: {
        marginTop: theme.spacing(4),
    },
}));
