import { CircularProgress } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const Loader: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={ classes.root }>
            <CircularProgress />
        </div>
    );
};

export default Loader;

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(4, 0),
    },
}));
