import * as React from "react";
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

type BoxIcoProps = {
    color?: string;
	size?: number,
};

function jeBarvaSvetla(rgbKod: string): boolean {
  // Získání hodnot červené, zelené a modré složky z RGB kódu
  const r: number = parseInt(rgbKod.substring(0, 2), 16);
  const g: number = parseInt(rgbKod.substring(2, 4), 16);
  const b: number = parseInt(rgbKod.substring(4, 6), 16);

  // Výpočet průměru hodnot červené, zelené a modré složky
  const prumer: number = (r + g + b) / 3;

  // Rozhodnutí, zda je barva světlá nebo tmavá
  return prumer > 128;
}

const BoxIco: React.FC<BoxIcoProps> = ({ color='', size=56 }) => {
	const classes = makeStyles((theme) => createStyles({
		background: {
			fill: (jeBarvaSvetla(color) ? '#888888' : '#ffffff')
		},
		color: {
			fill: (color !== '' ? '#'+color : '#00A4E1')
		},
	}))();

    return (

	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={`${size}px`} height={`${size}px`}
	 viewBox={`0 0 270 270`} xmlSpace="preserve">
	 <defs>
		<clipPath id="052e06dfa3"><path d="M 0 0.0585938 L 224.761719 0.0585938 L 224.761719 224.9375 L 0 224.9375 Z M 0 0.0585938 " clipRule="nonzero"/></clipPath>
		<clipPath id="6f76527084"><path d="M 10.914062 9.035156 L 214 9.035156 L 214 216.121094 L 10.914062 216.121094 Z M 10.914062 9.035156 " clipRule="nonzero"/></clipPath>
		<clipPath id="edb65ac3bb"><path d="M 127.203125 98.734375 L 194.667969 98.734375 L 194.667969 166.199219 L 127.203125 166.199219 Z M 127.203125 98.734375 " clipRule="nonzero"/></clipPath>
	 </defs>
	 <g clipPath="url(#052e06dfa3)">
		<path  className={classes.background} d="M 0 0.0585938 L 224.878906 0.0585938 L 224.878906 224.941406 L 0 224.941406 Z M 0 0.0585938 " fillOpacity="1" fillRule="nonzero"/>
		<path  className={classes.background} d="M 0 0.0585938 L 224.878906 0.0585938 L 224.878906 224.941406 L 0 224.941406 Z M 0 0.0585938 " fillOpacity="1" fillRule="nonzero"/>
	 </g>
	 <g clipPath="url(#6f76527084)">
		<path  className={classes.color} d="M 213.757812 49.023438 L 213.890625 48.65625 L 112.5 9.035156 L 11.261719 49.066406 L 10.921875 48.933594 L 10.921875 49.191406 L 10.914062 49.199219 L 10.921875 49.242188 L 10.921875 176.328125 L 112.449219 216.121094 L 213.984375 176.316406 L 213.984375 48.933594 Z M 112.398438 16.828125 L 146.796875 29.148438 L 53.03125 65.546875 L 21.28125 53.019531 Z M 108.835938 207.148438 L 70.8125 191.789062 L 51.730469 184.621094 L 18.179688 171.386719 L 18.179688 59.601562 L 51.390625 72.699219 L 51.390625 100.648438 L 54.347656 99.828125 L 56.347656 102.566406 L 59.128906 101.921875 L 61.828125 104.777344 L 64.179688 103.953125 L 67.980469 107.722656 L 67.980469 79.242188 L 70.933594 80.410156 L 108.835938 95.359375 Z M 112.460938 88.996094 L 75.378906 74.363281 L 166.816406 37.300781 L 204.53125 52.671875 Z M 206.738281 171.386719 L 116.082031 207.148438 L 116.082031 95.359375 L 206.726562 59.589844 L 206.726562 171.386719 Z M 206.738281 171.386719 " fillOpacity="1" fillRule="nonzero"/>
	</g>
	</svg>

    );
};

export default BoxIco;


