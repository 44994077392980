import { Typography, Link } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React, { AnchorHTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';

type MarkDownProps = {
    source: string,
};

const MarkDownLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ children, href, ...props }) => {
    const { getLinkTo, getHomePath: getRootPath } = useHomeNavigation();
    if (href && href.startsWith('/')) {
        href = getLinkTo(href);
    }
    return (
        <RouterLink to={ href ?? getRootPath() }>
            <Link href={ href } { ...(props as any) }>{ children }</Link>
        </RouterLink>
    );
};

const MarkDown: React.FC<MarkDownProps> = ({ source }) => {
    const classes = useStyles();

    return (
        <ReactMarkdown
            className={ classes.markdownContent }
            source={ source }
            renderers={ {
                paragraph: ({ children }) => (
                    <Typography variant="body2" component="p">
                        { children }
                    </Typography>
                ),
                link: (props) => <MarkDownLink { ...props } />,
            } }
            escapeHtml={ false }
            linkTarget={ (uri) => {
                if (uri.startsWith('/')) {
                    return '_self';
                }
                return '_blank';
            } }
        />
    );
};

export default MarkDown;

const useStyles = makeStyles((theme: Theme) => createStyles({
    markdownContent: {
        padding: theme.spacing(0),
        // marginLeft: theme.spacing(8),
        textAlign: 'justify',
        '& > p > a > span > img': {
            width: '100%',
        },
        '& > p': {
            margin: 0,
        },
        '& a': {
            color: theme.palette.primary.main,
        },
    },
}));
