import './Setup';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import IocContainer from '@shared/Core/IocContainer';
import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '../../shared/src/Core/Services/IAnalyticsLogger';

import App from './App';
import { languagesRouteRegex, defaultLocale } from './languages';
import FirebaseService from './Services/FirebaseService';
import * as serviceWorker from './serviceWorker';
import { createPreserveQueryHistory } from './utils/preserveQueryHistory';

import TagManager from 'react-gtm-module'

IocContainer.get(FirebaseService).initialize();
IocContainer.get<IAnalyticsLogger>(IAnalyticsLogger_TYPE).initialize();

// connect react router to mobx
const routerStore = IocContainer.get(RouterStore);
const browserHistory = createPreserveQueryHistory(createBrowserHistory, ['androidApp', 'iosApp'])();
const history = syncHistoryWithStore(browserHistory, routerStore);

const appsignal = new Appsignal({
    key: process.env.REACT_APP_APPSIGNAL_API_KEY,
});

const tagManagerArgs = {
    gtmId: 'GTM-KQZ77KL7'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
    <ErrorBoundary
        instance={ appsignal }
    >
        <Router history={ history }>
            <Switch>
                <Route path={ `/${defaultLocale}` }>
                    <Redirect to="/" />
                </Route>
                <Route path={ `/:locale(${languagesRouteRegex})` } component={ App } />
                <Route path="/" component={ App } />
            </Switch>
        </Router>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
