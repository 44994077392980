import {
    Typography, AppBar, Toolbar, IconButton, Tabs, Tab
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useHomeNavigation } from '../../Hooks/useHomeNavigation';
import { useRootNavigation } from '../../Hooks/useRootNavigation';
import { useStores } from '../../Stores/useStores';
// import BetaBadge from '../Common/BetaBadge';
import LogoDefault from '../LogoDefault';

type Pages = 'partners' | 'media' | 'contact' | 'organization';

type PageProps = {
    currentPage: Pages;
};

const Page: React.FC<PageProps> = ({ currentPage, children }) => {
    const { layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });
    const [value] = React.useState(currentPage);
    const { navigateTo } = useRootNavigation();
    const homeNav = useHomeNavigation();

    const onBackClicked = () => {
        homeNav.navigateTo(`/`);
    };

    function handleChange(_: React.ChangeEvent<{}>, newValue: Pages) {
        navigateTo(`/${newValue}`);
    }

    return (
        <div className={ classes.root }>
            <div className={ classes.logoContainer }>
                <LogoDefault height={ 64 } />
            </div>
            <AppBar position="static" color="primary">

                <Toolbar className={ classes.toolbar }>
                    <div className={ classes.menuButtonContainer }>
                        <IconButton edge="start" className={ classes.menuButton } onClick={ onBackClicked } color="inherit" aria-label="back">
                            <ArrowBackIcon />
                        </IconButton>
                        { !layoutStore.isMobile && (
                            <Typography variant="button">
                                <FormattedMessage
                                    id="PAGES_CTA_NAVIGATE_BACK_TO_APP"
                                    defaultMessage="Zpět do aplikace"
                                />
                            </Typography>
                        ) }
                    </div>
                    <Tabs value={ value } onChange={ handleChange } className={ classes.tabs }>
                        <Tab
                            label={ (
                                <FormattedMessage
                                    id="PAGES_PARTNERS_TITLE"
                                    defaultMessage="Partneři"
                                />
                            ) }
                            value="partners"
                            selected={ value === 'partners' }
                        />
                        <Tab
                            label={ (
                                <FormattedMessage
                                    id="PAGES_MEDIA_TITLE"
                                    defaultMessage="Mediální partneři"
                                />
                            ) }
                            value="media"
                            selected={ value === 'media' }
                        />
                        <Tab
                            label={ (
                                <FormattedMessage
                                    id="PAGES_ORGANIZATION_TITLE"
                                    defaultMessage="O Ukliďme Česko"
                                />
                            ) }
                            value="organization"
                            selected={ value === 'organization' }
                        />
                        <Tab
                            label={ (
                                <FormattedMessage
                                    id="PAGES_CONTACT_TITLE"
                                    defaultMessage="Kontakt"
                                />
                            ) }
                            value="contact"
                            selected={ value === 'contact' }
                        />
                    </Tabs>
                </Toolbar>
            </AppBar>
            <div className={ classes.content }>
                { children }
            </div>
        </div>
    );
};

export default observer(Page);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        // backgroundColor: 'whitesmoke'
    },
    content: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        ...(params.isMobile ? {
            padding: theme.spacing(2, 0, 2, 0),
        } : {
            padding: theme.spacing(4),

        }),
    }),
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
    logoContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(2),
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    tabs: {
    },
    menuButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));
