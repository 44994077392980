import {
    Typography, Button, IconButton, Avatar, ListItemAvatar, ListItem, ListItemText, Link
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import { useStores } from '../../../Stores/useStores';
import MarkDown from '../../Common/MarkDown';
import MarkDown2 from '../../Common/MarkDown2';
import Panel from '../../Common/Panel';
import FeedbackButton from '../../Feedback/FeedbackButton';

const AboutViewMessages = defineMessages({
    aboutContentTitlex: {
        id: 'ABOUT_CONTENT_TITLEX',
        defaultMessage: 'Kam s ním? Víme…',
    },
    aboutContentIntrox: {
        id: 'ABOUT_CONTENT_INTROX',
        defaultMessage: `„Kam s ním?“ je neziskový projekt [spolku **Ukliďme Česko**](/organization). Nabízí snadné a rychlé vyhledání míst ve vašem okolí, kde se můžete legálně ""zbavit"" nechtěných věcí a odpadů.
Projekt „Kam s ním?“ **poradí, jak správně s nechtěnými věcmi naložit** resp. kde lze legálně a v drtivé většině i bezplatně odevzdat jak běžný odpad z domácnosti, tak například prošlé léky, pneumatiky, vysloužilé elektro, baterie, světelné zdroje, velkoobjemový odpad, nebo třeba i odpady nebezpečné. A nejen to. Obsahuje i provozní doby sběrných středisek či míst zpětného odběru, včetně možnosti navigace na tato místa, která lze jednoduše vyhledat a zabrazit v mapě, která **obsahuje celkem přes 90 000 odběrných míst** a další budou průběžně přibývat."`,
    },

    aboutContentCitationx: {
        id: 'ABOUT_CONTENT_CITATIONX',
        defaultMessage: `„Před šesti lety jsme založili s kolegy tradici dobrovolnických úklidů pod názvem **Ukliďme Česko**, která se stala fenoménem s více než **150 000 zapojených dobrovolníků v roce 2019**. A ani po letech nás nepřestává překvapovat, co vše jsou lidé schopni vyhodit do přírody.

Naším cílem však není donekonečna uklízet nepořádek po jiných. Z toho důvodu jsme **spustili projekt „Kam s ním?“**, pro ty, kteří by snad i dnes, v době sběrných středisek a všudypřítomných kontejnerů na tříděný odpad nevěděli…

Věřím, že se nám tímto projektem podaří **omezit množství odpadu**, který končí mimo systém zpětného odběru resp. na skládkách, ve spalovnách, v nejhorším případě na černých skládkách v přírodě."`,
    },
    aboutContentProviderLabel: {
        id: 'ABOUT_CONTENT_PROVIDER_LABEL',
        defaultMessage: 'Provozovatel: ',
    },
    // aboutContentCookieConsent: {
    //     id: 'ABOUT_CONTENT_COOKIE_CONSENT',
    //     defaultMessage: 'Webová aplikace používá k poskytování služeb a analýze návštěvnosti <link>cookies</link>. Pokračováním s tímto souhlasíte.',
    // },
    // aboutCtaStart: {
    //     id: 'ABOUT_CTA_START',
    //     defaultMessage: 'Začít pracovat s aplikací',
    // },
    aboutContentBetaTitlex: {
        id: 'ABOUT_CONTENT_BETA_TITLEX',
        defaultMessage: `Aplikace ve vývoji`    },
    aboutContentBetaIntrox: {
        id: 'ABOUT_CONTENT_BETA_INTROX',
        defaultMessage: `Aplikace byla spuštěna na začátku roku 2020. Databáze prozatím obsahuje místa pro nejčastější druhy odpadů (kontejnerová stání jsou dostupná pouze pro Prahu a Brno). Postupně budou přibývat další města a sběrná místa pro další druhy odpadů. Děkujeme všem, kteří nám poskytli svá data.
Projekt má naplánovány další fáze rozvoje jako jazykové mutace, priority pro využívání vyhledaných komodit (reuse, recyklace, upcyklace..), interaktivní aktualizace dat o sběrných místech (zpětná vazba od uživatelů), včetně plánovaného využití umělé inteligence.`    },
    aboutContentBetaFeedbackx: {
        id: 'ABOUT_CONTENT_BETA_FEEDBACKX',
        defaultMessage: `V rámci zlepšování aplikace budeme vděčni za jakoukoli zpětnou vazbu, ať už v případě, kdy narazíte na chybu, či máte věcnou připomínku nebo nápad, jak aplikaci dále vylepšit…`,
    },
});

const AboutView: React.FC = () => {
    const intl = useIntl();
    const { layoutStore } = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });
    const { navigateTo } = useHomeNavigation();

    React.useEffect(() => {
        layoutStore.setMobilePanelFullscreen(true);
    }, []);

    const onStartClicked = () => {
        navigateTo('/');
    };

    return (
        <Panel>
            <div className={ classes.root }>
                <div className={ classes.header }>
                    <Typography variant="h6" component="h1">
                        { intl.formatMessage(AboutViewMessages.aboutContentTitlex) }
                    </Typography>

                    <IconButton className={ classes.closeButton } onClick={ onStartClicked }>
                        <CloseIcon />
                    </IconButton>
                </div>

                <MarkDown source={ intl.formatMessage(AboutViewMessages.aboutContentIntrox) } />


                <Typography
                    component="p"
                    variant="body2"
                    className={ classes.citation }
                >
                    <MarkDown2 source={ intl.formatMessage(AboutViewMessages.aboutContentCitationx) } />
                </Typography>
                <Avatar alt="Miroslav Kubásek" src="https://cdn.kamsnim.cz/images/mk-foto2.jpg" className={ classes.large } />

                <Typography
                    component="div"
                    variant="body2"
                    align="center"
                >
                    <Link
                        component="a"
                        href="https://www.facebook.com/KubasekMiroslav"
                        target="_blank"
                    >
                        <strong>Miroslav Kubásek</strong>
                    </Link>
                    <br />
                    <em>autor projektu Kam s ním?</em>

                </Typography>




                { /*
                <Typography variant="body2" component="div">
                    { intl.formatMessage(AboutViewMessages.aboutContentProviderLabel) }
                    { ' ' }
                    <Link
                        component="a"
                        href="https://www.uklidmecesko.cz"
                        target="_blank"
                    >
                        <strong>Ukliďme Česko z.s.</strong>
                    </Link>
                </Typography>
                */ }

                { /* <Typography variant="caption" component="div" className={ classes.cookies }>
                    { intl.formatMessage(AboutViewMessages.aboutContentCookieConsent, {
                        link: (...chunks: React.ReactNode[]) => (
                            <Link
                                component="a"
                                href="https://policies.google.com/technologies/cookies?hl=cs"
                                target="_blank"
                            >
                                { chunks }
                            </Link>
                        ),
                    }) }
                </Typography>

                <Button variant="contained" color="secondary" onClick={ onStartClicked }>
                    { intl.formatMessage(AboutViewMessages.aboutCtaStart) }
                </Button> */ }
                <div className={ classes.divider } />
				{/**
                <Typography variant="h6" component="h1">
                    { intl.formatMessage(AboutViewMessages.aboutContentBetaTitlex) }
                </Typography>
                <Typography variant="body1" component="p">
                    { intl.formatMessage(AboutViewMessages.aboutContentBetaIntrox) }
                </Typography>
				**/
				}
                <Typography variant="body1" component="p">
                    { intl.formatMessage(AboutViewMessages.aboutContentBetaFeedbackx) }
                </Typography>
                <FeedbackButton />
            </div>
        </Panel>
    );
};

export default observer(AboutView);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 3, 3, 3),

        '& p': {
            // margin: theme.spacing(2, 0, 2, 0),
            marginBottom: theme.spacing(1.5),
            textAlign: 'justify',
        },
        '& h1': {
            marginBottom: theme.spacing(1.5),
        },
        ...(!params.isMobile ? {
            marginTop: theme.spacing(3),
            // paddingTop: theme.spacing(4),
            borderTop: '1px solid lightgray',
        } : {
            marginTop: theme.spacing(0),
        }),
    }),
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    closeButton: () => ({
        // alignSelf: 'flex-end',
        position: 'absolute',
        right: theme.spacing(1.5),
        top: theme.spacing(1),
        // ...(params.isMobile ? {
        //     top: theme.spacing(-3)
        // } : {
        //         top: theme.spacing(1)
        //     })

    }),
    divider: {
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(24),
        height: theme.spacing(24),
    },
    citation: {
        fontStyle: 'italic',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(2.5),
        textAlign: 'justify',
    },
    listItem: {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(0.5),
    },
    cookies: {
        textAlign: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: '#424242',
    },
}));
