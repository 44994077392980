import { LinearProgress } from '@material-ui/core';
import {
    createStyles, makeStyles, withStyles
} from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

const BorderLinearProgress = withStyles({
    root: {
        height: 3,
    },
    bar: {
        borderRadius: 20,
    },
})(LinearProgress);

const MainLoader: React.FC = () => {
    const { homeStore } = useStores();
    const classes = useStyles();

    if (homeStore.isLoading) {
        return (
            <BorderLinearProgress className={ classes.linearLoader } />
        );
    }
    return null;
};

export default observer(MainLoader);

const useStyles = makeStyles(() => createStyles({
    linearLoader: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        zIndex: 200,
    },
}));
