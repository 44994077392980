import { defineMessages } from "react-intl";

export const userInputMessages = defineMessages({
    inputNicknameLabel: {
        id: 'INPUT_NICKNAME_LABEL',
        defaultMessage: 'Přezdívka',
    },
    inputFirstNameLabel: {
        id: 'INPUT_FIRSTNAME_LABEL',
        defaultMessage: 'Jméno',
    },
    inputLastNameLabel: {
        id: 'INPUT_LASTNAME_LABEL',
        defaultMessage: 'Příjmení',
    },
});