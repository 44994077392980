import React from 'react';
import { Redirect, useRouteMatch } from 'react-router';

import { trimPathEnd } from '../utils/trimPath';

type NotFoundRouteProps = {
};

const NotFoundRoute: React.FC<NotFoundRouteProps> = () => {
    const { path } = useRouteMatch();

    const rootPath = trimPathEnd(path);
    return (
        <Redirect to={ {
            pathname: `${rootPath}`,
            state: { error404: true },
        } }
        />
    );
};

export default NotFoundRoute;
