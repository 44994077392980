import {
    ListItemText, List, ListItem, ListItemIcon, Divider
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PinIcon from '@material-ui/icons/LocationOn';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { LocationSearchItem } from '../../../../../shared/src/Core/Models/LocationSearchItem';
import { useHomeNavigation } from '../../../Hooks/useHomeNavigation';
import { useStores } from '../../../Stores/useStores';
import { loadRecentItems, insertRecentItem } from '../../../utils/recentLocationsStorage';
import Loader from '../../Common/Loader';
import SearchBar from '../../Common/SearchBar';
import ResponsiveModal from '../../Layout/ResponsiveModal';
import ResponsiveToolbar from '../../Layout/ResponsiveToolbar';

const Messages = defineMessages({
    title: {
        id: 'SEARCH_LOCATION_TITLE',
        defaultMessage: 'Zvolit polohu',
    },
    placeholder: {
        id: 'SEARCH_LOCATION_PLACEHOLDER',
        defaultMessage: 'Napište název obce nebo adresu',
    },
    myLocation: {
        id: 'SEARCH_LOCATION_MY_LOCATION',
        defaultMessage: 'Moje poloha',
    },
    pickLocation: {
        id: 'SEARCH_LOCATION_PICK_LOCATION',
        defaultMessage: 'Zvolit libovolnou polohu na mapě',
    },
    pickLocationInfo: {
        id: 'SEARCH_LOCATION_PICK_LOCATION_INFO',
        defaultMessage: 'Kliknutím do mapy zvolíte libovolnou polohu',
    },
});

type SearchLocationModalProps = {
};

const SearchLocationModal: React.FC<SearchLocationModalProps> = observer(() => {
    const intl = useIntl();
    const nav = useHomeNavigation();
    const { enqueueSnackbar } = useSnackbar();
    const { navigateToMunicipality } = useHomeNavigation();
    const { layoutStore, homeStore, searchMunicipalityStore } = useStores();
    const classes = useStyles({
        isMobile: layoutStore.isMobile,
    });

    const recentItems = useMemo(() => loadRecentItems(), [searchMunicipalityStore.modalOpen]);

    const handleModalClosed = () => {
        searchMunicipalityStore.closeModal();
    };

    const handleSearchTermChanged = (value: string) => {
        searchMunicipalityStore.setSearchTerm(value);
    };

    const handleSelectItem = (item: LocationSearchItem) => () => {
        switch (item.type) {
        case 'municipality':
            navigateToMunicipality(item.id);
            break;
        case 'address':
            homeStore.setSelectedMapLocation(item.location);
            onItemSelected();
            break;
        default: throw Error('Invalid item type');
        }
        searchMunicipalityStore.closeModal();
        insertRecentItem(item);
    };

    const handleSelectMyLocation = () => {
        homeStore.focusUserLocation();
        searchMunicipalityStore.closeModal();
        onItemSelected();
    };

    const handleSelectLocationOnMap = () => {
        searchMunicipalityStore.closeModal();
        enqueueSnackbar(intl.formatMessage(Messages.pickLocationInfo), { variant: 'info' });
        onItemSelected();
    };

    return (
        <ResponsiveModal
            noPadding
            open={ searchMunicipalityStore.modalOpen }
            title={ intl.formatMessage(Messages.title) }
            onClose={ handleModalClosed }
            toolbarComponent={ renderToolbar() }
        >
            <div className={ classes.inner }>
                { renderInner() }
            </div>
        </ResponsiveModal>
    );

    function renderToolbar() {
        return (
            <div className={ classes.toolbar }>
                <ResponsiveToolbar>
                    <SearchBar
                        placeholder={ intl.formatMessage(Messages.placeholder) }
                        value={ searchMunicipalityStore.searchTerm }
                        onChanged={ handleSearchTermChanged }
                    />
                </ResponsiveToolbar>
            </div>
        );
    }

    function renderInner() {
        return (
            <List>
                { !searchMunicipalityStore.searchTerm && (
                    <>
                        <ListItem button onClick={ handleSelectMyLocation }>
                            <ListItemIcon>
                                <MyLocationIcon />
                            </ListItemIcon>
                            <ListItemText>
                                { intl.formatMessage(Messages.myLocation) }
                            </ListItemText>
                        </ListItem>
                        <ListItem button onClick={ handleSelectLocationOnMap }>
                            <ListItemIcon>
                                <PinIcon />
                            </ListItemIcon>
                            <ListItemText>
                                { intl.formatMessage(Messages.pickLocation) }
                            </ListItemText>
                        </ListItem>
                        <Divider />
                        { recentItems.length > 0 && (
                            <>
                                { recentItems.map((recentItem) => (
                                    <ListItem button key={ recentItem.id } onClick={ handleSelectItem(recentItem) }>
                                        <ListItemIcon>
                                            <AccessTimeIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            { recentItem.name }
                                        </ListItemText>
                                    </ListItem>
                                )) }
                                <Divider />
                            </>
                        ) }
                    </>
                ) }
                { searchMunicipalityStore.resultsAreLoading
                    ? (
                        <Loader />
                    ) : (
                        <>
                            { searchMunicipalityStore.results.map((result) => (
                                <ListItem button key={ result.id } onClick={ handleSelectItem(result) }>
                                    <ListItemIcon>
                                        <>
                                            { result.type === 'municipality' && (
                                                <LocationCityIcon />
                                            ) }
                                            { result.type === 'address' && (
                                                <PinIcon />
                                            ) }
                                        </>
                                    </ListItemIcon>
                                    <ListItemText>
                                        { result.name }
                                    </ListItemText>
                                </ListItem>
                            )) }
                        </>
                    ) }
            </List>
        );
    }

    function onItemSelected() {
        nav.navigateToMunicipality(undefined); // has to be called before clearMunicipality
        homeStore.clearMunicipality();
    }
});

export default SearchLocationModal;

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        padding: theme.spacing(0, 1),
    },
    inner: (params: any) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'strech',
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginBottom: params.isMobile ? '20vh' : undefined,
        height: params.isMobile ? undefined : 'min(500px, 50vh)',
        maxHeight: params.isMobile ? undefined : 'min(500px, 50vh)',
    }),
    buttonImageIcon: {
        // width: 16,
        height: 16,
        marginBottom: 2,
        border: '1px solid lightgray',
    },
}));
