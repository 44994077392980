import { Button,  Typography } from '@material-ui/core'; // 
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AssetIcon from '../../../../Components/Common/AssetIcon';

import { useSignInService } from '../../hooks/useSIgnInService';

type SignInWithProviderProps = {
    handleAuth: (handler: () => Promise<any>) => void;
};

const SignInWithProvider: React.FC<SignInWithProviderProps> = ({
    handleAuth,
}) => {
    const classes = useStyles();
    const signInSvc = useSignInService();

    const handleSigninWithFacebook = async () => {
        await handleAuth(() => signInSvc.signInWithFacebook());
    };

    const handleSigninWithGoogle = async () => {
        await handleAuth(() => signInSvc.signInWithGoogle());
    };

    return (
        <div className={ classes.externalProvidersContainer }>
            <Typography variant="body1" align="center" className={ classes.externalSignInTitle }>
                <FormattedMessage
                    id="SIGNIN_EXTERNAL_PROVIDERS_TITLE"
                    defaultMessage="Nebo se přihlašte pomocí"
                />
            </Typography>
            <Button startIcon={ <FacebookIcon /> } variant="contained" color="primary" onClick={ handleSigninWithFacebook } className={ classes.externalPrividerButton }>
                Facebook
            </Button>
            <Button startIcon={ <AssetIcon name="google.svg" height={15} /> } variant="contained" color="primary" onClick={ handleSigninWithGoogle } className={ classes.externalPrividerButton }>
                Google
            </Button>
            { /* <div id="signin-container" /> */ }
        </div>
    );
};

export default SignInWithProvider;

const useStyles = makeStyles((theme: Theme) => createStyles({
    externalSignInTitle: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },
    externalProvidersContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    externalPrividerButton: {
        margin: theme.spacing(1, 0),
    },
}));
