import { Button, Typography } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useRootNavigation } from '../../../Hooks/useRootNavigation';
import { useStores } from '../../../Stores/useStores';

import AccountIcon from './AccountIcon';

type SignInButtonProps = {
};

const SignInButton: React.FC<SignInButtonProps> = (props) => {
    const {  layoutStore } = useStores(); // authStore,
    const rootNavigation = useRootNavigation();
    const classes = useStyles({ isMobile: layoutStore.isMobile });

    const handleSignIn = () => {
        // authStore.setState('signingIn');
        rootNavigation.navigateTo('/signIn');
    };

    return (
        <Button endIcon={ <AccountIcon className={ classes.icon } /> } className={ classes.root } variant="text" onClick={ handleSignIn } disableRipple>
            { !layoutStore.isMobile && (
                <Typography variant="button">
                    <FormattedMessage
                        id="LOGIN_SIGNIN"
                        defaultMessage="Přihlásit se"
                    />
                </Typography>
            ) }
        </Button>
    );
};

export default observer(SignInButton);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: (params: any) => ({
        pointerEvents: 'all',
        padding: 0,
        marginRight: params.isMobile ? theme.spacing(-0.5) : undefined,
    }),
    icon: {
        height: 40,
    },
}));
