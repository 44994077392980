import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import { UserDetails } from '../Models/User';

import ApiService from './ApiService';

@injectable()
export class UserService {
    private api = IocContainer.get(ApiService);

    public async getUserDetails() {
        return this.api.getUserDetails();
    }

    public async updateUserDetails(user: UserDetails) {
        const details = {
            firstName: user.firstName,
            lastName: user.lastName,
            nickname: user.nickname,
        };

        return this.api.updateUserDetails(details);
    }
}
