import { debounce } from 'debounce';
import { injectable } from 'inversify';
import {
    observable, action, runInAction, computed, makeObservable
} from 'mobx';

import IocContainer from '../IocContainer';
import CategoryProduct from '../Models/CategoryProduct';
import CategorySearchResult from '../Models/CategorySearchResult';
import CategoryViewModel from '../Models/CategoryViewModel';
import CategoryService from '../Services/CategoryService';
import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '../Services/IAnalyticsLogger';

import SearchStore from './SearchStore';

@injectable()
export default class CategoryStore {
    private analyticsLogger = IocContainer.get<IAnalyticsLogger>(IAnalyticsLogger_TYPE);

    private searchStore = IocContainer.get(SearchStore);

    private categoryService = IocContainer.get(CategoryService);

    private logSearchDebounced = debounce(this.logSearch, 1000);

    private searchCategoriesDebounced = debounce(this.searchCategories, 200);

    @observable categories: CategoryViewModel[] = [];

    @observable searchResults: CategorySearchResult[] = [];

    @observable searchTerm: string = '';

    @observable selectedCategory: CategoryViewModel | null = null;

    @observable selectedProduct: CategoryProduct | null = null;

    @observable browsedCategories: CategoryViewModel[] = [];

    @computed get browsedCategory() {
        return (this.browsedCategories.length > 0)
            ? this.browsedCategories[this.browsedCategories.length - 1]
            : null;
    }

    @computed get isSearchActive() {
        return this.searchTerm.length > 0;
    }

    @computed get selectedTitle() {
        if (!this.selectedCategory) {
            return '';
        }
        const name = this.selectedProduct !== null
            ? this.selectedProduct.name
            : this.selectedCategory.name;
        return name;
    }

    @computed get keywords() {
        const allKeywords: string[] = [];
        if (this.selectedCategory?.keywords) {
            allKeywords.push(...(Array.isArray(this.selectedCategory.keywords)
                ? this.selectedCategory.keywords
                : (this.selectedCategory.keywords as string).split(',')));
        }

        if (this.selectedProduct?.keywords) {
            allKeywords.push(...(Array.isArray(this.selectedProduct.keywords)
                ? this.selectedProduct.keywords
                : (this.selectedProduct.keywords as string).split(',')));
        }
        return allKeywords.map((kw) => kw.trim());
    }

    constructor() {
        makeObservable(this);
    }

    @action async clearSearch() {
        if (this.searchTerm.length === 0) {
            return;
        }
        this.searchTerm = '';
        this.searchResults = [];
        await this.clearBrowsedCategories();
    }

    @action setSearchTerm(searchTerm: string) {
        this.searchTerm = searchTerm;
        this.searchCategoriesDebounced();
    }

    @action async setSelectedCategory(categoryId: string, productId?: string) {
         const category = await this.categoryService.getCategory(categoryId);
        if (!category) { throw Error(`Category '${categoryId}' not found.`); }
        // set category
        this.selectedCategory = category;
        // set product
        if (productId && this.selectedCategory.products) {
            const product = this.selectedCategory.products.find((p) => p.id === productId);
            this.selectedProduct = product || null;
        } else {
            this.selectedProduct = null;
        }
        // refresh search results
        this.searchStore.clearResults();
        await this.searchStore.setCategory(this.selectedCategory.id, this.selectedProduct?.id);
    }

    @action clearSelectedCategory() {
        if (this.selectedCategory === null) { return; }
        this.selectedCategory = null;
        this.searchStore.clearCategory();
    }

    @action async searchCategories() {
        // const catalogue = (this.browsedCategories.length > 0 && this.browsedCategory)
        //     ? this.browsedCategory.children || []
        //     : await this.categoryService.getCategories();

        this.logSearchDebounced();

        const catalogue = await this.categoryService.getCategories();

        const searchResults = await this.categoryService.searchCategories(catalogue, { searchTerm: this.searchTerm }, []);
        runInAction(() => {
            this.searchResults = searchResults;
        });
    }

    private logSearch() {
        if (!this.searchTerm) {
            return;
        }

        // analytics logging
        this.analyticsLogger.track({
            type: 'Search',
            data: {
                content_category: 'searchCategories',
                search_string: this.searchTerm,
            },
        });
    }

    @action async setBrowsedCategory(categoryId: string | 'all') {
        if (categoryId === 'all') {
            await this.setCategories();
            this.browsedCategories = [];
        } else {
            const categoryPath = await this.categoryService.getCategoryPath(categoryId);
            if (!categoryPath) {
                throw Error(`Invalid category id: ${categoryId}.`);
            }
            this.browsedCategories = [...categoryPath];

            const category = categoryPath[categoryPath.length - 1];
            this.categories = [...(category.children || [])];
        }
    }

    @action async clearBrowsedCategories() {
        this.browsedCategories = [];
        await this.setBrowsedCategory('all');
    }

    @action async setCategories() {
        const categories = await this.categoryService.getCategories();
        runInAction(() => {
            this.categories = categories;
        });
    }
}
