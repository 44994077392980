import { debounce } from 'debounce';
import { injectable, inject } from 'inversify';
import {
    observable, action, runInAction, makeObservable
} from 'mobx';

import IocContainer from '../IocContainer';
import { PlaceSearchResult } from '../Models/PlaceSearchResult';
import ApiService from '../Services/ApiService';
import IAnalyticsLogger, { IAnalyticsLogger_TYPE } from '../Services/IAnalyticsLogger';

import HomeStore from './HomeStore';

@injectable()
export default class SearchPlacesStore {
    private analyticsLogger = IocContainer.get<IAnalyticsLogger>(IAnalyticsLogger_TYPE);

    private api = IocContainer.get(ApiService);

    private homeStore = IocContainer.get(HomeStore);

    private logSearchDebounced = debounce(this.logSearch, 1000);

    public searchDebounced = debounce(this.search, 500);

    @observable searchTerm: string = '';

    @observable results: PlaceSearchResult[] = [];

    @observable resultsAreLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action beginSearch() {
        this.searchTerm = '';
        this.results = [];
    }

    @action finishSearch() {
    }

    @action setSearchTerm(term: string) {
        this.searchTerm = term;
        this.searchDebounced();
    }

    @action clearSearchTerm() {
        this.searchTerm = '';
    }

    @action async search() {
        if (this.searchTerm) {
            this.resultsAreLoading = true;

            this.logSearchDebounced();

            const results = await this.api.searchPlaces({
                searchTerm: this.searchTerm,
                location: this.homeStore.activeMapLocation || undefined,
            });
            runInAction(() => {
                this.results = results;
                this.resultsAreLoading = false;
            });
        } else {
            this.results = [];
        }
    }

    private logSearch() {
        if (!this.searchTerm) {
            return;
        }

        // analytics logging
        this.analyticsLogger.track({
            type: 'Search',
            data: {
                content_category: 'searchPlaces',
                search_string: this.searchTerm,
            },
        });
    }
}
