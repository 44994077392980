import { LatLng } from '../Types';

export default interface IGeocoder {
    reverseGeocode(location: LatLng, lang: string): Promise<GeocoderReverseResult>;

    geocode(term: string): Promise<GeocoderResult[]>;
}

export const IGeocoder_TYPE = Symbol.for('IGeocoder');

export type GeocoderReverseItem = {

};

export type GeocoderReverseResult = {
    items: GeocoderReverseItem[];
    label: string;
};

export type GeocoderResult = {
    id: string;
    label: string;
    location: LatLng;
}
