import { UseFormMethods } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getValidationErrorMessageId } from '../utils/getValidationErrorMessageId';

export function useValidationMessages(messages: any, form: UseFormMethods<Record<string, any>>) {
    const intl = useIntl();
    const { errors } = form;

    const getErrorMessage = (inputName: string) => {
        const error = errors[inputName];
        if (!error) {
            return undefined;
        }

        const messageId = getValidationErrorMessageId(inputName, error.type);

        const message = messages[messageId];
        if (!message) {
            return `Message '${messageId}' not found.`;
        }

        return error && intl.formatMessage(message);
    };

    return {
        getErrorMessage,
        getTextFieldErrorProps: (inputName: string) => {
            const error = errors[inputName];
            return {
                error: !!error,
                helperText: getErrorMessage(inputName),
            };
        },
    };
}
