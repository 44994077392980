import { Avatar } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import User from '../../../../../shared/src/Core/Models/User';
import { getUserDisplayName } from '../utils/getUserDisplayName';

import AccountIcon from './AccountIcon';

type UserAvatarProps = {
    user: User;
};

const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
    const classes = useStyles();

    const displayName = getUserDisplayName(user);
    return user.photoUrl ? (
        <Avatar alt={ displayName } src={ user.photoUrl } className={ classes.avatar } />
    ) : (
        <AccountIcon className={ classes.avatar } />
    );
};

export default UserAvatar;

const useStyles = makeStyles((theme: Theme) => createStyles({
    avatar: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.light,
    },
}));
