const CACHE_EXPIRATION_MS = 30 * 1000; // 30 s

export default class CachedObject<T> {
    private _data: T | null = null;

    private timestamp: Date | null = null;

    constructor(public state: any = null, public expiration: number = CACHE_EXPIRATION_MS) {}

    public async update(handler: () => Promise<T | null>): Promise<boolean> {
        const shouldUpdate = this._data === null || this.timestamp == null || (Date.now() - this.timestamp.getTime()) > this.expiration;
        if (shouldUpdate) {
            this._data = await handler();
            if (!this._data) {
                return false;
            }
            this.timestamp = new Date();
        }
        return shouldUpdate;
    }

    public getData() {
        return this._data;
    }

    public expire() {
        this.timestamp = null;
    }
}
