import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../Stores/useStores';

import LogoDefault from './LogoDefault';
import LogoMunicipality from './LogoMunicipality';

type LogoProps = {
    className?: string;
    height: number;
};

export const Logo: React.FC<LogoProps> = observer((props) => {
    const { homeStore } = useStores();

    return <LogoDefault { ...props } />;

    // return homeStore.municipality
    //     ? (
    //         <LogoMunicipality { ...props } />
    //     )
    //     : (
    //         <LogoDefault { ...props } />
    //     );
});
