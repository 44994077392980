import { injectable } from 'inversify';

import { AppHosts } from './Types';

@injectable()
export class ApiOptions {
    public locale: string = 'cs';

    public hostApp: AppHosts = 'browser';
}
