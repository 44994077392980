import { CircularProgress } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStores } from '../../Stores/useStores';

type AppLoaderProps = {
};

const AppLoader: React.FC<AppLoaderProps> = (props) => {
    const classes = useStyles();
    const { layoutStore } = useStores();

    if (!layoutStore.isLoading) {
        return null;
    }

    return (
        <div className={ classes.root }>
            <img
                alt="Loading..."
                src="/assets/logo_h.png"
                height="50px"
                style={ {
                    userSelect: 'none',
                    cursor: 'pointer',
                } }
            />
            <CircularProgress className={ classes.loader } />
        </div>
    );
};

export default observer(AppLoader);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'fixed',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',

        zIndex: 10000,
        left: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
    },
    loader: {
        marginTop: theme.spacing(4),
    },
}));
