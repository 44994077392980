import { injectable } from 'inversify';

import IocContainer from '../IocContainer';
import { LocationSearchItem } from '../Models/LocationSearchItem';
import { LatLng } from '../Types';

import ApiService from './ApiService';
import IGeocoder, { IGeocoder_TYPE, GeocoderResult } from './IGeocoder';


@injectable()
export default class SearchLocationService {
    private api = IocContainer.get(ApiService);

    private geocoder = IocContainer.get<IGeocoder>(IGeocoder_TYPE);

    public async searchLocations(searchTerm: string, location?: LatLng) {
        const [municipalityResults, geocodeResult] = await Promise.all([
            this.searchMunicipality(searchTerm, location),
            this.searchAddress(searchTerm),
        ]);

        const addresses = geocodeResult
            .map((r) => ({
                id: r.id,
                type: 'address',
                location: r.location,
                name: r.label,
            } as LocationSearchItem));

        const municipalities = municipalityResults.data.map((r) => ({
            type: 'municipality',
            ...r,
        } as LocationSearchItem));

        return [...addresses, ...municipalities];
    }

    private async searchMunicipality(searchTerm: string, location?: LatLng) {
        return this.api.searchMunicipality({
            searchTerm,
            location,
        });
    }

    private async searchAddress(searchTerm: string): Promise<GeocoderResult[]> {
        if (!searchTerm || searchTerm.length === 0) {
            return [];
        }
        return this.geocoder.geocode(searchTerm);
    }
}
