import { injectable } from 'inversify';
import { observable, action, computed, makeObservable } from 'mobx';

import IocContainer from '../IocContainer';
import FilterType from '../Models/FilterType';
import ConfigurationService from '../Services/ConfigurationService';

import SearchStore from './SearchStore';


@injectable()
export default class FilterTypeStore {
    private configurationService = IocContainer.get(ConfigurationService);

    private searchStore = IocContainer.get(SearchStore);

    @observable public filterTypes: FilterType[] = [];

    @observable public selectedTypes: { [key: string]: boolean } = {};

    @computed public get hasSelectedTypes() {
        return Object.keys(this.selectedTypes).reduce((p, c) => p || this.selectedTypes[c], false);
    }

    constructor() {
        makeObservable(this);
    }

    @action async load() {
        const config = await this.configurationService.getConfiguration();
        this.filterTypes = config.types;
    }

    @action async setFilterTypeState(id: string, selected: boolean) {
        const newSelectedTypes = { ...this.selectedTypes };
        newSelectedTypes[id] = selected;
        this.selectedTypes = newSelectedTypes;

        const onlySelected = Object.keys(this.selectedTypes).filter((key) => this.selectedTypes[key]);
        await this.searchStore.setFilterTypes(onlySelected);
    }

    @action async clearFilterTypeSelection() {
        this.selectedTypes = {};
        await this.searchStore.setFilterTypes([]);
    }

    public async mapTypeToName(placeType: string) {
        if (this.filterTypes.length === 0) {
            await this.load();
        }
        const type = this.filterTypes.find((ft) => ft.key === placeType);
        if (!type) {
            throw Error(`Unknown place type '${placeType}'`);
        }
        return type.label;
    }
}
