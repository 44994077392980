import { Button } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import FeedbackIcon from '@material-ui/icons/FeedbackRounded';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useStores } from '../../Stores/useStores';

const FeedbackButton: React.FC = () => {
    const { feedbackStore } = useStores();
    const classes = useStyles();

    const onFeedbackClicked = () => {
        feedbackStore.startNewFeedback();
    };

    return (
        <>
            <Button variant="contained" color="secondary" className={ classes.feedbackButton } onClick={ onFeedbackClicked }>
                <FormattedMessage
                    id="BUTTON_CTA_FEEDBACK"
                    defaultMessage="Zpětná vazba"
                />
                <FeedbackIcon className={ classes.rightIcon } />
            </Button>
        </>
    );
};

export default observer(FeedbackButton);

const useStyles = makeStyles((theme: Theme) => createStyles({
    feedbackButton: {
        alignSelf: 'center',
        flex: 1,
    },
    rightIcon: {
        marginLeft: theme.spacing(2),
        fontSize: 18,
    },
}));
