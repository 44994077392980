import { defineMessages } from 'react-intl';

export const userInputValidationMessages = defineMessages({
    email_required: {
        id: 'SIGNIN_EMAIL_VALIDATION_REQUIRED',
        defaultMessage: 'Email musí být vyplněn',
    },
    email_pattern: {
        id: 'SIGNIN_EMAIL_VALIDATION_FORMAT',
        defaultMessage: 'Email není ve správném formátu',
    },
    password_required: {
        id: 'SIGNIN_PASSWORD_VALIDATION_REQUIRED',
        defaultMessage: 'Heslo musí být vyplněno',
    },
    passwordRetype_passwordNotMatch: {
        id: 'SIGNIN_PASSWORD_VALIDATION_NOTMATCH',
        defaultMessage: 'Heslo musí být shodné se zadaným',
    },

    firstName_required: {
        id: 'SIGNIN_FIRSTNAME_VALIDATION_REQUIRED',
        defaultMessage: 'Jméno musí být vyplněno',
    },
    lastName_required: {
        id: 'SIGNIN_LASTNAME_VALIDATION_REQUIRED',
        defaultMessage: 'Příjmení musí být vyplněno',
    },

    consent_required: {
        id: 'SIGNUP_EMAIL_CONSENT_MISSING_ERROR',
        defaultMessage: 'Udělení souhlasu je podmínkou k registraci',
    }
});
