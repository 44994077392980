import { injectable } from 'inversify';

import IocContainer from '../IocContainer';

import ApiService from './ApiService';
import CategoryService from './CategoryService';

@injectable()
export default class PlaceService {
    private api = IocContainer.get(ApiService);

    private categoryService = IocContainer.get(CategoryService);

    public async loadPlace(placeId: string) {
        // TODO introduce place View Model and map it here
        const place = await this.api.getPlace(placeId);

        if (place.category && place.category.length > 0) {
            const vms = await this.categoryService.mapCategoryIds(place.category);
            place.category = vms.map((c) => c.name);
        }

        if (place.nadoby && place.nadoby.length > 0) {
            for (const container of place.nadoby) {
                if (container.category) {
                    const vms = await this.categoryService.mapCategoryIds(container.category);
                    container.category = vms.map((c) => c.name);
                }
            }
        }

        return place;
    }
}
