export class Point {
    public x: number = 0;

    public y: number = 0;

    public z: number = 0;

    constructor(_x: number, _y: number) {
        this.x = _x;
        this.y = _y;
    }
}
