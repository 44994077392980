import {
    Box,
    LinearProgress, Typography
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useStores } from '../../../Stores/useStores';

import SignInButton from './SignInButton';
import UserProfileButton from './UserProfileButton';
// import { RouterStore } from 'mobx-react-router';

type UserButtonProps = {
};

const UserButton: React.FC<UserButtonProps> = (props) => {
    const { authStore, layoutStore, appStore} = useStores();
    const classes = useStyles({ isMobile: layoutStore.isMobile });
	appStore.check();
	//console.log(appStore.routerStore);
	//console.log(appStore.hostApp);
	//console.log(appStore.isApp);
    const isLoading = authStore.state === 'unknown' || authStore.state === 'signingIn' || authStore.state === 'signingOut';
    return layoutStore.isMobile
        ? (
			!appStore.isApp ?
            <div className={ classes.root }>
                { renderButtonContent() }
            </div>
			:
			<div></div>
        )
        : (
            <Box boxShadow={ 4 } className={ classes.root }>
                { renderButtonContent() }
            </Box>
        );

    function renderButtonContent() {
        return (
            <>
                <div className={ classes.buttonContainer }>
                    { renderInner() }
                </div>
                { isLoading && (
                    <div className={ classes.progressHolder }>
                        <LinearProgress />
                    </div>
                ) }
            </>
        );
    }

    function renderInner() {
        switch (authStore.state) {
        case 'authenticated':
            return <UserProfileButton />;
        case 'signingOut':
            return (
                <Typography variant="button">
                    <FormattedMessage
                        id="LOGIN_SIGNING_OUT"
                        defaultMessage="Odhlašuji..."
                    />
                </Typography>
            );
        case 'unknown':
        default:
            return (
                <SignInButton />
            );
        }
    }
};

export default observer(UserButton);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: ({ isMobile }: any) => ({
        display: 'flex',
        pointerEvents: 'all',
        backgroundColor: 'white',
        minWidth: isMobile ? 30 : 40,
        maxHeight: isMobile ? 40 : 60,
        height: isMobile ? 40 : 60,
        alignItem: 'center',
        justifyContent: 'center',
        borderRadius: isMobile ? undefined : theme.spacing(1),
        overflow: 'hidden',
        position: 'relative',
    }),
    buttonContainer: ({ isMobile }: any) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: isMobile ? 0 : theme.spacing(1),
        paddingLeft: isMobile ? 0 : theme.spacing(2),
    }),
    loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItem: 'center',
        justifyContent: 'center',
    },
    progressHolder: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: 4,
        width: '100%',
    },
}));
