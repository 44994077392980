import {
    Button, Menu, MenuItem, Typography
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import React, { MouseEventHandler, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ResponsiveModal from '../../../Components/Layout/ResponsiveModal';
import UserProfileView from '../../../Components/UserProfileView';
import { userProfileMessages } from '../../../messages/userProfileMessages';
import { useStores } from '../../../Stores/useStores';
import { useSignOut } from '../hooks/useSignOut';
import { getUserDisplayName } from '../utils/getUserDisplayName';

import UserAvatar from './UserAvatar';

type UserProfileButtonProps = {
};

const UserProfileButton: React.FC<UserProfileButtonProps> = (props) => {
    const intl = useIntl();
    const { authStore, layoutStore } = useStores();
    const classes = useStyles();
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const signOut = useSignOut();
    const [open, setOpen] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(anchorRef.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenProfile = () => {
        setOpen(true);
        handleClose();
    };

    const handleLogOut = () => {
        signOut();
        handleClose();
    };

    const { user } = authStore;

    if (!user) {
        return null;
    }

    const displayName = getUserDisplayName(user);

    return (
        <>
            <>
                <Button innerRef={ anchorRef } variant="text" className={ classes.button } onClick={ handleClick } disableRipple>
                    { !layoutStore.isMobile && (
                        <Typography variant="button">{ displayName }</Typography>
                    ) }
                    <UserAvatar user={ user } />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={ anchorEl }
                    keepMounted
                    getContentAnchorEl={ null }
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }
                    transformOrigin={ {
                        vertical: 'top',
                        horizontal: 'center',
                    } }
                    open={ Boolean(anchorEl) }
                    onClose={ handleClose }
                >
                    <MenuItem onClick={ handleOpenProfile }>
                        <FormattedMessage
                            id="LOGIN_MENU_OPTIONS_PROFILE"
                            defaultMessage="Můj profil"
                        />
                    </MenuItem>
                    <MenuItem onClick={ handleLogOut }>
                        <FormattedMessage
                            id="LOGIN_MENU_OPTIONS_LOGOUT"
                            defaultMessage="Odhlásit se"
                        />
                    </MenuItem>
                </Menu>
            </>
            <ResponsiveModal
                open={ open }
                title={ intl.formatMessage(userProfileMessages.userProfileModalTitle) }
                onClose={ () => setOpen(false) }
            >
                <UserProfileView onSave={ () => setOpen(false) } />
            </ResponsiveModal>
        </>
    );
};

export default observer(UserProfileButton);

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        pointerEvents: 'all',
        backgroundColor: 'white',
    },
    button: {
        padding: 0,
        margin: 0,
        minWidth: 30,
    },
    avatar: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.light,
    },
}));
