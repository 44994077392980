import IEnvironment from '@shared/Core/IEnvironment';
import { injectable } from 'inversify';

@injectable()
export default class Environment implements IEnvironment {
    public get isProd() { return process.env.NODE_ENV === 'production'; }

    public get isBeta() { return false; }

    public get api() {
        return {
            host: process.env.REACT_APP_API_HOST!,
        };
    }

    public get mapboxKeyOLD() {
        return process.env.REACT_APP_MAPBOX_KEY!;
    }

    public get mapboxKey() {
        const keys = process.env.REACT_APP_MAPBOX_KEY!.split('#');
        const key = keys[Math.floor(Math.random()*keys.length)];
        return key;
    }

    public get mapTilerKey() {
        return process.env.REACT_APP_MAPTILER_KEY!;
    }

    public get facebookPixelId() {
        return process.env.REACT_APP_FBPIXEL_ID!;
    }

    public get firebase() {
        return {
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY!,
            appId: process.env.REACT_APP_FIREBASE_APP_ID!,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
        };
    }

    public get firebaseAuth() {
        return {
            projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECT_ID!,
            apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY!,
            appId: process.env.REACT_APP_FIREBASE_AUTH_APP_ID!,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
        };
    }

    public get versionHash() {
        return process.env.REACT_APP_VERSION_HASH ?? 'dev';
    }
}
