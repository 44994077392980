import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import { useRouteMatch } from 'react-router';

import AppLoader from './Components/Common/AppLoader';
import { defaultLocale } from './languages';
import Main from './Main';
import { useStores } from './Stores/useStores';
import Theme from './Theme';

import { SnackbarProvider } from 'notistack';
import { observer } from 'mobx-react-lite';

const theme = createMuiTheme({
    palette: {
        primary: { main: Theme.theme_primaryColor_hex }, // Purple and green play nicely together.
        secondary: { main: Theme.theme_secondaryColor_hex, contrastText: 'white' }, // This is just green.A700 as hex.
    },
    typography: {
        fontSize: 14,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: 14,
                fontWeight: 'lighter',
            },
        },
    },
});

const App: React.FC = observer(() => {
    const match = useRouteMatch<{ locale: string }>();
    const { appStore, layoutStore, localeStore } = useStores();

    const locale = match.params.locale ?? defaultLocale;

    useEffect(() => {
        appStore.setRootPath(match.url);
    }, []);

    useEffect(() => {
        loadLocale();
    }, [locale]);

    return (
        <ThemeProvider theme={ theme }>
            <AppLoader />
            <SnackbarProvider maxSnack={ 3 }>
                { localeStore.isSet && (
                    <IntlProvider
                        locale={ localeStore.locale }
                        defaultLocale={ defaultLocale }
                        messages={ localeStore.messages }
                    >
                        <Main />
                    </IntlProvider>
                ) }
            </SnackbarProvider>
        </ThemeProvider>
    );

    async function loadLocale() {
        layoutStore.startLoading();
        await localeStore.setLocale(locale);
        layoutStore.finishLoading();
    }
});

export default App;
