import {
    Grid, Typography, Popover, Button, List, ListItem, ListItemIcon, ListItemText, Link
} from '@material-ui/core';
import {
    createStyles, makeStyles, withStyles
} from '@material-ui/core/styles';
import AndroidIcon from '@material-ui/icons/Android';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import React from 'react';
import {
    isAndroid,
    isIOS,
    isBrowser
} from 'react-device-detect';
import { useStores } from '../../Stores/useStores';


type AppStoreLinksProps = {
};

const AndroidButtonButton = withStyles(() => ({
    root: {
        color: 'white',
        backgroundColor: 'black',
        height: 32,
        '&:hover': {
            backgroundColor: 'black',
        },
    },
}))(Button);

const imageLinks = {
	ios: 'https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg',
	android: 'https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg',
};
const iOSstyle = {
	background: `url(${imageLinks.ios}) no-repeat`,
	backgroundSize: 'contain',
	display: 'inline-block',
	overflow: 'hidden',
	textDecoration: 'none',
	height: '100%',
	width: '100%',
	padding: '5px',
};
const Androidstyle = {
	background: `url(${imageLinks.android}) no-repeat`,
	backgroundSize: 'contain',
	display: 'inline-block',
	overflow: 'hidden',
	textDecoration: 'none',
	height: '100%',
	width: '100%',
	padding: '5px',
};

const AppStoreLinks: React.FC<AppStoreLinksProps> = () => {
	const {  appStore } = useStores();
    const classes = useStyles();

    return (
        <Grid container alignContent="center" justify="center" spacing={ 3 } className={ classes.root }>
            { !appStore.isApp && ((isIOS || isBrowser)) && (
                <Grid item alignContent="center" justify="center" className={ classes.iosContainer }>
					<div style={{ display: 'inline-block', width: "100px", height: "40px" }}>
						<a
							href={"https://apps.apple.com/app/id1494784776"}
							target="_blank"
							rel="noopener noreferrer"
							title="iOS App Store"
							style={iOSstyle}
						>
						</a>
					</div>
                </Grid>
            ) }
            { !appStore.isApp && (isAndroid || isBrowser) && (
                <Grid item alignContent="center" justify="center" className={ classes.playStoreContainer }>
					<div style={{ display: 'inline-block', width: "100px", height: "40px", marginTop: "8px" }}>
						<a
							href={"https://play.google.com/store/apps/details?id=cz.kamsnim.app"}
							target="_blank"
							rel="noopener noreferrer"
							title="Android Google Play"
							style={Androidstyle}
						>
						</a>
					</div>
                </Grid>
            ) }
        </Grid>
    );
/*
    function renderPopup(popupState: any) {
        return (
            <>
                <AndroidButtonButton
                    { ...bindTrigger(popupState) }
                >
                    <AndroidIcon style={ { marginRight: 10 } } color="inherit" />
                    <Typography>Android</Typography>
                </AndroidButtonButton>
                <Popover
                    { ...bindPopover(popupState) }
                    anchorOrigin={ {
                        vertical: 'bottom',
                        horizontal: 'center',
                    } }
                    transformOrigin={ {
                        vertical: 'top',
                        horizontal: 'center',
                    } }
                >
                    <List>
                        { Object.entries(androidStores).map(([key, data]) => (
                            <Link
                                component="a"
                                href={ data.url }
                                target="_blank"
                            >
                                <ListItem key={ key }>
                                    <ListItemIcon className={ classes.itemIcon }>
                                        <img src={ data.image } alt={ data.label } className={ classes.itemImage } />
                                    </ListItemIcon>
                                    <ListItemText primary={ data.label } />
                                </ListItem>
                            </Link>
                        )) }
                    </List>
                </Popover>
            </>
        );
    }
*/

};


export default AppStoreLinks;

const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: 50,
        maxHeight: 50,
		marginTop: 5,
    },
    imageButton: {},
    imageButtonFocus: { },

    iosContainer: {
        maxHeight: 50,
    },
    playStoreContainer: {
        marginTop: -7,
    },
    itemIcon: {
        minWidth: theme.spacing(5),
    },
    itemImage: {
        width: 22,
        height: 22,
        objectFit: 'cover',
    },
}));
